@media screen and (max-width: 600px) {
    
    .sticky-hall-s{
        position: sticky;
        top: 115px;
        z-index: 1;
        background-color: white;
        margin: 0 !important;
        width: 100vw;
    }

}

.container-hall-s{
    border-top-style: solid;
    border-width: 0.01rem;
    color: #e2e2e2;
    border-bottom-style: solid;
    div{
        color: grey;
    }
}