
@media screen and (max-width: 600px) {

    
    .forgot-label{
        color: black;
        position: relative;
        top: 8px;
    }

}

@media screen and (min-width: 600px) {

    
    .forgot-label{
        color: black;
        position: relative;
        top: 20px;
    }
    
}

.forgot-label:hover{
    text-decoration: underline;
    color: black;
}

.close-forgotten {
    position: absolute !important;
    top: 0px !important;
    right: 14px !important;
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
    display: flex !important;
}