.contact-container{
    width: 65px;
    margin-left: 35%;
    margin-top: 13%;
}

.contact-home-icon{
    width: 15px;
}

.contact-home-icon2{
    font-size: 15px;
    color: #169792;
}

.soporte-icon-footer {
    font-size: 23px;
    padding: 10px;
    color: #169792;
}

.map-icon{
    padding-left: 10px;
    color: white;
}

.payment-icon{
    height: 27px;
}



@media screen and (min-width: 600px) {

    .comment-icon{
        margin-bottom: 10px;
        font-size: 25px;
        color: #18ada8;
    }
    

}

@media screen and (max-width: 600px) {

    .comment-icon{
        font-size: 25px;
        color: #18ada8;
    }
    

}

.share-icon{
    margin-left: 10px;
    color:  #18ada8;
}

.header-cart-icon{
    font-size: 30px;
    color: #999999;
}

.cart-badge {
    position: absolute;
    background: #169792;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    top: -3px;
    right: -8px;
    padding: 3px;
}

.cart-badge-i {
    position: absolute;
    background: #169792;
    width: 26px;
    height: 26px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    top: 3px;
    right: -34px;
    padding: 3px;
}

.cart-img{
    width: 16px;
    position: absolute;
    top: 22px;
    left: 0px;
    width: 17px;
    z-index: 1;
}

.new-home-icon{
    font-size: 30px;
    color: #a3a3a3;
}

.face-login-icon{
    padding-right: 8px;
    margin-left: 5px;
}

