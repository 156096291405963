@media screen and (max-width: 600px) {
    .modal-mobile-style{
        width: 85vw;
        border-radius: 15px;
        padding: 20px;
        div{
            width: 100%;
        }
    }

    .modal-product-style{
        width: 85vw;
        border-radius: 15px;
        padding: 20px;
        div{
            width: 100%;
        }
    }

    .hidex{
        display: none;
    }
}

@media screen and (min-width: 600px) {
    .tac-desk{
        text-align: center;
    }
}

.margin-radio{
    margin-bottom: 20px !important;
}

.radio-style{
    color: black;
    margin-bottom: 14px;
    font-size: 15px;
}

.body-size{
    font-size: 14px;
}

.input-style{
    font-size: 15px;
    color: black;
    height: 43px !important;
    padding: 11px;
}

.modal-separator{
    width: 100%;
    hr{
        border-top: 1px solid #dee2e6;
    }
}


.modal-prod-title{
    font-size: 20px;
    color: #149792;
    text-align: center;
}

.xprod{
    position: absolute;
    top: -12px;
    right: -72vw;
    font-size: 21px;
    color: gray;
    font-weight: 100;
}

.modal-prod-name{
    font-weight: normal;
    font-size: 12px;
    text-align: center;
    margin-bottom: 20px;
}

.modal-prod-desc{
    font-size: 12px;
    color: gray;
}

.modal-container-qty{
    border-color: gray;
    border-style: dotted;
    border-width: 1px;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
}

.modal-cantidad-text{
    font-size: 14px;
    color: gray;
}

.modal-cantidad-number{
    font-size: 20px;
    color: gray;
    text-decoration: underline;
    float: right;
    position: relative;
    top: -4px;
}

.modal-min{
    float: right;
    font-size: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: gray;
}

.modal-plus{
    float: right;
    font-size: 16px;
    height: 24px;
    width: 24px;
    text-align: center;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: gray;
    margin-right: 8px;
    margin-left: 20px;
}

.modal-prod-price{
    font-size: 16px;
    margin-top: 26px;
}

.container-button-prod{
    margin-top: 14px;
    text-align: center;
}

.modal-btn-add{
    padding: 13px 29px;
    color: #169792 !important;
    letter-spacing: 0.05em;
    border: 1px solid #169792;
    background-color: white;
    background-repeat: no-repeat;
    background-position: 0;
    height: 46px;
    font-size: 14px;
}
