
@media screen and (max-width: 600px) {

    .map-dialog{
        max-width: 80vw;
    }

    .map-mb{
        max-width: 90vw;
    }

}

@media screen and (min-width: 600px) {

}



.ol-attribution.ol-uncollapsible{
    display: none;
}
