.programing-radio {
    line-height: 24px;
    color: #333333 !important;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
}

.programing-body-title {
    font-size: 18px;
    color: black;
    margin-bottom: 14px;
    font-weight: bold;
    text-align: center;
}

.check-title {
    font-size: 20px;
    color: #169792 !important;
    text-align: center;
}

.hr-check {
    border-top: 1px solid #dee2e6;
}

.radio-space {
    margin-right: 8px;
}

.dir-radio-l {
    font-size: 11px;
}

.cart-section-btn2 {
    font-size: 16px !important;
}

.btn-bono {
    padding: 3px 7px;
    color: #ffffff !important;
    letter-spacing: 0.05em;
    border: 2px solid #169792;
    background-color: #169792;
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
    font-size: 11px !important;
}

@media (max-width: 600px) {
    .delivery-radio-mb {
        top: 11px !important;
    }
}
