@media (max-width: 600px) {
    .nav-menu-style {
        background-color: #fafafa !important;
    }

    .close-nav-icon-style {
        margin-left: auto;
    }

    .nav-area-card {
        background-color: white;
        padding: 10px 10px 10px 10px;
        margin: 10px;
        width: auto !important;
    }

    .nav-menu-title {
        color: #149792;
        font-weight: bold;
        font-size: 16px;
    }
    .nav-menu-item {
        padding: 0 !important;
        display: block;
        color: black;
        &:hover {
            color: black;
        }
    }
    .nav-li {
        width: 100%;
    }
    .nav-arrow-style {
        margin-right: auto;
        position: absolute;
        right: 0px !important;
    }
    .nav-drop-item {
        padding-left: 10px !important;
    }
    .btn-fluio-outline {
        color: #149792;
        background-color: white;
        border-color: #149792;
    }
    .nav-item-icon {
        padding-right: 10px;
        font-size: 16px;
    }
}
