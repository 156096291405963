.tags-row {
    margin-left: 0px;
    margin-right: 10px;
    height: 0;
    position: relative;
    bottom: 10px;
}

.tags-styles {
    background-color: white;
    border-radius: 50%;
}

@media screen and (max-width: 1000px) {
    .filtro-display {
        display: block;
    }
}

@media screen and (min-width: 1000px) {
    .filtro-display {
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .label-type-huerta {
        position: absolute;
        top: 114px;
        left: 8px;
        background: white;
        border: 1px solid;
        border-radius: 6px;
        padding: 2px;
        font-size: 10px;
    }
}

@media screen and (min-width: 600px) {
    .label-type-huerta {
        position: absolute;
        top: 143px;
        left: 8px;
        background: white;
        border: 1px solid;
        border-radius: 6px;
        padding: 2px;
        font-size: 10px;
    }
}

.productos-sugeridos-modal {
    width: 100%;
    margin-bottom: 6vh;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
}

.productos-sugeridos-modal {
    width: 100%;
    margin-bottom: 6vh;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
}

.productos-sugeridos-modal2 {
    width: 100%;
    margin-bottom: 4.5vh;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    padding-left: 10px;
    padding-bottom: 15px;
    padding-right: 10px;
    padding-top: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    //background-color: rgba(106, 179, 132, 0.5);
}

.productos-sugeridos-overlay {
    background: none !important;
}

.container-sugeridos {
    margin-left: 5%;
    margin-right: 5%;
}

.producto-sugerido-max-h {
    max-height: 95px;
}

.producto-sugerido-size {
    width: 95px !important;
    height: 95px;
}

.pb-sugerido-style {
    max-width: 90px;
}

.pb-sugerido-button {
    font-size: 10px !important;
    border: 2px solid transparent;
    position: relative;
    left: -5px;
    background-color: #22bbb8 !important;
    width: 90px;
}

.margin-sugerido {
    margin-right: 5px !important;
}

.float-box {
    position: fixed;
    bottom: 0px;
    background-color: white;
    color: #fff;
    text-align: center;
    z-index: 6;
    max-width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    padding-bottom: 6px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    height: 340px;
    width: 99vw;
}

.d-sugerido-box {
    max-height: 50vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: grey;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: grey;
    }
}

.name-h-sugeridos {
    height: 40px;
    color: black !important;
    margin-top: 6px;
}

.header-title-d-sugerido {
    font-size: 20px;
    color: #149792;
}

.float-box2 {
    position: fixed;
    bottom: 0px;
    background-color: white;
    color: #fff;
    text-align: center;
    z-index: 6;
    max-width: 100%;
    padding-bottom: 6px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    -moz-box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    box-shadow: 0px 0px 13px 0px rgba(133, 133, 133, 1);
    height: 455px;
    width: 100vw;
}

.slider-sugeridos {
    padding-left: 10px;
    margin-right: 7px;
}

.productos-sugeridos-name {
    font-size: 12px !important;
    height: 13px;
    text-align: start;
    color: black;
    padding-bottom: 0 !important;
}

.productos-sugeridos-aliado {
    font-size: 9px !important;
    text-align: start;
    padding-bottom: 0 !important;
}

.productos-sugeridos-price {
    font-size: 9px !important;
    text-align: start;
    color: black;
    padding-bottom: 0 !important;
}

.close-sugerido-icon {
    color: white;
    font-size: 8px;
    //font-size: 17px;
    //padding-right: 10px;
    padding-right: 5px;
    //padding-top: 2px;
}

.sugeridos-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #22bbb8;
    color: white;
    font-weight: 700;
}

.sugeridos-header2 {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffffff;
    color: black;
    font-weight: 700;
}

.cerrar-sugerido {
    font-size: 14px;
    text-decoration: underline;
    color: white;
}

.cerrar-sugerido2 {
    font-size: 14px;
    text-decoration: underline;
    color: black;
}

.cargando-sugerido {
}

.error-sugerido {
    color: black;
}
.end-pasillo-sugerido {
    border-right: 3px solid #80808075;
}

.body-title {
    font-size: 16px;
    color: black;
    margin-bottom: 14px;
}

.header-title {
    font-size: 20px;
    color: #149792;
    margin-bottom: 26px;
}

.slider-qty {
    font-size: 18px;
    height: 21px;
    text-decoration: underline;
    color: black;
}

.old-price-space {
    margin-left: 5px;
}

.product-page-img {
    width: 100%;
    max-width: 300px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.prod-p-name {
    font-size: 30px;
}

.prod-p-desc {
    height: 170px;
    overflow: auto;
}

.prod-p-aliado {
    font-size: 20px;
    color: gray;
}

.check-sugerido-box {
    overflow: auto;
    height: 235px;
    overflow-x: hidden;
    overflow-y: scroll;
}

.margin-check-sugerido {
    margin-top: 8px;
    padding-right: 5px;
}

.size-check-sugerido {
    max-width: 108px;
}

.pricing-sugerido {
    margin: 0;
    height: 19px;
}
