.launch-page{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    top: 0;
    background: bottom;
    background-color: #f7f7f7;
    background-size: cover;
    overflow-y: auto;
}

.launch-logo{
    width: 65px;
    border-radius: 50%;
    opacity: 0.8;
}

.launch-page-1{
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 30px;
}

.launch-page-1-title{
    font-size: 26px;
    color: #888888;
    font-family: sans-serif;
    letter-spacing: 0px;
}

.launch-page-1-title-black{
    font-size: 26px;
    color: black;
    font-family: sans-serif;
    letter-spacing: 0px;
}

.launch-page-1-text{
    font-size: 22px;
    color: #888888;
    font-family: sans-serif;
    letter-spacing: 0px;
    line-height: 32px;
}

.launch-page-1-section-1{
    margin-top: 38px;
}

.launch-next-button{
    background-color: #45ab6c;
    color: white;
    width: 160px;
    border-radius: 20px !important;
    font-family: sans-serif;
}

.launch-omitir-button{
    background-color: #306ab1;
    color: white;
    width: 160px;
    border-radius: 20px !important;
    font-family: sans-serif;
}

.launch-page-2{
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 30px;
}

.launch-page-3{
    margin-right: 20%;
    margin-left: 20%;
    margin-top: 30px;
}

.launch-page-2-text{
    font-size: 18px;
    color: #888888;
    font-family: sans-serif;
    letter-spacing: 0px;
    line-height: 25px;
}

.launch-page-3-text{
    font-size: 22px;
    color: #292929;
    font-family: sans-serif;
    letter-spacing: 0px;
    line-height: 25px;
}

.launch-90-button{
    background-color: #45ab6c;
    color: white;
    width: 160px;
    border-radius: 20px !important;
    font-family: sans-serif;
    font-size: 12px !important;
    line-height: 12px !important;
}

.launch-program-button{
    background-color: #306ab1;
    color: white;
    width: 160px;
    border-radius: 20px !important;
    font-family: sans-serif;
    font-size: 12px !important;
    line-height: 12px !important;
}

.launch-page-3-label{
    font-size: 16px;
    color: #888888;
    font-family: sans-serif;
}

.launch-check{
    margin-top: 4px;
    &:checked ~ .checkmark {
        background-color: #45ab6c;
    }
    background-color: #45ab6c;
}

.container-launch-check {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 12px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input{
        position: relative;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
    border-radius: 50%;
    border-style: solid;
    border-width: 1px;
}

.container-launch-check input:checked ~ .checkmark {
background-color:  #179697;;
}

.checkmark:after {
content: "";
    position: absolute;
    display: none;
}

.container-launch-check input:checked ~ .checkmark:after {
    display: block;
}

.container-launch-check .checkmark:after {
    /*
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    */
}

.mb-0{
    margin-bottom: 0 !important;
}

.launch-margin-check{
    margin-top: 14px !important;
    margin-bottom: 0 !important;
}

.launch-margin-check-2{
    margin-top: 13px !important;
    margin-bottom: 0 !important;
}

.launch-margin-label{
    margin-top: 8px !important;
    margin-bottom: 0 !important;
}

.omitir-label{
    position: fixed;
    bottom: 15px;
    right: 26px;
    color: gray;
    text-decoration: underline;
}

.launch-bar{
    background: #3c88d6;;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to top, #49ea63, #3c88d6);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #49ea63, #3c88d6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    width: 15vw;
    height: 100vh;
    position: fixed;
    left: 0;
    z-index: 91;
    opacity: 0.8;
}

.launch-next-1-space{
    margin-top: 6rem;
    margin-bottom: 3rem;
}

.launch-next-2-space{
    margin-top: 5rem;
    margin-bottom: 3rem;
}

.launch-tag-img{
    width: 20px;
    margin-left: 7px;
    margin-right: 7px;
}

.launch-truck{
    font-size: 22px;
}