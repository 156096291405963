.filtro-btn{
    width: 100%;
    font-size: 12px !important;
    padding-right: 0;
}

.suggets-btn-fix{
    font-size: 12px !important;
}

.repeat-home-btn{

    font-size: 11px !important;
    border: 2px solid transparent;
    background-color: #22bbb8 !important;
    color: white;
    line-height: 9px !important;
    width: 135px;

}

.btn-qty{
    background-color: #169792;
    color: white;
    margin-left: 2px;
    border-radius: 50% !important;
    width: 25px;
    height: 25px;
    text-align: center;
    font-size: 16px;
    &:hover{
        color:white !important;
    }

}

.add-content{
    height: 30px;
    touch-action: none;
}

.btn-com-home{
    background: white;
    color: black !important;
    font-weight: 100 !important;
    padding: 20px;
    border: 1px solid black;
}

.cart-section-btn{
    font-size: 20px !important;
}

.facebook-button{
    font-family: Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(.06887vw + .67769px) solid #4c69ba;
    padding: 10px;
    width: 100%;
    text-align: start;
}

.social-btn {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 4px;
    margin: 5px 0;
    opacity: 0.85;
    display: inline-block;
    font-size: 17px;
    line-height: 20px;
    text-decoration: none; /* remove underline from anchors */
    &:hover{
        opacity: 1;
        color: white;
    }
}

.fb {
    background-color: #3B5998;
    color: white;
}
  
.google {
    width: 100%;
}

.google-btn {
    background-color: #dd4b39;
    color: white;
}

.btn-suge-cont{
    margin-top: 14px;
    color: white;
    font-size: 13px !important;
    border: 2px solid transparent;
    position: relative;
    left: -5px;
    background-color: #22bbb8 !important;
    padding-top: 0;
    padding-bottom: 0;
}

