@media screen and (min-width: 600px) {

    .subcategory-image-box{
        width: 100px;
        height: 100px;
        border-radius: 8px;
    
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 78%;
        }
    }

}

@media screen and (max-width: 600px) {

    .subcategory-image-box{
        width: 28vw;
        height: 28vw;
        border-radius: 8px;
    
        img{
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 78%;
        }
    }

}

.subcategory-card{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    border-radius: 12px;
}

.subcategory-card2{
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.19);
    border-radius: 12px;
    min-height: 135px;
    .c-margin{
        margin-top: 20px;
    }
}



