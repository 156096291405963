.menu-huerta{
    min-height: calc(100vh - 150px);

    @media screen and (max-width: 600px) {
        
        .center-huerta{
            margin: 0;
            position: absolute;
            top: 43%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            left: 0px;
        }

    }
    
}

.hollyday-logo{
    width: 100px;
    opacity: 0.8;
}

.hollyday-content{
    margin-right: 15%;
    margin-left: 15%;
    margin-top: 15vh;
}