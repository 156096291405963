.tab-order{
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 11px;
}

.pedidos-title{
    text-align: center;
    font-weight: bold;
    font-size: 19px;
    color: black;
}