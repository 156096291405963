.container-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 24px;
    color: #333333 !important;
    font-weight: 700;
    font-size: 16px;
  }
  
  /* Hide the browser's default radio button */
  .container-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark-radio {
    position: absolute;
    top: -4px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container-radio:hover input ~ .checkmark-radio {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container-radio input:checked ~ .checkmark-radio {
    background-color: #149792;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark-radio:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container-radio input:checked ~ .checkmark-radio:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container-radio .checkmark-radio:after {
       top: 9px;
      left: 9px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: white;
  }