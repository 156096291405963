.float-button {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 75px;
    right: 9px;
    background-color: #169792;
    color: #fff;
    border-radius: 50px !important;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 99;
}

.float-button:hover {
    background-color: white;
    border-color: #169792;
    color: #169792;
    border-style: solid;
}

.float-home {
    margin-top: 6px;
}

.tags-label {
    display: inline-block;
    color: grey;
    font-size: 20px;
    letter-spacing: 1px;
}

.sugerido-item {
    display: inline-block;
    margin-right: 26px;
}

@media screen and (min-width: 600px) {
    .h-100 {
        height: 100px !important;
    }
}

@media screen and (max-width: 600px) {
    .fix-side {
        margin-top: 30px;
        margin-right: 3px;
    }

    .fixed-main {
        height: 55px;
    }

    .h-100 {
        height: 55px !important;
    }

    .home-tag-label {
        font-size: 12px;
    }
}

.category-card-home {
    width: 210px;
    height: 110px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
}

.category-home-b2 {
    width: 100%;
    height: 110px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
}

.category-home-b3 {
    width: 100%;
    height: 130px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
}

.content-home-m1 {
    margin-left: 5%;
    margin-right: 5%;
}

.sugerido-card-home {
    width: 80px;
    height: 80px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 50%;
    margin-right: 26px;
    display: table-cell;
    vertical-align: middle;
}

.categories-home-slider-box {
    overflow-x: scroll;
    height: 110px;
    margin-bottom: 20px;
    overflow-y: hidden;
}

.categories-home-slider-box::-webkit-scrollbar {
    display: none !important;
}

.sugeridos-home-slider-box {
    overflow-x: scroll;
    height: 80px;
    overflow-y: hidden;
}
.sugeridos-home-slider-box::-webkit-scrollbar {
    display: none !important;
}

.inline {
    display: inline-block;
}

.sugerido-home-label {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
    text-align: center;
    width: 100%;
    word-break: break-word;
    color: black;
    line-height: 14px;
}

.sugerido-label-container {
    height: 40px;
    align-items: center;
    display: inline-flex;
}

.vm-sugeridos {
    color: #666666;
    font-size: 14px;
}
.vm-sugeridos-container {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.home-separator {
    width: 100%;
    padding-left: 16%;
    padding-right: 16%;
    hr {
        border-top: 1px solid #666666;
    }
}

.category-home-card-label {
    color: black;
    font-size: 16px;
    position: absolute;
    top: -56px;
    line-height: 22px;
    letter-spacing: 1px;
}

.subcategory-home-card-label {
    color: black;
    font-size: 16px;
    position: absolute;
    top: 75px;
    line-height: 22px;
    letter-spacing: 1px;
}

.content-more {
    overflow: auto;
    height: 425px;
}

.category-home-card-label-odd {
    color: black;
    font-size: 16px;
    position: absolute;
    top: -56px;
    line-height: 22px;
    letter-spacing: 1px;
    text-align: right;
    padding-right: 10px;
}

.subcategory-home-image-box {
    height: 100px;
    border-radius: 8px;
    img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 78%;
    }
}

.subcategory-home-image-box2 {
    height: 100px;
    border-radius: 8px;
    img {
        display: block;
        margin-left: auto;
        height: 95px;
        position: relative;
        top: 65px;
    }
}

.mb-category-section-label {
    padding-left: 13px !important;
    padding-right: 13px !important;
    text-align: center;
    padding: 20px 35px;
    bottom: -50px;
    font-size: 16px;
    position: relative;
    color: black;
}

.border-category-home {
    border-color: black;
    border-style: solid;
    border-width: 1px;
}

.second-cate-label {
    font-size: 13px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    text-align: justify;
    position: absolute;
    bottom: -10px;
    left: -5px;
    line-height: 14px;
    letter-spacing: 0.5px;
}

.color-black {
    color: black;
}

.color-grey {
    color: gray;
}

.search-style {
    margin-top: 40px;
    height: 53px;
    margin-bottom: 35px;
}

.search-h {
    height: 53px !important;
}

.home-icon-container {
    margin-bottom: 30px;
    text-align: center;
    padding: 0 !important;
}

.home-icon-container2 {
    margin-bottom: 50px;
    text-align: center;
    padding: 0 !important;
}

.input-seach-style {
    background-color: #eaeaea;
    border-style: none;
    padding-top: 9px;
    padding-bottom: 9px;
    letter-spacing: 1px;
    &:focus {
        background-color: #eaeaea;
    }
}

.home-title-margin {
    margin-top: 30px;
    margin-bottom: 35px;
}

.home-title-sugerido-margin {
    margin-top: 30px;
    margin-bottom: 30px;
}

.home-title-aliado-margin {
    margin-top: 30px;
    margin-bottom: 30px;
}

.home-aliado-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    filter: grayscale(100%);
}

.mid-aliado-img-container {
    margin-right: 26px;
    margin-left: 26px;
}

.pb-0-i {
    padding-bottom: 0 !important;
}

.pl-0-i {
    padding-left: 0 !important;
}

.pr-0-i {
    padding-right: 0 !important;
}

.pt-0-i {
    padding-top: 0 !important;
}

.categories-home-slider-box2 {
    overflow-x: scroll;
    height: 110px;
    margin-bottom: 30px;
    overflow-y: hidden;
}

.categories-home-slider-box2::-webkit-scrollbar {
    display: none !important;
}

.no-border {
    border-style: none !important;
}

.center-cate-styles {
    max-height: 38vw;
}

.center-cate-label {
    color: black;
    text-align: center;
    font-size: 13px;
}

.tags-area {
    margin-bottom: 35px !important;
}

.home-t-label {
    color: grey;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: center;
}

.home-isearch {
    height: 55px;
    border-radius: 30px !important;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
}

.home-sicon {
    position: absolute;
    font-size: 30px;
    top: 60px;
    right: 27%;
}

.somos-con {
    position: relative;
    top: -105px;
    border-style: solid;
    border-width: 1px;
    margin-left: 32%;
    margin-right: 32%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    .title {
        font-size: 25px;
    }
    .text {
        font-size: 16px;
    }
}

.cate-sand {
    margin-left: 20%;
    margin-right: 20%;
}

.cate-tag-home {
    font-size: 28px !important;
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

.home-title-label {
    font-size: 24px !important;
    color: black;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
}

.home-title-label2 {
    font-size: 24px !important;
    color: black;
    text-align: center;
    margin-bottom: 30px;
}

.home-title-label3 {
    font-size: 24px !important;
    color: black;
    text-align: center;
    margin-bottom: 50px;
}

.home-text1 {
    margin-left: 20%;
    margin-right: 20%;
    text-align: center;
    p {
        font-size: 16px !important;
        color: black;
    }
}

.cate-tag-home-pos {
    position: relative;
    top: -35px;
}

.home-hr1 {
    margin-left: 30%;
    margin-right: 30%;
    hr {
        color: black;
        border-style: solid;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 80px;
        margin-bottom: 50px;
        border-width: 1px !important;
    }
}

.home-hr2 {
    margin-left: 30%;
    margin-right: 30%;
    hr {
        color: black;
        border-style: solid;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        margin-top: 50px;
        margin-bottom: 50px;
        border-width: 1px !important;
    }
}

.shop-cate-p {
    padding-left: 1% !important;
    padding-right: 1% !important;
}

.shop-cate-box {
    width: 14vw;
    height: 17vw;
}

.shop-cate-img {
    display: block;
    margin-top: 2vw;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
}

.shop-cate-lbl {
    z-index: 1;
    position: absolute;
    bottom: 10px;
    left: 15px;
    display: flex;
    h2 {
        font-size: 18px;
    }
}

@media (max-width: 600px) {
    .home-comment-container {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
}

@media (min-width: 600px) {
    .home-comment-container {
        text-align: center;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.home-app-slogan {
    text-align: center;
    font-size: 16px;
    margin-bottom: 80px;
}
