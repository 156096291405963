@import '../public/assets/scss/font-awesome';
@import '../public/assets/scss/bootstrap';
@import '../public/assets/scss/theme/variables';
@import './res-cart.scss';
@import './card.scss';
@import './assets/styles/password.scss';
@import './assets/styles/home.scss';
@import './assets/styles/header.scss';
@import './assets/styles/product.scss';
@import './assets/styles/buttons.scss';
@import './assets/styles/icons.scss';
@import './assets/styles/pse.scss';
@import './assets/styles/map.scss';
@import './assets/styles/responsive-fix.scss';
@import './assets/styles/orders.scss';
@import './assets/styles/sub-categories.scss';
@import './assets/styles/modal.scss';
@import './assets/styles/checkout.scss';
@import './assets/styles/launch.scss';
@import './assets/styles/mobile-login.scss';
@import './assets/styles/newnav.scss';
@import './assets/styles/radio.scss';
@import './assets/styles/sticky.scss';
@import './assets/styles/footer.scss';
@import './assets/styles/pages.scss';
@import './assets/styles/nav-menu.scss';

.center-mt-30 {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.home-cate {
    font-size: 16px;
    position: relative;
    bottom: -75px;
}

.i-footer {
    width: 160px;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
}

.center65 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 65%;
    text-align: center;
}

.bg-app {
    background-color: #e8efed;
}

.br-text-white {
    color: white !important;
    text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

.w-vista-r {
    width: 750px;
}

.pointer {
    cursor: pointer;
}

.btn-center {
    margin: 0 auto;
    display: block !important;
}

.mt-26px {
    margin-top: 26px;
}

.mt-150px {
    margin-top: 30px;
}

.w-28 {
    width: 28px;
}

.pasillo-img {
    border-radius: 25px;
    padding: 30px;
    margin-left: 30px;
    width: 300px;
    height: 500px;
    filter: grayscale(100%);
}

.pasillo-img3 {
    border-radius: 12px;
    //background: #73AD21;
    //margin-left: 30px;
    width: 300px;
    height: 500px;
    filter: grayscale(30%);
}

.pasillo-img2 {
    border-radius: 50%;
    width: 300px;
    height: 300px;
}

.pasillo-label {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.71);
    border: 2px solid black;
    padding: 10px;
    margin-left: 5px;
    width: 250px;

    color: black;
    text-align: center;
    font-size: 30px;
}

.pasillo-label-style {
    color: grey;
    font-size: 20px;
    margin-bottom: 0;
    position: relative;
    bottom: -20px;
    max-width: 80%;
}

.text-bc {
    position: relative;
    bottom: 50px;
    left: 48%;
    transform: translate(-50%, -50%);
}

.h-150 {
    height: 150px;
}

.h-170 {
    height: 170px;
}

.max-h {
    max-height: 100%;
}

.w-respo {
    width: 100%;
    height: auto;
}

.w-respo80 {
    width: 80%;
}

.border-content {
    border-radius: 25px;
    border: 2px solid black;
}

.no-pt {
    padding-top: 0px !important;
}

.text-c {
    position: absolute;
    top: 40%;
    left: 42%;
    transform: translate(-50%, -50%);
}

.w-respo2 {
    height: 80%;
    width: 80%;
}

.circular--portrait {
    position: relative;
    width: 20vw;
    height: 20vw;
    overflow: hidden;
    border-radius: 50%;
}
.circular--portrait {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.pasillo-label2 {
    border-radius: 25px;
    background: rgba(255, 255, 255, 0.71);
    border: 2px solid black;
    padding: 10px;
    margin-left: 25px !important;
    width: 250px;

    color: black;
    text-align: center;
    font-size: 30px;
}

.h-respo {
    transform: translateY(-50%);
}

@media screen and (max-width: 1198px) {
    .media-pasillo {
        margin-top: 30px !important;
    }
}

.cate-banner {
    padding: 0;
    position: relative;
    overflow: hidden;
    img {
        transform: scale(1);
        transition: 0.5s ease;
    }
    &:hover {
        img {
            transform: scale(1.1) translateX(2px);
            transition: 0.5s ease;
        }
        h2 {
            color: $theme-deafult;
            transition: 0.5s ease;
        }
    }
    .category-box {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        h2 {
            display: inline-block;
            padding: 20px 35px;
            margin-bottom: 0;
            transition: 0.5s ease;
        }
    }
}

.cate-banner2 {
    padding: 0;
    position: relative;
    overflow: hidden;
    img {
        transform: scale(1);
        transition: 0.5s ease;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    &:hover {
        h2 {
            color: $theme-deafult;
            transition: 0.5s ease;
        }
    }
    .category-box {
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        h2 {
            display: inline-block;
            padding: 20px 35px;
            margin-bottom: 0;
            transition: 0.5s ease;
        }
    }
}

.box400 {
    height: 240px;
    width: 400px;
    max-width: 100%;
}

.w-400 {
    width: 300px;
}

.mw-80 {
    max-width: 45%;
}

body {
    background-color: tan;
}

.ml-cate {
    margin-left: 2%;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .sticky {
        padding-top: 20px;
    }
    .ml-cate {
        //margin-left: 5%;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .ml-cate {
        //margin-left: 5%;
    }
}

.bg-cate {
    border-radius: 15px;
}

.bg-white {
    background-color: white;
}

.vpcate {
    height: 20px;
}

.max-h475 {
    max-height: 184px;
}

.sandwitch10 {
    margin-left: 10%;
    margin-right: 10%;
}

.special-p {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.scroll-nav {
    max-height: 250px; /* you can change as you need it */
    overflow: auto; /* to get scroll */
}

.w200 {
    width: 200x !important;
}

.space-s {
    margin-left: 10px;
    margin-right: 10px;
}

.more-img {
    width: 90% !important;
    display: block;
    margin-top: 3px;
    margin-left: 7%;
    margin-right: 3%;
}

@media screen and (max-width: 600px) {
    .green-loader {
        height: auto;
        width: 113px;
        padding-top: 18px;
    }

    .big-cate-img {
        width: 80% !important;
    }

    .mb-cate-box-test {
        height: 75% !important;
    }

    .mb-cate-banner-test {
        height: 80px;
    }

    .mb-min-cate {
        border-radius: 13px !important;
        height: 100px !important;
        width: 100px;
    }

    .mb-ml50 {
        margin-left: 50px;
    }

    .mb-img-max-h {
        max-height: 122px;
    }

    .mb-font9 {
        font-size: 9px !important;
    }

    .mb-font13 {
        font-size: 13px !important;
    }

    .mb-font-15 {
        font-size: 15px !important;
    }

    .mb-mt-130 {
        margin-top: -4px;
    }

    .mb-no-padding {
        padding: 0 !important;
    }

    .center-mt-30 {
        display: block;
        margin-top: 3px;
        margin-left: auto;
        margin-right: auto;
        width: 77%;
    }

    .mb-cate-separator {
        height: 10px;
    }

    .mb-cate-box {
        width: 44vw;
        height: 27vw;
        margin-bottom: 0 !important;
    }

    .mb-mb-70 {
        margin-bottom: 70px !important;
    }

    .mb-mb-100 {
        margin-bottom: 89px !important;
    }

    .mb-min-cate-style {
        margin-bottom: 70px !important;
    }

    .mb-padding {
        padding: 6px;
    }

    .mb-none {
        display: none !important;
    }

    .mb-font-10 {
        font-size: 10px;
    }

    .mb-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        text-align: center;
    }

    .mb-center90 {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        text-align: center;
    }

    .mb-display {
        display: block;
    }

    .mb-modal {
        max-width: 400px;
        div {
            width: 100%;
        }
    }

    .mb-max-img-pas {
        max-height: 205px;
        height: 184px;
    }

    .btn-cart {
        background-color: #597c7a;
        color: white;
        margin-left: 2px;
        border-radius: 5px !important;
    }

    .mb-font-8 {
        font-size: 8px !important;
    }

    .mb-ml25 {
        margin-left: 25% !important;
    }

    .btn-w {
        width: 50% !important;
    }

    .name-h {
        height: 54px !important;
        color: black !important;
        margin-top: 6px;
    }

    .lbl-h {
        height: 48px;
        font-size: 14px !important;
        color: black !important;
        margin-top: 6px;
    }

    .mb-font-6p8 {
        font-size: 11.8px !important;
    }

    .mb-oval {
        width: 127px;
        height: 103px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        position: relative;
        bottom: 102px;
        left: 5px;
        transform: rotateZ(170deg) !important;
    }

    .mb-cate-p {
        padding: 6px !important;
        padding-left: 13px !important;
        padding-right: 13px !important;
    }

    .mb-font-15 {
        font-size: 15px !important;
    }

    .mb-p0 {
        padding: 0 !important;
    }

    .center-special {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 95% !important;
        text-align: center;
    }

    .mb-search {
        height: 55px;
        background-color: white;
    }

    .mb-cate-cart {
        width: 30px;
    }

    .envio-label {
        margin-top: 2px;
        font-size: 25px;
    }

    .mb-l25 {
        left: 25% !important;
    }

    .mb-pas-img {
        width: 113px;
        height: 113px;
    }

    .pb-style {
        max-width: 130px;
    }

    .mb-pro-img {
        width: 142px;
        height: 142px;
    }

    .mb-fix-logo {
        position: relative;
        top: 16px;
        left: -1px;
    }

    .mb-mt-1 {
        margin-top: 0.25rem !important;
    }

    .envio-box {
        margin-right: 4%;
    }

    .envio-box2 {
        margin-right: 50%;
    }

    .lbl-a {
        font-size: 9px !important;
        height: 18px !important;
        font-weight: bold;
        font-style: oblique;
    }

    .lbl-a2 {
        font-size: 12px !important;
        height: 10px !important;
        font-weight: bold;
        font-style: oblique;
    }

    .add-img {
        height: auto;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        display: block;
    }

    .modal-image-style {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 120px;
    }

    .mb-home-loader {
        margin-top: -14px;
    }

    .lbl-name-aliado {
        height: 27px;
        font-size: 14px !important;
        color: black !important;
        margin-top: 6px;
    }
}

@media screen and (min-width: 600px) {
    .lbl-name-aliado {
        height: 33px;
        color: black !important;
        margin-top: 6px;
    }

    .home-loader {
        display: block;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;
    }

    .modal-image-style {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 250px;
        width: auto;
    }

    .green-loader {
        height: auto;
        padding-top: 18px;
        width: 100%;
    }

    .add-img {
        height: auto;
        max-width: 150px;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        display: block;
    }

    .mb-pas-img {
        width: 170px;
        height: 170px;
    }

    .lbl-a {
        font-size: 13px !important;
        height: 20px !important;
        font-weight: bold;
        font-style: oblique;
    }

    .lbl-a2 {
        font-size: 13px !important;
        height: 20px !important;
        font-weight: bold;
        font-style: oblique;
    }

    .mb-pro-img {
        width: 170px;
        height: 170px;
    }

    .pro-style {
        max-width: 170px;
    }

    .pb-style {
        max-width: 170px;
    }

    .envio-label {
        margin-top: -10px;
        font-size: 25px;
    }

    .dt-mt-4 {
        margin-top: 4px;
    }

    .envio-box {
        margin-right: 1%;
    }

    .envio-box2 {
        margin-right: 1%;
    }

    .mb-display {
        display: none !important;
    }

    .mb-cate-cart {
        width: 50px;
    }

    .pas-min-h {
        min-height: 450px;
    }

    .dt-mr13 {
        margin-right: 13%;
    }

    .m-w150 {
        max-width: 150px;
    }

    .lbl-h {
        height: 54px;
        color: black !important;
        margin-top: 6px;
    }

    .desk-font-10 {
        font-size: 13px !important;
    }
}

.bg-orange {
    background-color: $theme-deafult;
}

.max-w100 {
    max-width: 100%;
}

.image45 {
    width: 45px;
    height: 45px !important;
}

.image-clock {
    width: 35px;
    height: 35px !important;
    margin-top: 6px;
}

.img-checkout {
    width: 20px;
    margin-bottom: 5px;
}

.font-25 {
    font-size: 25px;
}

.center70 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    text-align: center;
}

.font-20 {
    font-size: 20px;
}

.font-15 {
    font-size: 15px;
}

.font-10 {
    font-size: 10px !important;
}

.font-9 {
    font-size: 9px !important;
}

.padding-10 {
    padding: 10px;
}

.text-deco {
    text-decoration: underline;
    text-align: right;
    color: black;
    position: relative;
    left: 80%;
}

.no-p-bt {
    padding-bottom: 0 !important;
}

.no-padding {
    padding: 0 !important;
}
.btn-cart {
    background-color: $theme-deafult;
    color: white;
    margin-left: 2px;
    border-radius: 5px !important;
}

.name-h {
    height: 67px;
    color: black !important;
    margin-top: 6px;
}

.mb-sepa-h {
    height: 5px;
}

.w70 {
    width: 70%;
}

.w100 {
    width: 100%;
}

.btn-w {
    width: 100%;
}

.up-image {
    position: relative;
    z-index: 1;
}

.font-15 {
    font-size: 15px !important;
}

.mobile-x {
    font-size: 26px;
}

.image-30 {
    height: 30px !important;
    width: 30px;
}

.li {
    display: list-item;
}

.bold {
    font-weight: bold;
}

.cate-img {
    width: 20px;
}

.special-h {
    height: 45px;
}

.center-special {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    text-align: center;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    left: 83vw;
    position: relative;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    color: grey;
    top: -47px;
    font-size: 30px;
}

.se-span {
    left: 6%;
}

.se-input {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
    height: 53px;
    font-size: 18px;
}

.round-input {
    border-radius: 25px;
    padding: 20px;
    width: 200px;
    height: 15px;
}

.br-r-25 {
    border-radius: 12px !important;
}

.ml-banner-cont {
    margin-left: 21%;
}

.btn-outline-check {
    display: inline-block;
    letter-spacing: 0.05em;
    border: 2px solid #169792;
    position: relative;
    color: #000000;
    border-radius: 25px !important;
    transform: scale(0.8);
}

.btn-solid-check {
    padding: 5px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
}

.cate-lbl {
    text-transform: capitalize;
    text-align: center;
}

.w-input-s {
    width: 365%;
}
.w-100 {
    width: 100%;
}

.ml25 {
    margin-left: 25%;
}
.tal {
    text-align: left;
}

.tac {
    text-align: center;
}

.tal {
    text-align: left;
}

.mb-110 {
    margin-bottom: 110px;
}

.ml17p5 {
    margin-left: 17.5%;
}

.font-poppins {
    font-family: 'Poppins', sans-serif !important;
    font-style: normal !important;
    //outline-color:rgb(89, 124, 122) !important;
    color: black;
}

.btn-link2 {
    font-weight: 400;
    color: $theme-deafult;
    background-color: transparent;
}

.theme-color {
    color: $theme-deafult !important;
}

.wrap-buttom {
    white-space: normal;
}

.no-plr {
    margin-right: 0px;
    margin-left: 0px;
}

.flr {
    float: right;
}

.sandwich-14 {
    margin-left: 14px;
    margin-right: 14px;
}

.fluio-load {
    background-color: white;
    height: 100vw;
    width: 100vw;
}

.h-196 {
    height: 196px;
}

.error-text {
    font-size: 50px !important;
    color: #dc3545 !important;
}

.bold-text-black {
    font-weight: bold !important;
    color: black !important;
}

.hr-cart {
    border: 1px solid;
    margin-left: 10%;
    margin-right: 10%;
}

.price-plan {
    font-size: 10px !important;
}

@media screen and (max-width: 600px) and (min-height: 737px) {
    .space1 {
        height: 45px;
    }

    .space2 {
        height: 15px;
    }

    .space3 {
        height: 30px;
    }

    .space4 {
        height: 30px;
    }
}

.btn-pay {
    background-color: $theme-deafult;
    border-radius: 5px !important;
    border-color: $theme-deafult !important;
    color: white;
}

.btn-round {
    border-radius: 5px !important;
}

.ml23p8 {
    margin-left: 23.8%;
}

.center53 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 53%;
}

.ttn {
    text-transform: none !important;
}

.font-13 {
    font-size: 13px !important;
}

button {
    text-transform: none !important;
    border-radius: 5px !important;
}
a {
    text-transform: none !important;
    border-radius: 5px !important;
}
h2 {
    text-transform: none !important;
}
h3 {
    text-transform: none !important;
}
div {
    text-transform: none !important;
}
h6 {
    text-transform: none !important;
}
td {
    text-transform: none !important;
}
strong {
    text-transform: none !important;
}
th {
    text-transform: none !important;
}
h4 {
    text-transform: none !important;
}

.quienes-img {
    height: 100px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    display: block;
}

.porque-img {
    height: 105px;
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    display: block;
}

.mw50 {
    max-width: 50% !important;
}

.end-pasillo {
    border-right: 3px solid #80808075;
    padding-right: 3px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.tdu {
    text-decoration: underline;
}

.desc-cate-style {
    color: gray;
}

/*
.react-multi-carousel-track {

  @media screen and (max-width: 600px){
    li{
      max-width: 150px;
    }

  }

}
*/

@keyframes fadeInImg {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.img-loading {
    opacity: 0;
    width: 100%;
    height: auto;
}

.img-loaded {
    animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-delay: 0.1s;
}

.soporte-icon {
    font-size: 25px;
    padding: 10px;
    color: $theme-deafult;
}

.google-badge {
    width: 150px;
}

.apple-badge {
    width: 135px;
}

.grey-filter {
    filter: grayscale(80%);
}

.clock-icon {
    font-size: 40px;
    padding: 5px;
    color: black;
}

.date-preview {
    width: 100%;
    text-align: center;
}

.swipe-control {
    position: absolute;
    width: 20%;
    height: 100%;
}

.swipe-control-z {
    position: absolute;
    width: 15%;
    height: 100%;
    z-index: 2;
}

.img-placeholder {
    height: 50px;
    width: 50px;
    color: black;
    background-color: black;
}

.swipe-control-z-md {
    position: absolute;
    width: 40%;
    height: 100%;
    z-index: 2;
}

.slick-slider {
    z-index: 3;
}

.swipe-control-z-pasillo {
    position: absolute;
    width: 13%;
    height: 100%;
    z-index: 4;
}

.slider-box {
    overflow-x: scroll;
    overflow-y: hidden;
}

.slider-box::-webkit-scrollbar {
    display: none !important;
}

.w-max-content {
    width: max-content;
}

.inline {
    display: inline-block;
}

.cerrar-style {
    margin-top: 15px;
}

.styles_closeButton__20ID4 {
    position: absolute;
    top: 44px;
    right: 14px;
    border: none;
    padding: 0;
    background-color: transparent;
    display: flex;
}

.cantidad-modal-label {
    clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.btn-modal-product {
    position: relative;
    top: 40px;
}

.styles_overlay__CLSq- {
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 1000;
    padding: 1.2rem;
    padding-bottom: 30px;
    padding-top: 30px;
}

.categories-slider-box {
    overflow-x: scroll;
    margin-right: 5%;
    margin-left: 5%;
}

.categories-slider-box-home {
    overflow-x: scroll;
    margin-left: 5%;
    margin-bottom: 35px;
}

.categories-slider-box-home::-webkit-scrollbar {
    display: none !important;
}

.categories-slider-box::-webkit-scrollbar {
    display: none !important;
}

.inline-categories {
    display: inline-block;
    font-size: 12px;
    background-color: #f5f7ff;
    border-radius: 106px;
    padding-left: 7px;
    padding-top: 4px;
    padding-bottom: 3px;
    padding-right: 7px;
}

.inline-halls {
    display: inline-block;
    font-size: 14px;
    //background-color: #f5f7ff;
    border-radius: 106px;
    padding-left: 9px;
    padding-top: 5px;
    padding-bottom: 4px;
    padding-right: 8px;
}

.categorie-slider-img {
    width: 22px;
    position: relative;
    top: -3px;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cate-img2 {
    width: 46px;
}

.services-home-img {
    width: 101%;
}

.services-home-img2 {
    width: 114%;
}

.service-home-container {
    margin-right: 2%;
    margin-left: 2%;
}

.services-header-img {
    width: 25%;
}

.loader-img-3 {
    width: 18px;
}

.margin-zero {
    margin: 0;
}

.padding-0 {
    padding: 0 !important;
}
.pl-0 {
    padding-left: 0;
}

.pr-0 {
    padding-right: 0;
}

.match-delivery-icon {
    font-size: 18px;
    margin-left: 4px;
    color: #169792;
}

.item-vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.normal-title {
    color: gray;
    font-size: 14px;
}

.normal-title-sugerido {
    color: gray;
    font-size: 16px;
}
.normal-title-aliado {
    color: gray;
    font-size: 16px;
}

.fluio-font {
    font-family: Georgia, Times, 'Times New Roman', serif;
}

.sandwitch8 {
    margin-left: 8%;
    margin-right: 8%;
}

.sandwitch4 {
    margin-left: 4%;
    margin-right: 4%;
}

.wrap {
    display: flex;
    height: 100%;
}

.inner {
    align-self: center;
}
