.mobile-login-page{
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 90;
    top: 0;
    background: bottom;
    background-color: #f7f7f7;
    background-size: cover;
    overflow-y: auto;
}

.login-title{
    color: #1b9e91;
    letter-spacing: -1px;
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif;
    line-height: 19px;
}

.container-login-page{
    margin-left: 8%;
    margin-right: 8%;
}

.log-user-icon{
    position: relative;
    top: -32px;
    left: 22px;
    font-size: 25px;
    color: white;
}

.log-input{
    background-color: #a5dee3;
    border-radius: 25px !important;
    text-align: center;
    font-family: sans-serif;
    color: white;
    &:focus{
        background-color: #a5dee3;
        padding-left: 55px;text-align: initial;
        &::placeholder{
            color: transparent;
        }
    }
    &::placeholder{
        color: white;
    }
}

.log-form-margin{
    margin-top: 50px;
}

.btn-log {
    background: rgb(1,182,178);
    background: linear-gradient(90deg, rgba(1,182,178,1) 2%, rgba(11,155,159,1) 100%);
    color: white;
    border-radius: 25px !important;
    font-family: sans-serif;
    font-size: 10px !important;
    &:active{
        background: rgb(1,182,178) !important;
        background: linear-gradient(90deg, rgba(1,182,178,1) 2%, rgba(11,155,159,1) 100%);
        color: white;
        border-radius: 25px !important;
        font-family: sans-serif;
        font-size: 10px !important;
    }
}

.log-input-h{
    height: 40px;
}

.log-separator{
    hr{
        color: black;
        border-style: solid;
        width: 42vw;
        margin-left: 0;
        margin-right: 0;
        margin-top: 9px;
        border-width: 1px !important;
    }
    span{
        padding-left: 2vw;
        padding-right: 2vw;
    }
}

.btn-log-social{
    background: rgb(1,182,178);
    background: linear-gradient(90deg, rgba(1,182,178,1) 2%, rgba(11,155,159,1) 100%);
    color: white;
    border-radius: 25px !important;
    font-family: sans-serif;
    width: 100%;
    font-size: 10px !important;
    &:active{
        background: rgb(1,182,178) !important;
        background: linear-gradient(90deg, rgba(1,182,178,1) 2%, rgba(11,155,159,1) 100%);
        color: white;
        border-radius: 25px !important;
        font-family: sans-serif;
        font-size: 10px !important;
        width: 100%;
        font-size: 10px;
    }
}

.log-social-icon{
    top: -28px;
    position: relative;
    left: -34vw;
    font-size: 22px;
}

.gicon-size{
    width: 25px;
}

.fb-color{
    color: #3f5993;;
}

.to-log{
    color: black;
    font-size: 12px;
    text-decoration: underline;
}

.regalo-img{
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2vh;
    top: 0;
}