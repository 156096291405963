@media screen and (max-width: 600px) {

    .rccs__expiry {
        bottom: 15%;
        font-size: 0;
        line-height: 1;
        position: absolute;
        right: -63% !important;
    }

}

@media screen and (min-width: 600px) {


}

.rccs {
    margin: 0 auto;
    -webkit-perspective: 1000px;
    perspective: 1000px;
    width: 290px;
    transform: scale(0.8);
}

.rccs__issuer {
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 22%;
    position: absolute;
    right: 10%;
    top: 10%;
    width: 40% !important;
}


