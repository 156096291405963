@media screen and (max-width: 414px) {

    .fix-table-cart {
        transform: scale(0.92);
        position: relative;
        left: -1%;
    }

    
    .fix-envio {
        position: relative;
        left: -20px;
    }

    .fix-img-cart{
        max-width: 103px;
    }

    .fix-table-cart-plan {
        transform: scale(0.88);
        position: relative;
        left: -3%;
        top: -42px;
    }

    .sub-fix-plan {
        margin-top: -24%;
        position: relative;
        right: 10%;
        tfoot{
            tr{
                td{
                    font-size: 12px !important;
                    h2{
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

    .sub-fix {
        position: relative;
        right: 10%;
        tfoot{
            tr{
                td{
                    font-size: 12px !important;
                    h2{
                        font-size: 12px !important;
                    }
                }
            }
        }
    }

}

@media screen and (max-width: 375px) {

    .fix-img-cart{
        max-width: 103px;
    }

    .fix-table-cart {
        transform: scale(0.8);
        position: relative;
        left: -35px;
        top: -42px;
    }

    .fix-table-cart-plan {
        transform: scale(0.88);
        position: relative;
        left: -10%;
        top: -42px;
    }

    
    .fix-envio {
        position: relative;
        left: -20px;
    }

}

@media screen and (max-width: 320px) {

    .fix-img-cart{
        max-width: 103px;
    }

    .fix-table-cart {
        transform: scale(0.8);
        position: relative;
        left: -15%;
        top: -42px;
    }

    .fix-table-cart-plan {
        transform: scale(0.85);
        position: relative;
        left: -11%;
        top: -42px;
    }

    
    .fix-envio {
        position: relative;
        left: -20px;
    }

}