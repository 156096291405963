/*-----------------------------------------------------------------------------------

    Template Name:Multikart
    Template URI: themes.pixelstrap.com/multikart
    Description: This is E-commerce website
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

----------------------------------------------------------------------------------- */
/* 1. General CSS
   1.1. Button CSS
   1.2. Title CSS
   1.3. Brand logo CSS */
/* 2. Header CSS */
/* 3. Home slider CSS  */
/* 4. Instagram CSS  */
/* 5. Blog CSS */
/* 6. Service CSS  */
/* 7. About CSS  */
/* 8. Banner Timer CSS  */
/* 9. Category CSS */
/* 10. Product Box CSS */
/* 11. Theme tab CSS */
/* 12. Banner CSS */
/* 13. Collection Banner CSS */
/* 14. Product-box slider CSS */
/* 15. Modal popup CSS */
/* 16. Look-book CSS */
/* 17. Demo css
   17.1. look-book
   17.2. beauty demo
   17.3. electronics
   17.4. pets demo
   17.5. metro demo
   17.6. gym demo
   17.7. extra layout css */
/* 18. Flying products CSS */
/* 19. Footer CSS */
/* 20. Loader CSS*/
/* 21. Tap to top CSS */
/* 22. theme setting css */
/* 23. Inner pages CSS */
/* 24. RTL CSS */
/* 25. Dark CSS */
/* 26. Menu CSS */
/* 27. Responsive CSS */

/*=====================
    1.General CSS start
==========================*/
body {
    font-family: $font_0, $font_1;
    position: relative;
    background: $white;
    font-size: 14px;
    overflow-x: hidden;
    letter-spacing: 0.8px;
}
h1 {
    font-size: 60px;
    color: $dark-font;
    font-weight: 700;
    text-transform: uppercase;
    span {
        font-size: 107px;
        font-weight: 700;
        color: $theme-deafult;
    }
}
h2 {
    font-size: 36px;
    color: $dark-font;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
}
h3 {
    font-size: 24px;
    font-weight: 400;
    color: $grey;
    letter-spacing: 0.03em;
}
h4 {
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1;
}
h5 {
    font-size: 16px;
    font-weight: 400;
    color: $dark-font;
    line-height: 24px;
    letter-spacing: 0.05em;
}
h6 {
    font-size: 14px;
    font-weight: 400;
    color: $grey;
    line-height: 24px;
}
ul {
    padding-left: 0;
    margin-bottom: 0;
}
li {
    display: inline-block;
}
p {
    font-size: 14px;
    color: $grey;
    line-height: 1;
}
a {
    transition: 0.5s ease;
    &:hover {
        text-decoration: none;
        transition: 0.5s ease;
    }
    &:focus {
        outline: none;
    }
}
button {
    &:focus {
        outline: none;
    }
}
.white {
    background-color: #fff;
    border: 1px solid #aaa;
}
.blue {
    background-color: #4695b1;
}
.pink {
    background-color: pink;
}
.yellow {
    background-color: #fdf791;
}
.red {
    background-color: #b65d60;
}
.gray {
    background-color: #bababa;
}
.green {
    background-color: #7ee768;
}
.orange {
    background-color: rgba(230, 184, 71, 0.92);
}
.black {
    background-color: #1b1b1b;
}
section,
.section-t-space {
    padding-top: 15px;
}
.section-b-space {
    padding-bottom: 15px;
}
.p-t-0 {
    padding-top: 0;
}
hr.style1 {
    width: 75px;
    height: 3px;
    margin-top: 13px;
    background-color: $theme-deafult;
    text-align: center;
}
.no-arrow {
    .slick-next,
    .slick-prev {
        display: none !important;
    }
}
.icon-angle-left:before {
    content: '\E64A';
}
.form-control {
    border-radius: 0;
}
.small-section {
    padding-top: 35px;
    padding-bottom: 35px;
}
.banner-padding {
    padding-top: 30px;
}
.border-section {
    border-top: 1px solid lighten($black, 86.5%);
    border-bottom: 1px solid lighten($black, 86.5%);
}
.border-b {
    border-bottom: 1px solid $border-color;
}
.border-bottom-grey {
    border-bottom: 1px solid $grey3;
}
.border-top-grey {
    border-top: 1px solid $grey3;
}
.darken-layout {
    background-color: $grey-darken;
}
.dark-layout {
    background-color: $grey-dark;
}
.light-layout {
    background-color: $grey-lighter;
}
.white-layout {
    background-color: $white;
}
.bg-light0 {
    background-color: $light-grey;
}
.bg-light1 {
    background-color: $pink;
}
.bg-light2 {
    background-color: $blue;
}
.bg-blog {
    background-color: $white-dark;
}
.Toastify__toast {
    min-height: 36px;
}
.input-range__track--active {
    background: $theme-deafult !important;
}
.input-range__slider {
    background: $theme-deafult !important;
    border: 1px solid $theme-deafult !important;
}
.block-price-content {
    .custom-control {
        padding-right: 1.5rem;
        padding-bottom: 18px;
    }
}
del {
    font-size: 14px;
    color: $font-grey;
    font-weight: 400;
}
.sidenav .sidebar-back {
    display: flex;
    align-items: center;
}
.srv-validation-message {
    color: red;
}
.sticky {
    &.fixed {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 999;
        transition: all 300ms ease;
        background: #fff;
        box-shadow: 0px 0px 8px #ddd;
        animation: smoothScroll 1s forwards;
        .brand-logo {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .onhover-div {
            .show-div {
                top: 66px;
            }
        }
        .pixelstrap > li > a,
        .pixelstrap > li > a:hover,
        .pixelstrap > li > a:active {
        }
        .onhover-div {
        }

        .top-header {
            display: none;
        }
        .cart-qty-cls {
            top: 13%;
            right: -11px;
        }
    }
}
@-webkit-keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
@keyframes smoothScroll {
    0% {
        -webkit-transform: translateY(-40px);
        transform: translateY(-40px);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}
.shopping-cart {
    &::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #ddd;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $theme-deafult;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
[data-notify='progressbar'] {
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
}
.progress-bar {
    background-color: #19a340;
}
.progress-bar-info {
    background-color: #00829a;
}
// image ratio //
.ratio_40 {
    .bg-size {
        &:before {
            padding-top: 40%;
            content: '';
            display: block;
        }
    }
}
.ratio_45 {
    .bg-size {
        &:before {
            padding-top: 45%;
            content: '';
            display: block;
        }
    }
}
.ratio2_1 {
    .bg-size {
        &:before {
            padding-top: 50%;
            content: '';
            display: block;
        }
    }
}
.ratio2_3 {
    .bg-size {
        &:before {
            padding-top: 60%;
            content: '';
            display: block;
        }
    }
}
.ratio3_2 {
    .bg-size {
        &:before {
            padding-top: 66.66%;
            content: '';
            display: block;
        }
    }
}
.ratio_landscape {
    .bg-size {
        &:before {
            padding-top: 75%;
            content: '';
            display: block;
        }
    }
}
.ratio_square {
    .bg-size {
        &:before {
            padding-top: 100%;
            content: '';
            display: block;
        }
    }
}
.ratio_asos {
    .bg-size {
        &:before {
            padding-top: 127.7777778%;
            content: '';
            display: block;
        }
    }
}
.ratio_portrait {
    .bg-size {
        &:before {
            padding-top: 150%;
            content: '';
            display: block;
        }
    }
}
.ratio1_2 {
    .bg-size {
        &:before {
            padding-top: 200%;
            content: '';
            display: block;
        }
    }
}
.b-top {
    background-position: top !important;
}
.b-bottom {
    background-position: bottom !important;
}
.b-center {
    background-position: center !important;
}
.b_size_content {
    background-size: contain !important;
    background-repeat: no-repeat;
}

/*=====================
    1.1.Button CSS start
==========================*/
button {
    cursor: pointer;
}
.btn {
    line-height: 20px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    border-radius: 0;
    transition: 0.3s ease-in-out;
    &:hover {
        transition: 0.3s ease-in-out;
    }
    &:focus {
        box-shadow: none;
    }
}
.btn-solid {
    padding: 13px 29px;
    color: $white !important;
    letter-spacing: 0.05em;
    border: 2px solid $theme-deafult;
    background-color: $theme-deafult;
    background-size: 540px;
    background-repeat: no-repeat;
    background-position: 0;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    &:hover {
        background-position: 100%;
        color: $black !important;
        background-color: $white;
    }
    &.black-btn {
        background-image: -webkit-linear-gradient(30deg, $dark-font 50%, transparent 50%);
        background-image: linear-gradient(30deg, $dark-font 50%, transparent 50%);
        border: 2px solid $dark-font;
    }
    &:focus {
        color: $white;
    }
}
.btn-outline {
    display: inline-block;
    padding: 13px 29px;
    letter-spacing: 0.05em;
    border: 2px solid $theme-deafult;
    position: relative;
    color: $black;
    &:before {
        -webkit-transition: 0.5s all ease;
        transition: 0.5s all ease;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        bottom: 0;
        opacity: 0;
        content: '';
        background-color: $theme-deafult;
        z-index: -2;
    }
    &:hover {
        color: $theme-deafult;
        &:before {
            -webkit-transition: 0.5s all ease;
            transition: 0.5s all ease;
            left: 0;
            right: 0;
            opacity: 1;
        }
    }
    &:focus {
        &:before {
            transition: 0.5s all ease;
            left: 0;
            right: 0;
            opacity: 1;
        }
    }
}
button {
    &.btn {
        &.btn-solid,
        &.btn-outline {
            &:active {
                background-image: -webkit-linear-gradient(30deg, $theme-deafult 50%, transparent 50%);
                background-image: linear-gradient(30deg, $theme-deafult 50%, transparent 50%);
                color: $white;
                background: $theme-deafult;
            }
        }
    }
}
.btn-classic {
    &:hover {
        background-color: $theme-deafult;
    }
}
.btn-theme {
    background-color: $theme-deafult;
    color: $white;
}

/*=====================
    1.2.Title CSS start
    ==========================*/
.title1 {
    text-align: center;

    h4 {
        color: $theme-deafult;
        padding-bottom: 10px;
        margin-bottom: 0;
        margin-top: -3px;
    }

    .title-inner1 {
        padding-bottom: 15px;
        margin-bottom: 30px;
        position: relative;

        &:after {
            position: absolute;
            height: 5px;
            width: 70px;
            background-color: $theme-deafult;
            content: '';
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
        }
    }
    &.title5 {
        .title-inner1 {
            padding-bottom: 0;
            margin-bottom: 0;
            &:after {
                display: none;
            }
        }
        hr[role='tournament6'] {
            border: 0px solid;
            height: 1px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba($theme-deafult, 0.75), rgba(0, 0, 0, 0));
            display: block;
            width: 25%;
            margin: 15px auto 30px auto;
        }
        hr[role='tournament6']::before {
            position: absolute;
            background-color: #efefef;
            border: 1px solid;
            border-color: $theme-deafult;
            padding: 5px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            left: 50%;
            margin: -6px 0px 0px 0;
            content: '';
        }
    }
}
.title2 {
    text-align: center;
    h4 {
        color: $theme-deafult;
        padding-bottom: 10px;
        margin-bottom: 0;
        text-transform: uppercase;
        margin-top: -3px;
    }
    .title-inner2 {
        margin-bottom: 50px;
        position: relative;
        display: inline-block;
        padding: 0 25px;
        &:after {
            position: absolute;
            height: 5px;
            width: 100%;
            background-color: lighten($theme-deafult, 10%);
            content: '';
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 3px;
            z-index: -1;
        }
    }
}
.title3 {
    text-align: center;
    h4 {
        color: $theme-deafult;
        padding-bottom: 10px;
        margin-bottom: 5px;
        margin-top: -3px;
    }
    .title-inner3 {
        margin-bottom: 20px;
        position: relative;
        margin-top: -5px;
    }
    .line {
        position: relative;
        height: 5px;
        width: 45px;
        background-color: $theme-deafult;
        content: '';
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 0;
        margin-bottom: 50px;
        &:after,
        &:before {
            position: absolute;
            height: 5px;
            width: 10px;
            content: '';
            background-color: $theme-deafult;
        }
        &:after {
            right: 50px;
        }
        &:before {
            left: 50px;
        }
    }
}
.title4 {
    text-align: center;
    .title-inner4 {
        padding-bottom: 25px;
        margin-bottom: 0;
        position: relative;
        margin-top: -5px;
    }
    .line {
        position: relative;
        height: 1px;
        width: 180px;
        background-color: $theme-deafult;
        margin: 0 auto;
        margin-bottom: 55px;
        bottom: 0;
        top: 0;
        &:after,
        &:before {
            position: absolute;
            height: 10px;
            width: 10px;
            content: '';
            background-color: $theme-deafult;
            top: -4px;
        }
        &:after {
            left: 78px;
        }
        &:before {
            left: 64px;
        }
        span {
            &:after,
            &:before {
                position: absolute;
                height: 10px;
                width: 10px;
                content: '';
                background-color: $theme-deafult;
                top: -4px;
            }
            &:after {
                right: 78px;
            }
            &:before {
                right: 64px;
            }
        }
    }
}
.title-borderless {
    margin-bottom: 30px;
    text-align: center;
    margin-top: -5px;
}

/*=====================
    1.3.Brand logo CSS start
    ==========================*/
.logo-block {
    a {
        &:focus {
            outline: none;
        }
    }

    img {
        filter: grayscale(100%);
        opacity: 0.5;
        transition: 0.5s ease;
        margin: 0 auto;

        &:focus {
            outline: none;
        }

        &:hover {
            filter: grayscale(0);
            opacity: 1;
            transition: 0.5s ease;
        }
    }
}

/*=====================
    2.Header CSS start
==========================*/
header {
    background-color: $white;
}
.onhover-dropdown {
    position: relative;
    font-size: 14px;
    &:before {
        content: '\f107';
        font-family: $font-awesome;
        position: absolute;
        right: 3px;
    }
    .onhover-show-div {
        top: 50px;
        position: absolute;
        z-index: 10;
        background-color: $white;
        transition: all linear 0.3s;
        min-width: 160px;
        text-align: left;
        -webkit-box-shadow: 0 0 1px 0 #ebebeb;
        -moz-box-shadow: 0 0 1px 0 #ebebeb;
        box-shadow: 0 0 1px 0 #ebebeb;
        transform: translateY(30px);
        opacity: 0;
        visibility: hidden;
        right: 0;
        padding: 15px 20px;
        border-radius: 0 !important;
        &.product-page-full {
            z-index: 99999;
        }
    }
    &:hover {
        .onhover-show-div {
            opacity: 1;
            transform: translateY(0px);
            visibility: visible;
        }
    }
}
.onhover-div {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: $font_0, $font_1;
    img,
    i {
        cursor: pointer;
    }
    .show-div {
        top: 103px;
        position: absolute;
        z-index: 9;
        background-color: $white;
        transition: all linear 0.3s;
        min-width: 236px;
        text-align: left;
        -webkit-box-shadow: 0 0 1px 0 #ebebeb;
        -moz-box-shadow: 0 0 1px 0 #ebebeb;
        box-shadow: 0 0 1px 0 #ebebeb;
        transform: translateY(30px);
        opacity: 0;
        visibility: hidden;
        right: 0;
        padding: 0;
        border-radius: 0 !important;
    }
    &:hover {
        .show-div {
            opacity: 1;
            transform: translateY(0px);
            visibility: visible;
        }
    }
}
.search-overlay {
    height: 100%;
    width: 100%;
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: lighten($white, 5%);
    > div {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .overlay-content {
            width: 100%;
            .form-control {
                &:focus {
                    box-shadow: none;
                }
            }
            input {
                background-color: transparent;
                border: 0;
                border-bottom: 2px solid lighten($black, 46.5%);
                border-radius: 0;
                padding: 20px 0;
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: lighten($black, 46.5%);
                    font-size: 18px;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: lighten($black, 46.5%);
                    font-size: 18px;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: lighten($black, 46.5%);
                    font-size: 18px;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: lighten($black, 46.5%);
                    font-size: 18px;
                }
            }
            button {
                position: absolute;
                top: 10px;
                background-color: transparent;
                border: 0;
                font-size: 24px;
                color: lighten($black, 46.5%);
                right: 10px;
            }
        }
        .closebtn {
            position: fixed;
            top: 10px;
            right: 25px;
            color: lighten($black, 46.5%);
            font-size: 40px;
            cursor: pointer;
        }
    }
}
.top-header {
    background-color: $top-header;
    .header-contact {
        padding: 15px 0;
        li {
            color: $header-font;
            font-size: 14px;
            padding-right: 25px;
            i {
                color: $theme-deafult;
                padding-right: 10px;
            }
        }
    }
    .header-dropdown {
        li {
            cursor: pointer;
            color: $header-font;
            padding: 15px 25px;
            padding-right: 20px;
            font-size: 14px;
            &:first-child {
                padding-right: 0;
            }
            r &:nth-child(2) {
                padding-right: 25px;
            }
            a {
                text-transform: capitalize;
                color: $header-font;
            }
            i {
                padding-right: 5px;
                transition: all 0.5s ease;
            }
            ul {
                li {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
            a {
                i {
                    color: $header-font;
                    transition: all 0.5s ease;
                }
                &:hover {
                    i {
                        color: $theme-deafult;
                        transition: all 0.5s ease;
                    }
                }
            }
            &:hover {
                i {
                    color: $theme-deafult;
                    transition: all 0.5s ease;
                }
                z-index: 8;
            }
        }
        .onhover-dropdown {
            .onhover-show-div {
                li {
                    padding-left: 0;
                    display: flex;
                    font-size: 15px;
                    padding-bottom: 5px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                    a {
                        color: $font-color;
                    }
                }
            }
        }
    }
    &.top-header-dark {
        background-color: $dark-font;
        .header-contact {
            li {
                color: $grey;
                i {
                    color: $grey;
                }
            }
        }
        .header-dropdown {
            li {
                color: $grey;
                a {
                    color: $grey;
                    i {
                        color: $grey;
                    }
                    &:hover {
                        i {
                            color: $theme-deafult;
                        }
                    }
                }
            }
        }
    }
    &.top-header-dark2 {
        background-color: $grey-dark;
        .header-contact {
            li {
                color: $grey-shade;
                i {
                    color: $grey-shade;
                }
            }
        }
        .header-dropdown {
            li {
                color: $grey-shade;
                a {
                    color: $grey-shade;
                    i {
                        color: $grey-shade;
                    }
                    &:hover {
                        i {
                            color: $theme-deafult;
                        }
                    }
                }
            }
        }
    }
    &.top-header-dark3 {
        background-color: $grey-darken;
        .header-contact {
            li {
                color: $grey-link;
                i {
                    color: $grey-link;
                }
            }
        }
        .header-dropdown {
            li {
                color: $grey-link;
                a {
                    color: $grey-link;
                    i {
                        color: $grey-link;
                    }
                    &:hover {
                        i {
                            color: $theme-deafult;
                        }
                    }
                }
            }
        }
    }
}
.layout3-menu {
    .main-menu {
        .menu-left {
            .navbar {
                i {
                    font-size: 22px;
                }
            }
        }
    }
}
.main-menu {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 7;
    &.product-full-page {
        z-index: 9999;
    }
    .brand-logo {
        display: inline-block;
        padding-bottom: 35px;
    }
    .menu-left {
        display: flex;
        align-items: center;
        .navbar {
            display: inline-block;
            padding: 40px 45px 40px 0;
            i {
                font-size: 28px;
                color: $dark-font;
                cursor: pointer;
            }
            &.white-navbar {
                i {
                    color: $white;
                }
            }
        }
        &.around-border {
            .navbar {
                > a {
                    border: 2px solid lighten($theme-deafult, 35%);
                    padding: 11px 10px 6px;
                }
            }
        }
        &.category-nav-right {
            .navbar {
                padding-right: 0;
                padding-left: 45px;
                > a {
                    padding: 0;
                }
                i {
                    color: $sidebar-color;
                }
            }
        }
    }
    .menu-right {
        display: flex;
        float: right;
        .icon-nav {
            display: inline-block;
            li {
                padding-left: 20px;
            }
            .onhover-div {
                .show-div {
                    &.shopping-cart {
                        padding: 15px 15px;
                        min-width: 270px;
                        left: unset;
                        right: 0;
                        max-height: 250px;
                        overflow-y: auto;
                        li {
                            position: relative;
                            padding-bottom: 10px;
                            width: 100%;
                            &:last-child {
                                padding-bottom: 0;
                            }
                            .media {
                                img {
                                    height: 90px;
                                }
                                .media-body {
                                    align-self: center;
                                    h4 {
                                        color: $font-color;
                                        span {
                                            color: $grey;
                                            font-size: 16px;
                                        }
                                    }
                                }
                            }
                            .close-circle {
                                position: absolute;
                                top: 0;
                                right: 0;
                                i {
                                    color: $grey-light;
                                    transition: all 0.3s ease;
                                    &:hover {
                                        color: $black;
                                        transition: all 0.3s ease;
                                    }
                                }
                            }
                            .total {
                                border-top: 1px solid $border-grey;
                                border-bottom: 1px solid $border-grey;
                                padding-top: 10px;
                                padding-bottom: 10px;
                                h5 {
                                    text-transform: capitalize;
                                    margin-bottom: 0;
                                    color: $header-font;
                                    span {
                                        float: right;
                                    }
                                }
                            }
                            .buttons {
                                a {
                                    font-size: 16px;
                                    color: $font-color;
                                    text-transform: capitalize;
                                    font-weight: 700;
                                    &:hover {
                                        color: $theme-deafult;
                                    }
                                }
                                .checkout {
                                    float: right;
                                }
                            }
                        }
                    }
                    &.setting {
                        padding: 15px 20px 20px;
                        min-width: 175px;
                        right: 0;
                        left: unset;
                        h6 {
                            font-size: 16px;
                            text-transform: capitalize;
                            color: $font-color;
                            font-weight: 700;
                            margin-bottom: 0;
                            margin-top: 5px;
                            &:first-child {
                                margin-top: 0;
                            }
                        }
                        ul {
                            li {
                                display: flex;
                                padding-bottom: 0;
                                padding-left: 17px;
                                padding-top: 5px;
                                a {
                                    color: $font-color;
                                    font-weight: 400;
                                    font-size: 16px;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                    li {
                        padding: 0;
                        .form-control {
                            border-radius: 0;
                            &:focus {
                                border: 1px solid #ced4da;
                                box-shadow: none;
                            }
                        }
                        .search-btn {
                            padding: 8px 12px;
                            background-color: $theme-deafult;
                            color: $white;
                        }
                    }
                    ul {
                        li {
                            a {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            &.white-icon {
                .onhover-div {
                    img {
                        filter: brightness(5);
                    }
                    .show-div {
                        img {
                            filter: none;
                        }
                    }
                }
            }
        }
    }
}
.nav-cat {
    li {
        position: relative;
        display: flex;
        padding-left: 25px;
        padding-right: 25px;
        .round-cat {
            height: 50px;
            width: 50px;
            border: 1px solid #dddddd;
            padding: 12px;
            border-radius: 100%;
            margin-right: 10px;
        }
        a {
            color: $dark-font;
            font-size: 16px;
            text-transform: uppercase;
        }
    }
}
.Toastify__toast--info {
    background: #007bff;
}
.Toastify__toast--success {
    background: #22af47;
}
.Toastify__toast--warning {
    background: #ff850d;
}
.Toastify__toast--error {
    background: #ff2046;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

/*=====================
    3.Home slider CSS start
==========================*/
.home-slider,
.center-slider {
    .slick-prev,
    .slick-next {
        opacity: 0;
        transform: scale(1);
        transition: all 0.5s ease;
        margin-top: -10px;
        &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: rgba($white, 0.5);
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .slick-next {
        right: 1px;
        &:before {
            content: '\f105';
        }
    }
    .slick-prev {
        left: 1px;
        z-index: 1;
        &:before {
            content: '\f104';
        }
    }
    .home {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 75vh;
        @for $i from 1 through 56 {
            &.home#{$i} {
                background-image: url('#{$assetspath}/home-banner/#{$i}.jpg');
            }
        }
    }

    .slider-contain {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        h1 {
            margin-bottom: 0;
            margin-top: 10px;
            color: $dark-font;
        }
        h4 {
            font-weight: 700;
            letter-spacing: 0.4em;
            color: $grey;
            margin-bottom: 0;
        }
        .btn-solid,
        .btn-outline {
            margin-top: 20px;
        }
    }
    .p-left {
        .slider-contain {
            justify-content: flex-start;
        }
    }
    .p-right {
        .slider-contain {
            justify-content: flex-end;
        }
    }
    .p-center {
        .slider-contain {
            justify-content: center;
        }
    }
    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
            transform: scale(2.5);
            transition: all 0.5s ease;
            &:before {
                opacity: 1;
            }
        }
        .slick-next {
            right: 100px;
        }
        .slick-prev {
            left: 100px;
        }
    }
}

.cate-slider,
.center-slider {
    .slick-prev,
    .slick-next {
        opacity: 0;
        transform: scale(1);
        transition: all 0.5s ease;
        margin-top: -10px;
        &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: rgba($white, 0.5);
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .slick-next {
        right: 1px;
        &:before {
            content: '\f105';
        }
    }
    .slick-prev {
        left: 1px;
        z-index: 1;
        &:before {
            content: '\f104';
        }
    }
    .home {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 75vh;
        @for $i from 1 through 56 {
            &.home#{$i} {
                background-image: url('#{$assetspath}/home-banner/#{$i}.jpg');
            }
        }
    }

    .slider-contain {
        width: 100%;
        height: 75vh;
        display: flex;
        align-items: center;
        h1 {
            margin-bottom: 0;
            margin-top: 10px;
            color: $dark-font;
        }
        h4 {
            font-weight: 700;
            letter-spacing: 0.4em;
            color: $grey;
            margin-bottom: 0;
        }
        .btn-solid,
        .btn-outline {
            margin-top: 20px;
        }
    }
    .p-left {
        .slider-contain {
            justify-content: flex-start;
        }
    }
    .p-right {
        .slider-contain {
            justify-content: flex-end;
        }
    }
    .p-center {
        .slider-contain {
            justify-content: center;
        }
    }
    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
            transform: scale(2.5);
            transition: all 0.5s ease;
            &:before {
                opacity: 1;
            }
        }
        .slick-next {
            right: 100px;
        }
        .slick-prev {
            left: 100px;
        }
    }
}

.home-slider-container {
    .slider-contain {
        padding: 0 100px;
    }
}
.center-slider {
    border-left: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
}
.home-slider {
    &.text-white {
        .slider-contain {
            h1,
            h4 {
                color: $white;
            }
        }
    }
}
.gym-slider {
    .home-slider {
        .home {
            height: 85vh;
            .slider-contain {
                height: 85vh;
                h4,
                h1 {
                    color: $white;
                }
            }
        }
    }
}
.metro-slider {
    .home-slider {
        .home {
            background-position-y: 70%;
            .slider-contain {
                margin-top: 75px;
                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}
.small-slider {
    .home-slider {
        .home {
            height: 55vh;
            .slider-contain {
                height: 37vh;
                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}
.height-100 {
    .home-slider {
        .home {
            height: 100vh;
        }
        .slider-contain {
            height: calc(99vh + 80px);
        }
    }
}
.height-85 {
    .home-slider {
        .home {
            height: 85vh;
        }
        .slider-contain {
            height: 85vh;
        }
    }
    &.content_top {
        .slider-contain {
            padding-top: 150px;
            align-items: flex-start;
            h1 {
                font-size: 40px;
                letter-spacing: 0.05em;
            }
            .btn-solid {
                padding: 9px 18px;
                margin-top: 10px;
            }
            h4 {
                letter-spacing: 0.08em;
            }
        }
    }
}
.layout-7 {
    .home {
        height: 60vh;
        .slider-contain {
            height: 60vh;
        }
    }
}
.banner-slider {
    .height-banner {
        height: 100%;
    }
    .home {
        height: 81vh;
        .slider-contain {
            height: 81vh;
        }
    }
    .home-banner {
        > div {
            img {
                width: 100%;
            }
            &:last-child {
                img {
                    margin-top: 30px;
                }
            }
        }
    }
}
.absolute-banner {
    margin-top: -105px;
    .absolute-bg {
        background-color: white;
        position: relative;
        padding: 25px;
        box-shadow: 0 0 8px 0 #ddd;
    }
}

/*=====================
    4.Instagram CSS start
==========================*/
.instagram-box {
    position: relative;
    background-color: $theme-deafult;
    overflow: hidden;
    img {
        width: 100%;
    }
    .overlay {
        transition: 0.5s ease;
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($theme-deafult, 0.5);
        top: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: scale(3) rotate(90deg);
        i {
            font-size: 60px;
            color: $white;
        }
    }
    &:hover {
        .overlay {
            opacity: 1;
            transition: 0.5s ease;
            transform: scale(1) rotate(0deg);
        }
    }
}

/*=====================
    5.Blog CSS start
==========================*/
.blog {
    .slick-slide {
        line-height: 1;
    }
}
.blog-bg {
    background-color: $border-grey;
}
.classic-effect {
    position: relative;
    overflow: hidden;
    &:before,
    &:after {
        background: $theme-deafult;
        width: 25%;
        position: absolute;
        content: '';
        opacity: 0;
        -webkit-transition: all 0.3s steps(4);
        transition: all 0.3s steps(4);
        z-index: 1;
        bottom: 100%;
        top: 0;
    }
    &:before {
        left: 0;
        transition-delay: 0s;
    }
    &:after {
        left: 25%;
        transition-delay: 0.025s;
    }
    span {
        &:before,
        &:after {
            background: $theme-deafult;
            width: 25%;
            position: absolute;
            content: '';
            opacity: 0;
            -webkit-transition: all 0.3s steps(4);
            transition: all 0.3s steps(4);
            z-index: 1;
            bottom: 100%;
            top: 0;
        }
        &:before {
            left: 50%;
            transition-delay: 0.05s;
        }
        &:after {
            left: 75%;
            transition-delay: 0.15s;
        }
    }
    &:hover {
        &:before,
        &:after {
            bottom: 0;
            opacity: 0.5;
        }
        span {
            &:before,
            &:after {
                bottom: 0;
                opacity: 0.5;
            }
        }
    }
}
.blog-details {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;

    h4 {
        color: $theme-deafult;
        margin-top: 25px;
        line-height: 1;
        margin-bottom: 0;
    }
    p {
        color: $dark-font;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 1.5;
        letter-spacing: 0.03em;
        margin-top: 10px;
        margin-bottom: -5px;
        transition: all 0.5s ease;
        &:hover {
            color: $theme-deafult;
            transition: all 0.5s ease;
        }
    }
    h6 {
        line-height: 1;
        letter-spacing: 0.1em;
        margin-bottom: 0;
    }
}
.blog-2 {
    .blog-details {
        h6 {
            padding: 5px 0;
        }
    }
}

/*=====================
    6.Service CSS start
==========================*/
.service-block {
    display: flex;
    align-items: center;
    justify-content: center;
    .media {
        padding-top: 23px;
        padding-bottom: 23px;
        align-items: center;
    }
    svg {
        margin-right: 12px;
        width: 60px;
        height: 60px;
        path {
            fill: $theme-deafult;
        }
    }
    h4 {
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.03em;
    }
    p {
        text-transform: capitalize;
        margin-bottom: 0;
    }

    + .service-block {
        border-left: 1px solid lighten($black, 86.5%);
    }
    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }
        h4 {
            color: $theme-deafult;
            transition: all 0.3s ease;
        }
    }
}
.service-block1 {
    text-align: center;
    svg {
        margin-bottom: 20px;
        width: 60px;
        height: 60px;
        path {
            fill: $theme-deafult;
        }
    }
    h4 {
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 15px;
    }
    p {
        letter-spacing: 0.03em;
        margin-bottom: -3px;
        line-height: 1.5;
    }
    &:hover {
        svg {
            animation: pulse 1000ms ease-in-out;
            transition: all 0.3s ease;
        }
        h4 {
            color: $theme-deafult;
            transition: all 0.3s ease;
        }
    }
}

/*=====================
    7.About CSS start
==========================*/
.about-text {
    p {
        line-height: 28px;
        letter-spacing: 0.06em;
        text-align: center;
        margin-bottom: 50px;
    }
}

/*=====================
    8.Banner Timer CSS start
==========================*/
.banner-timer {
    background-image: url('#{$assetspath}/offer-banner.jpg');
    background-size: cover;
    margin-left: 20px;
    margin-right: 20px;
    .banner-text {
        padding-top: 45px;
        padding-bottom: 45px;
        h2 {
            margin-bottom: 0;
            text-align: center;
            span {
                color: $theme-deafult;
            }
        }
    }
    .timer-box {
        justify-content: center;
        display: flex;
    }
}
.timer {
    padding-top: 15px;
    padding-bottom: 7px;
    padding-left: 40px;
    margin-top: 30px;
    background-color: $grey-dark;
    display: inline-block;
    p {
        font-size: 18px;
        color: $white;
        margin-bottom: 0;
    }
    span {
        width: 70px;
        display: inline-block;
        .timer-cal {
            font-size: 12px;
            color: $grey;
        }
        .padding-l {
            padding-left: 22px;
            display: inline;
        }
    }
}

/*=====================
    9.Category CSS start
==========================*/
.category-block {
    .category-image {
        margin: 0 auto;
        text-align: center;
        border-radius: 100%;
        width: 65px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.5s ease;
    }
    .category-details {
        margin-top: 15px;
        text-align: center;
        h5 {
            font-weight: 700;
            margin: 0 auto;
            transition: 0.5s ease;
            text-transform: uppercase;
        }
    }
    &:hover {
        .category-details {
            h5 {
                color: $theme-deafult;
                transition: all 0.5s ease;
            }
        }
    }
}
.category-border {
    padding: 20px 10px;
    .border-padding {
        padding: 0 10px;
    }
    div {
        .category-banner {
            padding: 0;
            position: relative;
            overflow: hidden;
            img {
                transform: scale(1);
                transition: 0.5s ease;
            }
            &:hover {
                img {
                    transform: scale(1.1) translateX(14px);
                    transition: 0.5s ease;
                }
                h2 {
                    color: $theme-deafult;
                    transition: 0.5s ease;
                }
            }
            .category-box {
                z-index: 1;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                display: flex;
                h2 {
                    background-color: $white;
                    display: inline-block;
                    padding: 20px 35px;
                    margin-bottom: 0;
                    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.4);
                    transition: 0.5s ease;
                }
            }
        }
    }
}
.category-bg {
    background-color: lighten($theme-deafult, 30%);
    padding: 40px 50px;
    .image-block {
        img {
            width: 100%;
        }
        &.even {
            &:after {
                bottom: 94%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            background-color: lighten($theme-deafult, 30%);
            height: 0;
            width: 40px;
            z-index: 1;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 0;
            transition: 0.5s ease;
            margin-top: -40px;
        }
        &:hover {
            &:after {
                opacity: 1;
                transition: 0.5s ease;
                height: 80px;
            }
        }
    }
    .contain-block {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        height: 100%;
        background-color: $white;
        &.even {
            &:after {
                top: 94%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            background-color: lighten($theme-deafult, 30%);
            height: 0;
            width: 40px;
            z-index: 1;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 0;
            transition: 0.5s ease;
            bottom: 94%;
        }
        &:hover {
            h2 {
                color: $theme-deafult;
                transition: all 0.5s ease;
            }
            h6 {
                span {
                    color: $theme-deafult;
                    transition: all 0.5s ease;
                }
            }
            .category-btn {
                background-image: linear-gradient(30deg, $dark-font 50%, transparent 50%);
                border: 2px solid $dark-font;
                transition: all 0.5s ease;
            }
            &:after {
                opacity: 1;
                transition: all 0.5s ease;
                height: 80px;
            }
        }
        h2 {
            margin-bottom: 0;
            margin-top: 15px;
            transition: 0.5s ease;
        }
        h6 {
            color: $theme-deafult;
            text-transform: uppercase;
            letter-spacing: 0.3em;
            line-height: 1;
            margin-bottom: 0;
            span {
                color: $grey-shade;
                letter-spacing: 0.03em;
                font-weight: 700;
                transition: all 0.5s ease;
            }
        }
        .category-btn {
            letter-spacing: 0.07em;
            margin-bottom: 25px;
            margin-top: 25px;
            transition: all 0.5s ease;
        }
    }
}
.category-m {
    .slick-slide {
        > div {
            margin: 0 20px;
        }
    }
    .category-wrapper {
        border: 1px solid $border;
        margin: 0 auto;
        text-align: center;
        justify-content: center;
        padding-top: 40px;
        padding-bottom: 40px;
        position: relative;
        > div {
            &:after,
            &:before {
                content: '';
                width: 1px;
                height: 0;
                position: absolute;
                transition: all 0.2s linear;
                background: $theme-deafult;
                transition-delay: 0s;
            }
            &:before {
                left: 0;
                top: 0;
            }
            &:after {
                right: 0;
                bottom: 0;
            }
        }
        &:after,
        &:before {
            content: '';
            width: 0;
            height: 1px;
            position: absolute;
            transition: all 0.2s linear;
            background: $theme-deafult;
        }
        &:before {
            right: 0;
            top: 0;
        }
        &:after {
            left: 0;
            bottom: 0;
        }
        &:hover {
            transition: all 0.5s ease;
            &:after,
            &:before {
                transition-delay: 0s;
                width: 100%;
            }
            > div {
                &:after,
                &:before {
                    transition-delay: 0.2s;
                    height: 100%;
                }
            }
        }
        img {
            display: inline;
        }
        h4 {
            text-transform: uppercase;
            color: $grey-dark;
            font-weight: 700;
            margin-bottom: 0;
            padding-bottom: 25px;
            padding-top: 25px;
        }
        .btn {
            margin-top: 20px;
        }
        .category-link {
            li {
                display: block;
                text-transform: capitalize;
                margin-top: 5px;
                &:first-child {
                    margin-top: 0;
                }
                a {
                    color: $grey-link;
                    &:hover {
                        color: $theme-deafult;
                    }
                }
            }
        }
    }
}
.background {
    background-color: $border-grey;
    padding: 20px 10px;
    margin: 0;
    .contain-bg {
        width: 100%;
        background-color: $white;
        padding-top: 45px;
        padding-bottom: 45px;
        text-align: center;
        transition: all 0.5s ease;
        h4 {
            color: $dark-font;
            text-transform: uppercase;
            font-weight: 700;
            margin-bottom: 0;
            transition: all 0.5s ease;
        }
        &:hover {
            background-color: lighten($theme-deafult, 35%);
            h4 {
                color: $theme-deafult;
                transition: all 0.5s ease;
            }
        }
    }
}

/*=====================
    10.Product Box CSS start
==========================*/
.absolute-product {
    .theme-tab {
        .tab-title {
            .current {
                a {
                    font-weight: 700;
                }
            }
        }
    }
    .product-box {
        width: 100%;
        display: inline-block;
        padding-bottom: 10px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .img-wrapper {
            border-radius: 5px 5px 0 0;
        }
        .product-detail {
            text-align: center;
            margin-top: 0;
            padding: 0 15px;
            .color-variant {
                padding-top: 5px;
                li {
                    height: 16px;
                    width: 16px;
                }
            }
            .cart-bottom {
                border-top: 1px solid #ddd;
                padding-top: 10px;
                margin-top: 10px;
                button {
                    border: none;
                    background-color: transparent;
                }
                i {
                    color: #828282;
                    font-size: 18px;
                    padding-right: 7px;
                    padding-left: 7px;
                    transition: all 0.5s ease;
                    &:hover {
                        color: $theme-deafult;
                        transition: all 0.5s ease;
                    }
                }
            }
            .rating {
                margin-top: 10px;
            }
        }
    }
    .slick-slider {
        .product-box {
            margin-bottom: 3px;
        }
    }
    &.digital-product {
        .product-box {
            padding-bottom: 15px;
            .img-wrapper {
                .digital-wrapper {
                    display: flex;
                    width: 100%;
                    .live-demo,
                    .details_product {
                        width: 50%;
                        background-color: rgba($theme-deafult, 0.6);
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 6px 0;
                        position: absolute;
                        bottom: 0;
                        h4 {
                            margin-bottom: 0;
                            line-height: 1.2;
                        }
                    }
                    .live-demo {
                        left: -50%;
                        transition: all 0.5s ease;
                    }
                    .details_product {
                        right: -50%;
                        transition: all 0.5s ease;
                        //border-left: 1px solid white;
                    }
                }
            }
            .product-detail {
                h6 {
                    padding-top: 15px;
                    color: $black;
                    font-weight: 700;
                }
                h4 {
                    color: $black;
                    font-weight: 100;
                }
            }
            &:hover {
                .img-wrapper {
                    .digital-wrapper {
                        .live-demo {
                            left: 0;
                            transition: all 0.5s ease;
                        }
                        .details_product {
                            right: 0;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
}
.product-m {
    .slick-slide {
        > div {
            margin: 0 10px;
        }
    }
}
.color-variant {
    li {
        display: inline-block;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        margin-right: 5px;
        transition: all 0.1s ease;
        vertical-align: middle;
    }
}
.no-slider {
    .product-box {
        width: 100%;
        flex: 0 0 25%;
        max-width: calc(25% - 30px);
        margin: 0 15px 30px;
        &:nth-last-child(-n + 4) {
            margin: 0 15px 0;
        }
    }
}
.product-para {
    p {
        margin-bottom: 0;
        padding-bottom: 25px;
        line-height: 24px;
        letter-spacing: 0.05em;
        margin-top: -7px;
    }
}
.portfolio-section {
    .article-title {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        color: white;
        transition: all 0.5s ease;
        text-align: center;
        float: left;
        font-size: 18px;
        line-height: 24px;
        a {
            color: white;
        }
    }
    .isotopeSelector {
        float: left;
        width: 100%;
        position: relative;
        .overlay {
            position: relative;
            border: 1px solid $round-border;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .isotopeSelector {
        margin-bottom: 30px;
        img {
            transition: all 0.4s ease;
        }
        .overlay-background {
            transform: scale(0);
            transition: all 0.4s ease;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: white;
                padding: 10px;
                border-radius: 100%;
                transform: translate(-50%, -50%);
                color: $theme-deafult;
                font-size: 22px;
                display: none;
                transition: all 0.4s ease;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
            }
        }
        &:hover {
            img {
                transform: scale(1.2) rotate(2deg);
                transition: all 0.4s ease;
            }
            .overlay-background {
                transform: scale(1);
                border: 3px solid white;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba($theme-deafult, 0.2);
                transition: all 0.4s ease;
                i {
                    display: flex;
                    transition: all 0.4s ease;
                }
            }
        }
    }
    .border-portfolio {
        margin: 3px;
        overflow: hidden;
    }
    #form1 {
        padding-bottom: 20px;
    }
    .filter-button {
        padding: 0 24px;
        font-size: 20px;
        line-height: 35px;
        border: none;
        background-color: white;
        &.active {
            background-color: $theme-deafult;
            color: $white;
        }
    }
    &.fullwidth-portfolio {
        .isotopeSelector {
            padding-left: 7px;
            padding-right: 7px;
            margin-bottom: 14px;
        }
    }
    &.metro-section {
        .isotopeSelector {
            &:hover {
                img {
                    //transform: scale(1) rotate(0deg);
                    //transition: all 0.5s ease;
                }
            }
        }
        .product-box {
            .product-detail {
                opacity: 0;
                position: absolute;
                background-color: $white;
                padding: 10px;
                transition: all 0.5s ease;
                width: 65%;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -10px;
                text-align: center;
                h6 {
                    color: #525252;
                    padding-top: 0;
                    margin-top: -2px;
                }
                h4 {
                    font-weight: 400;
                    color: $black;
                }
            }
            .cart-wrap {
                top: 7px;
                right: 7px;
                opacity: 1;
                button {
                    border: 1px solid $white;
                    border-radius: 100%;
                    background-color: rgba($white, 0.8);
                    opacity: 1;
                    i {
                        padding: 7px;
                        color: $font-color;
                    }
                }
                a {
                    i {
                        border-radius: 100%;
                        border: 1px solid $white;
                        margin: 5px 0;
                        background-color: rgba($white, 0.8);
                        padding: 7px;
                        color: $font-color;
                        opacity: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            &:hover {
                .product-detail {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 15px;
                }
                .cart-wrap {
                    button {
                        animation: none;
                    }
                    a {
                        i {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            i {
                                animation: fadeInDown 500ms ease-in-out;
                            }
                        }
                        &:nth-child(3) {
                            i {
                                animation: fadeInDown 700ms ease-in-out;
                            }
                        }
                        &:nth-child(4) {
                            i {
                                animation: fadeInDown 1000ms ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}
.product-box,
.product-wrap {
    position: relative;
    transition: all 0.5s ease;
    &.product-wrap {
        //padding-bottom: 15px;
    }
    .img-block {
        background-color: $grey-lighter;
        position: relative;
        overflow: hidden;
        .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
        }
        .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
        }
        .lable-wrapper {
            margin: 0 auto;
            top: 40px;
            position: absolute;
            left: 0;
            right: 0;
            text-align: center;
            transition: all 0.5s ease;
            z-index: 2;
            .lable1,
            .lable2 {
                font-size: 14px;
                padding: 10px 14px 10px 20px;
                display: inline-block;
                text-transform: uppercase;
                text-align: center;
            }
            .lable1 {
                background-color: $theme-deafult;
                color: $white;
                border-bottom-left-radius: 25px;
                border-top-left-radius: 25px;
            }
            .lable2 {
                background-color: $white;
                color: $black;
                border-bottom-right-radius: 25px;
                border-top-right-radius: 25px;
            }
        }
    }
    .img-wrapper {
        position: relative;
        overflow: hidden;
        .front {
            opacity: 1;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
        }
        .back {
            opacity: 0;
            position: absolute;
            backface-visibility: hidden;
            top: 0;
            left: 0;
            transition: all 0.5s ease;
            transform: translateX(-100px);
        }
        .cart-box {
            position: absolute;
            margin: 0 auto;
            display: inline-block;
            right: 0;
            left: 0;
            border-radius: 50px;
            width: max-content;
            padding: 12px 15px;
            box-shadow: 0 0 12px 0 $round-border;
            bottom: 30px;
            background-color: $white;
            opacity: 0;
            transition: all 0.2s ease;
            button {
                background: none;
                box-shadow: none;
                border: none;
                padding: 0;
            }
            i {
                color: #bbbbbb;
                font-size: 18px;
                padding-left: 8px;
                padding-right: 8px;
                transition: all 0.2s ease;
                display: inline-block;
                &:hover {
                    color: $theme-deafult;
                    transition: all 0.2s ease;
                }
            }
        }
        .lable-block {
            .lable3 {
                border-radius: 100%;
                background-color: $theme-deafult;
                text-align: center;
                font-size: 14px;
                font-weight: 700;
                position: absolute;
                padding: 12px 6px;
                text-transform: uppercase;
                color: $white;
                top: 7px;
                left: 7px;
            }
            .lable4 {
                position: absolute;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                writing-mode: vertical-rl;
                transform: rotate(-180deg);
                top: 7px;
                right: 7px;
                letter-spacing: 0.1em;
            }
        }
        .product-thumb-list {
            position: absolute;
            bottom: 0;
            left: 0;
            li {
                display: block;
                opacity: 0.3;
                &.active {
                    opacity: 1;
                }
                img {
                    height: 50px;
                }
            }
        }
    }
    .cart-info,
    .cart-wrap {
        position: absolute;
        bottom: 40px;
        text-align: center;
        margin: 0 auto;
        display: inline-block;
        right: 0;
        left: 0;
        justify-content: center;
        opacity: 0;
        transition: all 0.5s ease;
        a {
            [class^='icon-'],
            [class*=' icon-'] {
                display: inline-block;
            }
        }
        &.cart-wrap {
            bottom: 0;
            text-align: right;
            left: unset;
            i {
                display: block;
                padding-bottom: 10px;
                padding-top: 10px;
            }
            &.cart-effect-left {
                left: 0;
                right: unset;
            }
        }
        button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
        }
        i {
            color: #bbbbbb;
            font-size: 18px;
            padding-right: 10px;
            padding-left: 10px;
            &:hover {
                color: $theme-deafult;
            }
        }
    }
    .cart-detail {
        position: absolute;
        top: 15px;
        right: 20px;
        opacity: 0;
        i {
            color: $icon;
            font-size: 18px;
            display: flex;
            padding-top: 8px;
            padding-bottom: 8px;
        }
        button {
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
        }
    }
    .product-detail,
    .product-info {
        padding-left: 5px;
        .rating {
            margin-top: 15px;
            i {
                padding-right: 5px;
                &:nth-child(-n + 4) {
                    color: $star-yellow;
                }
                &:last-child {
                    color: $round-border;
                }
            }
        }
        h6 {
            line-height: 1;
            margin-bottom: 0;
            padding-top: 2px;
            padding-bottom: 5px;
            transition: all 0.5s ease;
            font-size: 16px;
        }
        h4 {
            font-size: 18px;
            color: $dark-font;
            font-weight: 700;
            margin-bottom: 0;
            transition: all 0.5s ease;
        }
        .color-variant {
            padding-top: 15px;
            li {
                display: inline-block;
                height: 20px;
                width: 20px;
                border-radius: 100%;
                margin-right: 5px;
                transition: all 0.1s ease;
                cursor: pointer;
            }
        }
    }
    .product-info {
        padding: 0;
        text-align: center;
        position: relative;
        .add-btn {
            position: absolute;
            bottom: 110px;
            margin: 0 auto;
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 0.2s ease;
            z-index: 1;
            i {
                color: $theme-deafult;
            }
            .btn-outline {
                transition: all 0.2s ease;
                color: $theme-deafult;
                &:hover {
                    color: $white;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    &.effect-center {
        .front {
            img {
                transition: all 0.5s ease;
            }
        }
        .img-wrapper {
            .cart-box {
                bottom: 20%;
                transition: all 0.5s ease;
            }
        }
    }
    &:hover {
        &.effect-center {
            .front {
                img {
                    opacity: 0.3;
                    transition: all 0.5s ease;
                }
            }
            .img-wrapper {
                .cart-box {
                    bottom: 35%;
                    transition: all 0.5s ease;
                }
            }
        }
        .img-block,
        .img-wrapper {
            .first {
                opacity: 0;
                transition: all 0.5s ease;
            }
            .back {
                opacity: 1;
                transition: all 0.5s ease;
                transform: translateX(0);
            }
        }
        .cart-info {
            opacity: 1;
            transition: all 0.5s ease;
            button {
                animation: fadeInUp 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInUp 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInUp 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInUp 1000ms ease-in-out;
                }
            }
        }
        .cart-wrap {
            button {
                animation: fadeInRight 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInRight 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInRight 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInRight 1000ms ease-in-out;
                }
            }
            &.cart-effect-left {
                button {
                    animation: fadeInLeft 300ms ease-in-out;
                }
                a {
                    &:nth-child(2) i {
                        animation: fadeInLeft 500ms ease-in-out;
                    }
                    &:nth-child(3) i {
                        animation: fadeInLeft 700ms ease-in-out;
                    }
                    &:nth-child(4) i {
                        animation: fadeInLeft 1000ms ease-in-out;
                    }
                }
            }
        }
        .cart-detail {
            opacity: 1;
            transition: all 0.5s ease;
            button {
                animation: fadeInRight 300ms ease-in-out;
            }
            a {
                &:nth-child(2) i {
                    animation: fadeInRight 500ms ease-in-out;
                }
                &:nth-child(3) i {
                    animation: fadeInRight 700ms ease-in-out;
                }
                &:nth-child(4) i {
                    animation: fadeInRight 1000ms ease-in-out;
                }
            }
        }
        .product-info {
            .add-btn {
                opacity: 1;
                transition: all 0.2s ease;
                animation: fadeInUp 500ms ease-in-out;
            }
        }
        .img-wrapper {
            .cart-box {
                opacity: 1;
                transition: all 0.2s ease;
                animation: fadeInUp 400ms ease-in-out;
            }
        }
    }
}
.addtocart_count {
    position: relative;
    .product-box {
        .product-detail {
            text-align: center;
        }
        .cart-info {
            bottom: 40px;
            right: 10px;
            a {
                i {
                    background-color: #e2e2e2;
                    border-radius: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                    color: #313131;
                }
            }
        }
        .add-button {
            background-color: #efefef;
            color: black;
            text-align: center;
            font-size: 18px;
            text-transform: capitalize;
            width: 100%;
            padding: 5px 0;
            transition: all 0.5s ease;
            border: none;
            cursor: pointer;
        }
        &:hover {
            .cart-info {
                a {
                    &:nth-child(1) {
                        i {
                            animation: fadeInRight 300ms ease-in-out;
                        }
                    }
                }
            }
            .add-button {
                bottom: 0;
                transition: all 0.5s ease;
            }
        }
    }
    .addtocart_btn {
        position: relative;
        .cart_qty {
            width: 100%;
            &.qty-box {
                position: absolute;
                bottom: 0;
                display: none;
                .input-group {
                    .form-control {
                        width: 100%;
                        &:focus {
                            border-color: #efefef;
                            box-shadow: none;
                        }
                    }
                    button {
                        background: $grey3 !important;
                        position: absolute;
                        height: 100%;
                        z-index: 9;
                        &.quantity-left-minus {
                            left: 0;
                        }
                        &.quantity-right-plus {
                            right: 0;
                        }
                    }
                    button {
                        i {
                            color: $black;
                        }
                    }
                }
            }
            &.open {
                display: block;
            }
        }
    }
}
.game-product {
    .theme-tab {
        .tab-title,
        .tab-title2 {
            .current {
                a {
                    border-bottom: 2px solid $theme-deafult;
                    padding-bottom: 5px;
                }
            }
        }
    }
    .product-box {
        .product-detail {
            text-align: center;
        }
        .cart-info {
            bottom: 40px;
            right: 10px;
            a {
                i {
                    background-color: white;
                    border-radius: 100%;
                    margin: 10px 0;
                    padding: 8px;
                    font-size: 16px;
                }
            }
        }
        .add-button {
            background-color: $theme-deafult;
            color: white;
            text-align: center;
            font-size: 18px;
            text-transform: capitalize;
            position: absolute;
            width: 100%;
            bottom: -40px;
            padding: 5px 0;
            transition: all 0.5s ease;
            cursor: pointer;
        }
        &:hover {
            .cart-info {
                a {
                    &:nth-child(1) {
                        i {
                            animation: fadeInRight 300ms ease-in-out;
                        }
                    }
                }
            }
            .add-button {
                bottom: 0;
                transition: all 0.5s ease;
            }
        }
    }
    .img-wrapper {
        img {
            width: 100%;
        }
    }
}

/*=====================
    11.Theme tab CSS start
==========================*/
.react-tabs__tab--selected {
    background: unset !important;
    border-color: transparent !important;
    color: $theme-deafult !important;
    border-radius: 0 !important;
    .nav-link {
        color: $theme-deafult !important;
    }
}
.tab-content {
    &.nav-material {
        .react-tabs__tab--selected {
            border-bottom: 2px solid $theme-deafult !important;
        }
    }
}
.react-tabs__tab {
    &:focus {
        border-color: transparent !important;
        box-shadow: none !important;
    }
}
.theme-tab {
    position: relative;
    .tab-content {
        display: block;
        &[style='display: block;'] {
            .product-box,
            .tab-box {
                animation: zoomIn 300ms ease-in-out;
            }
        }
        .product-tab {
            .tab-box {
                background-color: $white;
                width: 100%;
                flex: 0 0 25%;
                max-width: calc(25% - 10px);
                margin: 0 5px 10px;
                &:nth-last-child(-n + 4) {
                    margin: 0 5px 0;
                }
                .product-box2 {
                    img {
                        height: 250px;
                        padding: 15px;
                        transition: all 0.5s ease;
                    }
                    .media {
                        .media-body {
                            .rating {
                                margin-top: 0;
                                i {
                                    padding-right: 0;
                                    &:nth-child(-n + 4) {
                                        color: $star-yellow;
                                    }
                                    &:last-child {
                                        color: $round-border;
                                    }
                                }
                            }
                            a {
                                h6 {
                                    margin-right: 35px;
                                    line-height: 17px;
                                    margin-top: 5px;
                                    margin-bottom: 0;
                                }
                            }
                            h4 {
                                font-size: 20px;
                                margin-top: 5px;
                                font-weight: 700;
                                color: $dark-font;
                                margin-bottom: 0;
                            }
                            .color-variant {
                                li {
                                    display: inline-block;
                                    height: 20px;
                                    width: 20px;
                                    border-radius: 100%;
                                    margin-right: 2px;
                                    margin-top: 20px;
                                    transition: all 0.1s ease;
                                    margin-bottom: 0;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
                &:hover {
                    .product-box2 {
                        img {
                            transform: scale(1.03);
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
    .tab-title,
    .tab-title2 {
        text-align: center;
        font-size: 18px;
        margin-bottom: 25px;
        margin-top: -9px;
        .current {
            a {
                color: $theme-deafult;
            }
        }
        li {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 0;
            line-height: 1;
        }
        a {
            color: $grey-dark;
            text-transform: uppercase;
        }
    }
    .tab-title2 {
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: -8px;
        &:after {
            content: '';
            position: absolute;
            border: 1px solid $border-grey;
            width: 100%;
            left: 0;
            top: 17px;
            z-index: -1;
        }
        .current {
            background-color: $white;
            a {
                background-color: $white;
            }
        }
    }
}
.tab-bg {
    background-color: lighten($theme-deafult, 37%);
    padding: 70px 50px 60px 50px;
    &.tab-grey-bg {
        background-color: $grey-lighter;
    }
    .theme-tab {
        .tab-title {
            margin-bottom: 30px;
        }
    }
}

/*=====================
    12.Banner CSS start
==========================*/
.full-banner {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 190px;
    padding-bottom: 190px;
    background-position: center;
    @for $i from 1 through 28 {
        &.parallax-banner#{$i} {
            background-image: url('#{$assetspath}/parallax/#{$i}.jpg');
        }
    }
    &.parallax-layout {
        padding-top: 350px;
        padding-bottom: 350px;
        .banner-contain {
            h4 {
                color: $grey-shade;
            }
        }
    }
    &.banner-layout-3 {
        .banner-contain {
            h4 {
                color: $white;
            }
            h3 {
                color: $white;
                margin-top: 10px;
            }
            .color {
                color: $theme-deafult;
                line-height: 1;
            }
        }
    }
    &.parallax {
        background-attachment: fixed;
    }
    &.p-left {
        .banner-contain {
            float: left;
        }
    }
    &.p-right {
        .banner-contain {
            float: right;
        }
    }
    &.p-center {
        .banner-contain {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
    .banner-contain {
        .btn-solid,
        .btn-outline {
            margin-top: 20px;
        }
        h2 {
            font-size: 100px;
            font-weight: 700;
            color: $theme-deafult;
            text-transform: uppercase;
            margin-top: -15px;
        }
        h3 {
            font-size: 60px;
            color: $font-color;
            text-transform: uppercase;
            font-weight: 700;
        }
        h4 {
            font-size: 24px;
            color: $grey;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5em;
            padding-top: 10px;
            margin-bottom: -5px;
            line-height: 1.3;
        }
    }
}
.parallax-cls {
    section {
        &:nth-child(odd) {
            .full-banner {
                background-position: right;
            }
        }

        &:nth-child(even) {
            .full-banner {
                background-position: left;
            }
        }
        .banner-contain {
            margin-top: -12px;
        }
    }
}

/*=====================
    13.Collection Banner CSS start
==========================*/
.collection-banner {
    position: relative;
    overflow: hidden;
    img {
        transition: all 0.5s ease;
        width: 100%;
    }
    &.p-left {
        .contain-banner {
            justify-content: flex-start;
        }
    }
    &.p-right {
        .contain-banner {
            justify-content: flex-end;
        }
    }
    &.p-center {
        .contain-banner {
            justify-content: center;
        }
    }
    .contain-banner {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 70px;
        padding-right: 70px;
        &.banner-3 {
            padding-left: 50px;
            padding-right: 50px;
            h2 {
                font-size: 36px;
                letter-spacing: 0.05em;
                color: white;
                margin-top: 5px;
                margin-bottom: -6px;
            }
            h4 {
                color: $white;
            }
        }
        &.banner-4 {
            padding-left: 50px;
            padding-right: 50px;
            h2 {
                font-size: 28px;
                letter-spacing: 0.03em;
                color: white;
                margin-bottom: -6px;
            }
        }
        h4 {
            color: $theme-deafult;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            margin-bottom: 0;
        }
        h2 {
            font-size: 60px;
            font-weight: 700;
            color: $dark-font;
            letter-spacing: 0.1em;
            margin-bottom: -6px;
        }
    }
    &:hover {
        img {
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }
}
.banner-furniture {
    padding-left: 15px;
    padding-right: 15px;
    .collection-banner {
        .contain-banner {
            &.banner-3 {
                h2 {
                    color: $dark-font;
                    margin-bottom: 0;
                }
                h4 {
                    color: $theme-deafult;
                }
            }
        }
    }
}
.banner-goggles {
    .collection-banner {
        .contain-banner {
            &.banner-3 {
                h2 {
                    color: $dark-font;
                }
                h4 {
                    color: $theme-deafult;
                }
            }
        }
    }
}
.banner-top-cls {
    margin-top: 30px;
}
.banner-6 {
    .collection-banner {
        .contain-banner {
            &.banner-3 {
                h2 {
                    color: #222222;
                }
            }
        }
    }
}
.absolute_banner {
    margin-bottom: 22px;
    .collection-banner {
        overflow: unset;
        .absolute-contain {
            position: absolute;
            background-color: #ffffff;
            bottom: -22px;
            left: 50%;
            transform: translateX(-50%);
            padding: 20px;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.35);
            min-width: 85%;
            text-align: center;
            transition: all 0.5s ease;
            h3 {
                color: $theme-deafult;
                text-transform: capitalize;
                margin-top: -5px;
                font-weight: 700;
            }
            h4 {
                color: $black;
                margin-bottom: 0;
            }
        }
        &:hover {
            img {
                transform: none;
            }
        }
    }
}

/*=====================
    14.Product-box slider CSS start
==========================*/
.layout7-product {
    .product-box {
        padding: 2px;
        img {
            width: 100%;
        }
        .cart-info {
            right: 7px;
            bottom: 7px;
        }
        .details-product {
            position: absolute;
            transition: all 0.5s ease;
            opacity: 0;
            bottom: 15px;
            left: 15px;
            h6,
            h4 {
                margin-bottom: 0;
            }
            h6 {
                color: $black;
            }
        }
        &:hover {
            .details-product {
                opacity: 1;
                animation: fadeIn 500ms ease-in-out;
                transition: all 0.5s ease;
            }
        }
    }
}
.theme-card {
    position: relative;
    &.center-align {
        display: flex;
        height: 100%;
        align-items: center;
        .offer-slider {
            .product-box2 {
                .media {
                    .media-body {
                        margin-top: 0;
                    }
                }
            }
        }
    }
    &.card-border {
        border: 1px solid $round-border;
        h5 {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 50px;
        }
        .slick-prev,
        .slick-next {
            top: -45px;
        }
        .slick-next {
            right: 30px;
        }
        .slick-prev {
            right: 50px;
        }
        .offer-slider {
            padding-top: 0;
            padding-bottom: 20px;
            img {
                padding: 15px 15px 15px 30px;
            }
            .media {
                .media-body {
                    a {
                        h6 {
                            margin-right: 25px;
                        }
                    }
                }
            }
        }
    }
    h5 {
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 10px;
        margin-top: -5px;
        &.title-border {
            border-bottom: 1px solid $round-border;
        }
    }
    .slick-prev,
    .slick-next {
        top: -35px;
        &:before {
            font: normal normal normal 30px/1 $font-awesome;
            color: $dark-font;
            opacity: 0.6;
        }
    }
    .slick-next {
        right: 0;
        &:before {
            content: '\f105';
        }
    }
    .slick-prev {
        left: unset;
        right: 25px;
        &:before {
            content: '\f104';
        }
    }
    .offer-slider {
        img {
            height: 160px;
            padding: 15px 15px 15px 0;
        }
        > div {
            .media {
                &:last-child {
                    .media-body {
                        margin-top: 15px;
                    }
                    img {
                        margin-bottom: -15px;
                    }
                }
            }
        }
        .media {
            .media-body {
                .rating {
                    margin-top: 0;
                    i {
                        padding-right: 5px;
                        &:nth-child(-n + 4) {
                            color: $star-yellow;
                        }
                        &:last-child {
                            color: $round-border;
                        }
                    }
                }
                a {
                    h6 {
                        margin-right: 61px;
                        line-height: 17px;
                        margin-top: 5px;
                        margin-bottom: 0;
                    }
                }
                h4 {
                    margin-top: 10px;
                    font-weight: 700;
                    color: $dark-font;
                    margin-bottom: 0;
                }
            }
        }
    }
}
.offer-slider {
    .slick-slide {
        line-height: 1;
    }
}
.center-slider {
    .product-box {
        box-shadow: none;
        .img-wrapper {
            img {
                padding: 15px;
            }
        }
    }
    .slick-next,
    .slick-prev {
        &:before {
            box-shadow: 0 0 8px 0 $round-border;
        }
    }
    .title2 {
        .title-inner2 {
            margin-bottom: 0;
        }
    }
    &:hover {
        .product-box {
            box-shadow: none;
        }
        .slick-next {
            right: 20px;
            transform: scale(1.8);
        }
        .slick-prev {
            left: 20px;
            transform: scale(1.8);
        }
    }
}
.full-box {
    .theme-card {
        .offer-slider {
            .product-box2 {
                .media {
                    img {
                        height: 300px;
                        transform: scale(1);
                        transition: all 0.5s ease;
                    }
                    .media-body {
                        a {
                            h6 {
                                margin-right: 20px;
                            }
                        }
                        h4 {
                            font-size: 20px;
                            margin-top: 5px;
                        }
                        .color-variant {
                            li {
                                display: inline-block;
                                height: 20px;
                                width: 20px;
                                border-radius: 100%;
                                margin-right: 5px;
                                margin-top: 15px;
                                transition: all 0.1s ease;
                                margin-bottom: 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
                + .product-box2 {
                    border-top: 1px solid $border-grey;
                }
                &:hover {
                    .media {
                        img {
                            transform: scale(1.03);
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }
    .product-box {
        //border-left: 1px solid $border-grey;
        //border-right: 1px solid $border-grey;
    }
}

/*=====================
    15.Modal popup CSS start
==========================*/
.ribbon-1 {
    width: 24px;
    height: 45px;
    background: $theme-deafult;
    top: -6px;
    left: 25px;
    position: absolute;
    box-shadow: 4px 5px 15px 2px rgba(90, 90, 90, 0.35);
    &:before {
        height: 0;
        width: 0;
        border-bottom: 6px solid darken($theme-deafult, 20%);
        border-right: 6px solid transparent;
        right: -6px;
        content: '';
        position: absolute;
    }
    &:after {
        height: 0;
        width: 0;
        border-left: 12px solid $theme-deafult;
        border-right: 12px solid $theme-deafult;
        border-bottom: 12px solid transparent;
        bottom: -11px;
        left: 0;
        content: '';
        position: absolute;
    }
    span {
        display: block;
        color: #fff;
        height: 12px;
        text-transform: lowercase;
    }
}
.modal-open {
    padding-right: 0 !important;
}
#sizemodal {
    padding-right: 0 !important;
}
.modal-dialog {
    margin: 0;
    .modal-content {
        border: none;
        .modal-body {
            padding: 0;
        }
    }
}
.theme-modal {
    padding-right: 0 !important;
    .modal-dialog {
        .modal-content {
            .modal-body {
                padding: 15px;
                background-image: linear-gradient(
                    135deg,
                    $theme-deafult 5.77%,
                    $white 5.77%,
                    $white 25%,
                    $grey01 25%,
                    $grey01 30.77%,
                    $white 30.77%,
                    $white 50%,
                    $theme-deafult 50%,
                    $theme-deafult 55.77%,
                    $white 55.77%,
                    $white 75%,
                    $grey01 75%,
                    $grey01 80.77%,
                    $white 80.77%,
                    $white 100%
                );
                background-size: 36.77px 36.77px;
                @for $i from 1 through 15 {
                    &.modal#{$i} {
                        // background-image:url("#{$assetspath}/modal-bg/#{$i}.jpg");
                    }
                }
                .modal-bg {
                    background-color: $white;
                    padding: 45px;
                    .close {
                        padding-right: 10px;
                        padding-top: 5px;
                        position: absolute;
                        right: 17px;
                        top: 0;
                        span {
                            color: $black;
                        }
                    }
                    .age-content {
                        h2 {
                            border-bottom: 1px dashed $grey;
                            text-align: center;
                            margin-bottom: 30px;
                            padding-bottom: 30px;
                            font-weight: 700;
                        }
                        h4 {
                            margin-bottom: 30px;
                            text-align: center;
                            color: #272727;
                            font-size: 18px;
                            font-weight: 400;
                            text-transform: capitalize;
                        }
                        form {
                            margin: 0 auto;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            .form-control {
                                padding-top: 15px;
                                padding-bottom: 15px;
                                text-align: center;
                                font-size: 12px;
                                height: 50px;
                                border-radius: 0;
                                width: 170px;
                                letter-spacing: 0.05em;
                            }
                        }
                    }
                    .offer-content {
                        img {
                            margin-bottom: 40px;
                        }
                        h2 {
                            margin-bottom: 30px;
                            text-align: center;
                        }
                        form {
                            .form-group {
                                margin: 0 auto;
                                text-align: center;
                                .form-control {
                                    padding-top: 15px;
                                    padding-bottom: 15px;
                                    text-align: center;
                                    font-size: 12px;
                                    max-width: 640px;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                    letter-spacing: 0.05em;
                                }
                                .btn-solid,
                                .btn-outline {
                                    margin-top: 10px;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
        .quick-view-modal {
            button {
                &.close {
                    position: absolute;
                    z-index: 1;
                    right: 10px;
                    top: 5px;
                }
            }
            .view-detail {
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}
.modal-dialog {
    .modal-content {
        .modal-body {
            .modal-bg {
                padding: 25px;
                &.addtocart {
                    .media {
                        a {
                            img {
                                max-width: 150px;
                            }
                        }
                        .media-body {
                            padding: 0 15px;
                            a {
                                h6 {
                                    font-size: 16px;
                                    i {
                                        margin-right: 8px;
                                        color: #fff;
                                        background: #19a340;
                                        font-size: 14px;
                                        width: 18px;
                                        height: 18px;
                                        line-height: 18px;
                                        border-radius: 50%;
                                    }
                                }
                            }
                            .buttons {
                                a {
                                    display: inline-block;
                                    font-size: 16px;
                                    padding: 6px 10px;
                                    margin: 0 6px;
                                }
                            }
                        }
                    }
                    #upsell_product {
                        .product-box {
                            padding-left: 10px;
                            padding-right: 10px;
                            .product-detail {
                                text-align: center;
                                h6 {
                                    font-weight: 700;
                                    text-transform: capitalize;
                                    a {
                                        color: $theme-deafult;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .upsell_payment {
                padding-top: 15px;
            }
            .product-upsell {
                margin: 20px 0 10px;
                h4 {
                    font-weight: 600;
                }
            }
        }
    }
}
/* =====================
    16.look-book demo CSS start
========================== */
.lookbook-section {
    padding: 25px;
    .lookbook-img {
        > div {
            &:last-child {
                img {
                    margin-top: 25px;
                }
            }
        }
    }
}
/* =====================
    17.1.lookbook CSS start
========================== */
.lookbook {
    .lookbook-block {
        position: relative;
        .lookbook-dot {
            cursor: pointer;
            position: absolute;
            z-index: 2;
            width: 29px;
            height: 29px;
            line-height: 29px;
            border-radius: 50%;
            -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            top: 50%;
            left: 12%;
            background-color: $white;
            &.dot2 {
                top: 70%;
                left: 27%;
            }
            &.dot3 {
                top: 28%;
                left: 30%;
            }
            &.dot4 {
                top: 67%;
                left: 8%;
            }
            &.dot5 {
                top: 40%;
                left: 35%;
            }
            &.dot6 {
                top: 80%;
                left: 58%;
            }
            &.dot7 {
                top: 67%;
                left: 45%;
            }
            &.dot8 {
                top: 43%;
                left: 39%;
            }
            &.dot9 {
                top: 32%;
                left: 50%;
            }
            &.dot10 {
                top: 60%;
                left: 50%;
            }
            &.dot11 {
                top: 50%;
                left: 45%;
            }
            &.dot12 {
                top: 75%;
                left: 45%;
            }
            &.dot13 {
                top: 40%;
                left: 45%;
            }
            &.dot14 {
                top: 76%;
                left: 45%;
            }
            &.dot15 {
                top: 50%;
                left: 15%;
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                content: '';
                background-color: #fff;
                background-color: rgba(255, 255, 255, 0.5);
                zoom: 1;
                background-color: transparent\9;
                z-index: -1;
                border-radius: 50%;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            span {
                text-align: center;
                justify-content: center;
                display: flex;
            }
            .dot-showbox {
                visibility: hidden;
                top: -98px;
                left: 150%;
                position: absolute;
                width: 130px;
                background-color: $white;
                -webkit-box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
                box-shadow: -3px -3px 13px rgba(48, 54, 61, 0.1);
                img {
                    margin-top: 0;
                }
                .dot-info {
                    padding: 5px;
                    text-align: center;
                    h5 {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        line-height: 20px;
                        &.title {
                            font-weight: 700;
                        }
                    }
                    h6 {
                        text-transform: uppercase;
                        color: $theme-deafult;
                        margin-bottom: 0;
                    }
                }
                &:before {
                    position: absolute;
                    content: '';
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-right: 8px solid #ffffff;
                    border-bottom: 8px solid transparent;
                    top: 41%;
                    left: -8px;
                }
            }
            &:hover {
                background-color: $theme-deafult;
                -webkit-box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
                box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
                z-index: 10;
                .dot-showbox {
                    animation: fadeInUp 400ms ease-in-out;
                    visibility: visible;
                    transition: all 0.5s ease;
                }
                span {
                    color: $white;
                }
                &:before {
                    -webkit-transform: scale(1.3);
                }
            }
        }
    }
}
/* =====================
    17.2. Beauty Demo css
========================== */
.beauty-about {
    .about-text {
        p {
            text-align: left;
        }
    }
}
.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    .small-section {
        padding-top: 20px;
    }
    .service {
        .service-block1 {
            img {
                margin-bottom: 10px;
            }
            h5 {
                text-transform: capitalize;
                font-weight: 700;
                margin-bottom: 0;
            }
        }
    }
}
.video-modal {
    .modal-body {
        padding: 0;
    }
    iframe {
        height: 500px;
        width: 100%;
        vertical-align: middle;
        border: none;
    }
}
.video-section {
    .video-img {
        position: relative;
        overflow: hidden;
        img {
            transition: all 0.5s ease;
            transform: scale(1);
        }
        .play-btn {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                height: 60px;
                width: 60px;
                border-radius: 100%;
                background-color: $theme-deafult;
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;
            }
            i {
                font-size: 35px;
                padding-left: 5px;
                color: $white;
            }
        }
        &:hover {
            img {
                transform: scale(1.03);
                transition: all 0.5s ease;
            }
        }
    }
}
/* =====================
    17.3. Electronics Demo CSS
========================== */
.layout-8 {
    padding: 0 80px;
    .layout-8-bg {
        background-image: url('#{$assetspath}/electronics/bg.jpg');
        background-size: cover;
        background-attachment: fixed;
        height: 100%;
        width: 100%;
        padding: 0 15px;
        animation: 10s linear 0s normal none infinite animate;
        .layout7-product {
            .product-box {
                .details-product {
                    opacity: 1;
                }
                &:hover {
                    .details-product {
                        animation: none;
                    }
                }
            }
        }
    }
}
.white-bg {
    background-color: $white;
}
@keyframes animate {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 500px;
    }
}
/* =====================
      17.4.pets demo CSS start
========================== */
.pets-box {
    .product-box {
        .img-wrapper {
            .cart-info {
                background-color: rgba($theme-deafult, 0.5);
                padding: 10px;
                border: none;
                transform: perspective(500px) rotateX(90deg);
                bottom: 0;
                i {
                    padding: 5px;
                    margin: 0 3px;
                    background-color: white;
                }
                a {
                    border-left: none;
                }
            }
        }
        .product-detail {
            text-align: center;
            background-color: #fafafa;
            .rating {
                margin-top: 0;
                padding-top: 15px;
            }
        }
        &:hover {
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.08);
            .cart-info {
                transform: perspective(500px) rotateX(0deg);
            }
        }
    }
    .br {
        &.br-default {
            margin: 0 0 15px 0;
            justify-content: center;
            display: flex;
        }
    }
    .product-m {
        .slick-slide {
            .img-wrapper {
                margin: 0;
            }
        }
    }
}
.small-slider {
    .home-slider {
        .home {
            height: 37vh;
            .slider-contain {
                height: 55vh;
                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}
.banner-6 {
    .collection-banner {
        .contain-banner {
            &.banner-3 {
                h2 {
                    color: #222222;
                }
            }
        }
    }
}
.banner-top-cls {
    margin-top: 30px;
}
.j-box {
    .product-box {
        width: 100%;
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 12px;
        overflow: hidden;
        margin: 0 10px;
        .cart-info {
            display: flex;
            left: 0;
            background-color: rgba(250, 250, 250, 0.84);
            border-top: 1px solid $round-border;
            border-bottom: 1px solid $round-border;
            bottom: -30px;
            transition: all 0.5s ease;
            a {
                border-left: 1px solid $round-border;
                i {
                    padding: 12px;
                }
            }
        }
        .product-detail {
            padding-left: 15px;
            padding-bottom: 15px;
            background-color: #fafafa;
            .rating {
                margin-top: 0;
                padding-top: 15px;
            }
        }
        &:hover {
            .img-wrapper {
                .cart-info {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 0;
                    button {
                        animation: none;
                    }
                    a {
                        &:nth-child(2) i {
                            animation: none;
                        }
                        &:nth-child(3) i {
                            animation: none;
                        }
                        &:nth-child(4) i {
                            animation: none;
                        }
                    }
                }
            }
        }
    }
}
.pet-parallax {
    position: relative;
    .pet-decor {
        position: absolute;
        left: 110px;
        bottom: -164px;
    }
    .banner-contain {
        h4,
        h3,
        p {
            color: #212121;
        }
        p {
            max-width: 75%;
            margin: 0 auto;
            line-height: 22px;
            font-size: 16px;
            letter-spacing: 0.04em;
        }
        h4 {
            letter-spacing: 0.05em;
            padding-top: 0 !important;
            margin-bottom: 0;
            line-height: 1.3;
            margin-top: -7px;
        }
        h3 {
            margin: 15px 0;
            font-size: 48px;
        }
    }
    .full-banner {
        padding-top: 130px;
        padding-bottom: 130px;
    }
}
footer {
    &.pet-layout-footer {
        .white-layout {
            background-color: #f5e1d0;
        }
        .footer-brand-logo {
            margin-bottom: 20px;
        }
        .sub-footer {
            &.black-subfooter {
                background-color: $theme-deafult;
            }
            p {
                padding: 3px 0;
                color: $white;
            }
        }
        .social-white {
            display: block;
            margin-top: 40px;
            margin-bottom: 30px;
        }
    }
}
.form_search {
    height: 45px;
    box-shadow: 0 0 0 1px #e2e2e2;
    overflow: hidden;
    position: relative;
    width: 525px;
    background-color: #f5f5f5;
    border-radius: 25px;
    input {
        width: 100%;
        height: 45px;
        font-size: 16px;
        color: darkgrey;
        border: none;
        outline: none;
        background: none;
        padding: 0 10px;
    }
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 40px;
        height: 35px;
        padding: 0;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        border-radius: 5px;
        i {
            font-size: 18px;
            color: $theme-deafult;
        }
    }
}
.blog {
    &.blog_box {
        .blog-details {
            text-align: left;
            padding: 0;
            p {
                font-size: 14px;
                text-transform: none;
                color: $font-color;
                font-weight: 400;
                margin-bottom: 8px;
                margin-top: 3px;
            }
            a {
                p {
                    color: $grey7;
                    text-transform: capitalize;
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-top: 10px;
                }
            }
            .read-cls {
                text-transform: capitalize;
                font-weight: 700;
                color: $black;
            }
        }
    }
}
/* =====================
   17.5. Metro layout  CSS start
========================== */
.header-metro {
    position: absolute;
    width: 100%;
    .top-header {
        background-color: transparent;
        z-index: 9;
        position: relative;
    }
    .metro {
        background-color: white;
        z-index: 9;
        position: relative;
    }
}
.metro-section {
    .isotopeSelector {
        &:hover {
            img {
                // transform: scale(1) rotate(0deg); // transition: all 0.5s ease;
            }
        }
    }
    .product-box {
        .product-detail {
            opacity: 0;
            position: absolute;
            background-color: $white;
            padding: 10px;
            transition: all 0.5s ease;
            width: 65%;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: -10px;
            text-align: center;
            h6 {
                color: #525252;
                padding-top: 0;
                margin-top: -2px;
            }
            h4 {
                font-weight: 400;
                color: $black;
            }
            .rating {
                margin-top: 0;
            }
        }
        .cart-wrap {
            top: 7px;
            right: 7px;
            opacity: 1;
            button {
                border: 1px solid $white;
                border-radius: 100%;
                background-color: rgba($white, 0.8);
                opacity: 1;
                i {
                    padding: 7px;
                    color: $font-color;
                }
            }
            a {
                i {
                    border-radius: 100%;
                    border: 1px solid $white;
                    margin: 5px 0;
                    background-color: rgba($white, 0.8);
                    padding: 7px;
                    color: $font-color;
                    opacity: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        &:hover {
            .product-detail {
                opacity: 1;
                transition: all 0.5s ease;
                bottom: 15px;
            }
            .cart-wrap {
                button {
                    animation: none;
                }
                a {
                    i {
                        opacity: 1;
                    }
                    &:nth-child(2) {
                        i {
                            animation: fadeInDown 500ms ease-in-out;
                        }
                    }
                    &:nth-child(3) {
                        i {
                            animation: fadeInDown 700ms ease-in-out;
                        }
                    }
                    &:nth-child(4) {
                        i {
                            animation: fadeInDown 1000ms ease-in-out;
                        }
                    }
                }
            }
        }
    }
}
/* =====================
   17.6. GYM layout  CSS start
========================== */
.gym-slider {
    .home-slider {
        .home {
            height: 85vh;
            .slider-contain {
                height: 85vh;
                h4,
                h1 {
                    color: $white;
                }
            }
        }
    }
}
.metro-slider {
    .home-slider {
        .home {
            background-position-y: 70%;
            .slider-contain {
                margin-top: 75px;
                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}
.gym-parallax {
    .title1 {
        h2 {
            color: white;
        }
    }
    .product-para {
        p {
            color: white;
        }
    }
    .instagram-box {
        .overlay {
            background: linear-gradient(-180deg, rgba($gradient1, 0.8) 0%, rgba($gradient2, 0.8) 99%);
        }
    }
}
.gym-product {
    text-align: center;
    .product-box {
        background-color: white;
        padding: 15px;
        box-shadow: 0 0 8px 0 #ddd;
        transition: all 0.5s ease;
        .img-wrapper {
            position: unset;
            .cart-info {
                z-index: 1;
                display: flex;
                left: 0;
                height: 100%;
                align-items: center;
                bottom: 0;
                top: 0;
                i {
                    background: linear-gradient(180deg, $gradient1 0%, $gradient2 99%);
                    color: rgba($white, 0.6);
                    border-radius: 100%;
                    margin: 0 5px;
                    transition: all 0.5s ease;
                }
                button,
                a {
                    &:hover {
                        i {
                            color: white;
                        }
                    }
                }
            }
        }
        .br {
            &.br-default {
                display: flex;
                justify-content: center;
            }
        }
        &:hover {
            transform: scale(1.02);
            transition: all 0.5s ease;
            .img-wrapper {
                .cart-info {
                    opacity: 1;
                    transition: all 0.5s ease;
                    button {
                        animation: fadeInUp 300ms ease-in-out;
                    }
                    a {
                        &:nth-child(2) i {
                            animation: fadeInUp 500ms ease-in-out;
                        }
                        &:nth-child(3) i {
                            animation: fadeInUp 700ms ease-in-out;
                        }
                        &:nth-child(4) i {
                            animation: fadeInUp 1000ms ease-in-out;
                        }
                    }
                }
            }
        }
    }
    .partition-cls {
        > div {
            &:nth-child(-n + 4) {
                margin-bottom: 30px;
            }
        }
    }
}
.gym-blog {
    .blog-details {
        text-align: left;
        padding-left: 0;
        p {
            margin-bottom: 3px;
        }
    }
}
.gym-banner {
    .collection-banner {
        .contain-banner {
            h4,
            h2 {
                color: $white;
            }
        }
    }
}
.bg-img-gym {
    background-image: url('#{$assetspath}/gym/bg.jpg');
    .dark-layout {
        background-color: transparent;
    }
    .footer-theme2 {
        p {
            color: $white;
        }
        .contact-details {
            li {
                color: $white;
                a {
                    color: $white;
                }
            }
        }
        .footer-link,
        .footer-link-b {
            a {
                color: #ebebeb;
            }
        }
    }
    .sub-footer {
        &.darker-subfooter {
            background-color: black;
            p {
                color: $white;
            }
        }
    }
}
.tap-top {
    &.gradient-top {
        background: linear-gradient(180deg, $gradient1 0%, $gradient2 99%);
    }
}
.banner-furniture {
    padding-left: 15px;
    padding-right: 15px;
    .collection-banner {
        .contain-banner {
            &.banner-3 {
                h2 {
                    color: $dark-font;
                    margin-bottom: 0;
                }
                h4 {
                    color: $theme-deafult;
                }
            }
        }
    }
}
.gym-slider {
    .home-slider {
        .home {
            height: 85vh;
            .slider-contain {
                height: 85vh;
                h4,
                h1 {
                    color: $white;
                }
            }
        }
    }
}
.main-nav-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header-gym {
    position: absolute;
    width: 100%;
    .main-menu {
        background-color: rgba($black, 0.8);
        padding: 0 15px;
        z-index: 1;
        .menu-left {
            .navbar {
                padding: 20px 45px 20px 0;
                i {
                    color: white;
                }
            }
        }
        .brand-logo {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        .onhover-div {
            .show-div {
                top: 70px;
            }
        }
    }
    .sidebar-back {
        i {
            color: black;
        }
    }
    .sm-horizontal {
        > li {
            > a {
                padding-top: 20px;
                padding-bottom: 20px;
                color: $white;
                &:hover,
                &:active,
                &:focus,
                &.highlighted {
                    padding-top: 20px;
                    padding-bottom: 20px;
                    color: $white;
                }
            }
        }
        li {
            // .lable-nav { // top:0; // background: #373B44;  /* fallback for old browsers */ // background: -webkit-linear-gradient(to right, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */ // background: linear-gradient(to right, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ // &:before { // border-top: 5px solid #4286f4; // } // }
        }
    }
    .onhover-div {
        padding-top: 20px;
        padding-bottom: 20px;
        > div {
            img {
                filter: brightness(54);
            }
        }
    }
    .top-header {
        z-index: 1;
        position: relative;
        background: transparent;
        .header-contact {
            li {
                color: #fff;
                i {
                    color: #fff;
                }
            }
        }
        .header-dropdown {
            li {
                color: $white;
                a {
                    color: $white;
                    i {
                        color: $white;
                        padding-right: 8px;
                    }
                }
            }
        }
        &.blue-layout {
            border-bottom: 1px solid #223b7d;
        }
    }
}
.btn-gradient {
    background: $theme-deafult;
    background: linear-gradient(180deg, #01effc 0%, $gradient2 99%);
    transition: background 300ms ease-in-out;
    background-size: 300% 100%;
    border: none;
    &:hover {
        background: linear-gradient(-180deg, $gradient2 0%, #01effc 99%);
        transition: background 300ms ease-in-out;
        color: white;
    }
}
/* =====================
  17.7  extra layout CSS start
========================== */
.left-header {
    .top-header {
        padding-left: 300px;
        background-color: $white;
        .header-dropdown {
            .mobile-wishlist,
            .mobile-account {
                img {
                    display: block;
                }
                i {
                    display: none;
                }
            }
        }
    }
    .onhover-div {
        padding-top: 15px;
        padding-bottom: 15px;
        > div {
            img {
                width: 20px;
                height: auto;
            }
        }
        .show-div {
            top: 56px;
        }
    }
    .sidenav {
        left: 0;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
        .sidebar-back {
            display: none;
        }
        nav {
            background-color: $white;
        }
        .pixelstrap {
            > li {
                > a {
                    padding: 10px 0 20px 0;
                    &:hover,
                    &:focus {
                        padding: 10px 0 20px 0;
                        background-color: $white;
                    }
                }
                .lable-nav {
                    top: -12px;
                    background-color: $white-dark;
                    &:before {
                        border-top: 5px solid $color-red;
                    }
                    &.grey-lable {
                        background-color: #4d6171;
                        &:before {
                            border-top: 5px solid #4d6171;
                        }
                    }
                }
            }
        }
        .left-sidebar_search {
            width: 100%;
            background-color: $white;
            input {
                height: 55px;
                width: 95%;
                border: none;
                padding-left: 15px;
                background-color: transparent;
            }
            .btn-search {
                position: absolute;
                right: 10px;
                top: 20px;
                border: none;
                background-color: transparent;
            }
        }
        .left-sidebar_center {
            padding: 40px 0 30px 30px;
            background-color: $white;
        }
        .bottom-section {
            padding: 70px 25px;
            .icon_settings {
                li {
                    display: inline-block;
                }
                .shopping-cart {
                    position: relative;
                    .shopping-count {
                        width: 18px;
                        height: 18px;
                        background-color: $color-red;
                        color: white;
                        position: absolute;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        top: -7px;
                        right: 7px;
                    }
                }
            }
            ul {
                li {
                    display: inline-block;
                    padding: 8px 15px 8px 0;
                    a {
                        font-size: 16px;
                        text-transform: capitalize;
                        color: $dark-font;
                        i {
                            font-size: 18px;
                            padding-right: 5px;
                            color: $dark-font;
                        }
                    }
                }
            }
            .leftside_setting {
                .language,
                .currency {
                    display: flex;
                    h5 {
                        margin-bottom: 0;
                        text-transform: capitalize;
                        color: #999;
                        margin-top: 0;
                    }
                    select {
                        margin-left: 10px;
                        border: none;
                        outline: none;
                        text-transform: capitalize;
                        color: $dark-font;
                    }
                }
                h5 {
                    margin-top: 10px;
                }
            }
        }
        .leftside_social {
            position: relative;
            width: 100%;
            padding: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px dashed $round-border;
            .footer-social {
                margin-top: 0;
                i {
                    font-size: 18px;
                }
                li {
                    padding: 10px;
                }
            }
            .call_us {
                h5 {
                    text-align: center;
                    span {
                        display: block;
                    }
                }
            }
        }
    }
    .main-menu {
        justify-content: flex-end;
        .brand-logo {
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px dashed $round-border;
        }
        .menu-right {
            .header-dropdown {
                > li {
                    img {
                        width: 20px;
                        height: auto;
                    }
                }
            }
            .icon-nav {
                .onhover-div {
                    > img {
                        width: 20px;
                        height: auto;
                    }
                }
            }
        }
        .menu-left {
            .navbar {
                display: none;
            }
            .mobile-logo {
                display: none;
            }
        }
    }
    .search-overlay {
        padding-left: 300px;
    }
}
.left-sidebar_space {
    padding-left: 300px;
}
.header-6 {
    .top-header {
        background-color: $theme-deafult;
        .header-contact {
            li {
                color: $white;
                i {
                    color: $white;
                }
            }
        }
        .header-dropdown {
            li {
                color: $white;
                a {
                    color: $white;
                    i {
                        color: white;
                    }
                }
            }
        }
    }
    .mobile-search {
        display: none;
    }
}
.form_search {
    height: 45px;
    box-shadow: 0 0 0 1px #e2e2e2;
    overflow: hidden;
    position: relative;
    width: 525px;
    background-color: #f5f5f5;
    border-radius: 25px;
    input {
        width: 100%;
        height: 45px;
        font-size: 16px;
        color: darkgrey;
        border: none;
        outline: none;
        background: none;
        padding: 0 10px;
    }
    button {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 40px;
        height: 35px;
        padding: 0;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: transparent;
        border-radius: 5px;
        i {
            font-size: 18px;
            color: $theme-deafult;
        }
    }
}
.header-tools {
    position: absolute;
    width: 100%;
    top: 35px;
    z-index: 1;
    background-color: transparent;
    .container {
        padding-left: 30px;
        padding-right: 30px;
    }
    .top-header {
        background-color: transparent;
        .container {
            background-color: $theme-deafult;
        }
        .header-contact {
            padding: 10px 0;
            li {
                color: $white;
            }
        }
        .header-dropdown {
            li {
                color: $white;
                padding: 10px 25px;
                padding-right: 0;
                a {
                    color: $white;
                    i {
                        color: $white;
                    }
                }
                &:hover {
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    .logo-menu-part {
        background-color: #fff;
        border-bottom: 2px solid $theme-deafult;
    }
    .main-menu {
        .menu-left {
            .navbar {
                padding: 25px 45px 25px 0;
            }
        }
        .brand-logo {
            padding-top: 25px;
            padding-bottom: 25px;
        }
    }
    .sm-horizontal {
        > li {
            > a {
                padding-top: 25px;
                padding-bottom: 25px;
                &:hover,
                &:active,
                &:focus,
                &.highlighted {
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }
        }
        li {
            .lable-nav {
                top: 5px;
            }
        }
    }
    .onhover-div {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .cart_qty_cls {
        top: 16%;
        right: -14px;
    }
}
.tools-bg {
    background-color: #f5f5f5;
    section {
        background-color: #f5f5f5;
    }
}
.tools-parallax {
    &.small-slider {
        .home-slider {
            .home {
                .slider-contain {
                    h4,
                    h1 {
                        color: $white;
                    }
                }
            }
        }
    }
}
.tools-parallax-product {
    &.full-banner {
        padding-top: 110px;
        padding-bottom: 110px;
    }
    .tools-description {
        h3 {
            color: $white;
            text-transform: uppercase;
            margin-top: -5px;
        }
        .tools-form {
            .search-box {
                width: 75%;
                margin: 20px 0;
                select {
                    text-transform: capitalize;
                }
            }
            .btn-find {
                width: 75%;
                padding: 7px 29px;
                background-size: 1000px;
            }
        }
    }
    .tools-grey {
        .slick-prev,
        .slick-next {
            transform: scale(1.5);
        }
    }
}
.tools_slider {
    .home-slider {
        .home {
            .container {
                position: relative;
            }
            .tools-parts {
                position: absolute;
                top: 0;
                right: 10%;
                display: flex;
                align-items: center;
                height: 75vh;
                margin-top: 50px;
            }
            .tools-parts1 {
                position: absolute;
                top: 0;
                right: 20%;
                display: flex;
                align-items: center;
                height: 75vh;
                margin-top: 50px;
            }
        }
        .slider-contain {
            margin-top: 70px;
            margin-left: 150px;
            h4,
            h1 {
                color: $white;
            }
        }
    }
}
.tools-grey {
    .product-m {
        .slick-slide {
            .img-wrapper {
                margin: 0;
            }
        }
    }
    .product-box {
        width: 100%;
        display: inline-block;
        overflow: hidden;
        background-color: white;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
        margin: 0 10px;
        .img-wrapper {
            .lable-block {
                transition: all 0.5s ease;
            }
        }
        .cart-info {
            display: flex;
            left: 0;
            bottom: -30px;
            transition: all 0.5s ease;
            .mobile-quick-view {
                display: none;
            }
            a {
                background-color: #eef0f1;
                border-radius: 5px;
                i {
                    color: $grey2;
                }
                &:hover {
                    background-color: $theme-deafult;
                    transition: all 0.5s ease;
                    i {
                        color: $white;
                        transition: all 0.5s ease;
                    }
                }
            }
            button {
                width: 55%;
                background-color: #eef0f1;
                color: $grey2;
                border-radius: 5px;
                margin: 0 5px;
                transition: all 0.5s ease;
                i {
                    display: none;
                }
                &:hover {
                    background-color: $theme-deafult;
                    color: $white;
                    transition: all 0.5s ease;
                }
            }
        }
        .quick-view-part {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -20px;
            background-color: $theme-deafult;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(-50%, -50%);
            opacity: 0;
            transition: all 0.5s ease;
            i {
                font-size: 18px;
                color: $white;
            }
        }
        .product-info {
            padding-left: 15px;
            padding-bottom: 15px;
            background-color: $white;
            border-top: 1px solid rgba($round-border, 0.2);
            .rating {
                margin-top: 0;
                padding-top: 15px;
            }
        }
        .br {
            &.br-default {
                display: flex;
                justify-content: center;
            }
        }
        &:hover {
            .img-wrapper {
                .front {
                    img {
                        opacity: 0.05;
                        transition: all 0.5s ease;
                    }
                }
                .cart-info {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 10px;
                    button {
                        animation: none;
                    }
                    a {
                        &:nth-child(2) i {
                            animation: none;
                        }
                        &:nth-child(3) i {
                            animation: none;
                        }
                        &:nth-child(4) i {
                            animation: none;
                        }
                    }
                }
                .lable-block {
                    display: none;
                    transition: all 0.5s ease;
                }
            }
            .quick-view-part {
                opacity: 1;
                transition: all 0.5s ease;
            }
            .ribbon {
                display: none;
                transition: all 0.5s ease;
            }
        }
    }
    .ribbon {
        position: absolute;
        left: -5px;
        top: -5px;
        z-index: 1;
        overflow: hidden;
        width: 75px;
        height: 75px;
        text-align: right;
        transition: all 0.5s ease;
        span {
            font-size: 10px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            text-align: center;
            line-height: 20px;
            transform: rotate(-45deg);
            width: 100px;
            display: block;
            background: $theme-deafult;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
            position: absolute;
            top: 19px;
            left: -21px;
            &:before {
                content: '';
                position: absolute;
                left: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid $theme-deafult;
                border-right: 3px solid transparent;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $theme-deafult;
            }
            &:after {
                content: '';
                position: absolute;
                right: 0px;
                top: 100%;
                z-index: -1;
                border-left: 3px solid transparent;
                border-right: 3px solid $theme-deafult;
                border-bottom: 3px solid transparent;
                border-top: 3px solid $theme-deafult;
            }
        }
    }
}
.category-tools {
    .category-m {
        .category-wrapper {
            background-color: white;
            border: none;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
        }
    }
}
.banner-tools {
    margin-top: 30px;
    border: 1px solid $round-border;
    overflow: hidden;
    img {
        transition: all 0.5s ease;
    }
    &:hover {
        img {
            transform: scale(1.05);
            transition: all 0.5s ease;
        }
    }
}
.tools-brand {
    .row {
        background-color: white;
        padding: 25px 0;
        box-shadow: 0 0 8px 0 #ddd;
        margin: 0;
    }
}
.sticky-footer {
    background-image: url('#{$assetspath}/tools/footer.jpg');
    .darken-layout {
        background-color: transparent !important;
        p {
            color: $grey8;
        }
        .sub-title {
            li {
                a {
                    color: $grey8;
                }
            }
            .contact-list {
                li,
                i {
                    color: $grey8;
                }
            }
        }
        .footer-social {
            i {
                color: $grey8;
            }
        }
    }
    .dark-subfooter {
        background-color: #141111 !important;
        p {
            color: $grey8 !important;
        }
    }
}
.green-gradient {
    .top-header {
        background: $theme-deafult;
        background-image: linear-gradient(to right, $gradient1, $gradient2);
        .header-contact {
            padding: 10px 0;
            li {
                color: $white;
                i {
                    color: $white;
                }
            }
        }
        .header-dropdown {
            li {
                color: $white;
                padding: 10px 25px;
                a {
                    color: $white;
                    i {
                        color: $white;
                    }
                }
            }
        }
    }
    .pixelstrap {
        li {
            .lable-nav {
                background: $theme-deafult;
                background-image: linear-gradient(to right, $gradient1, $gradient2);
            }
        }
    }
}
.service_slide {
    position: relative;
    .home-slider {
        .home {
            background-blend-mode: overlay;
            background-color: rgba($black, 0.4);
        }
        .slider-contain {
            height: 200px;
        }
    }
    .service-home {
        position: absolute;
        width: 100%;
        bottom: 0;
        .service-block1 {
            background-color: rgba($white, 0.9);
            padding: 25px 0;
            h4 {
                margin-bottom: 0;
            }
            p {
                text-transform: capitalize;
            }
            svg {
                margin-bottom: 5px;
                width: 50px;
                height: 50px;
            }
            &:nth-child(even) {
                background-color: rgba($white, 0.85);
            }
        }
    }
}
.tab-left {
    .theme-tab {
        display: flex;
        .left-side {
            width: 30%;
            .left-tab-title {
                margin-bottom: 38px;
                h4 {
                    color: #dafe69;
                    margin-top: -3px;
                }
                h3 {
                    color: $white;
                }
            }
            .tab-title {
                margin-right: 100px;
                text-align: left;
                a {
                    text-transform: capitalize;
                }
            }
            li {
                width: 100%;
                padding: 7px 0;
                border-bottom: 1px solid #606060;
                a {
                    color: $white;
                }
                &:first-child {
                    border-top: 1px solid #606060;
                }
                &.current {
                    a {
                        color: #dafe69;
                    }
                }
            }
        }
        .tab-content-cls {
            width: 70%;
        }
    }
}
.product_section {
    background-image: url('#{$assetspath}/marijuana/leaf-bg.jpg');
}
.border-box {
    &.tools-grey {
        .ribbon {
            span {
                background-image: linear-gradient(to right, $gradient1, $gradient2);
            }
        }
        .product-box {
            border-radius: 10px;
            box-shadow: none;
            border: 1px solid $round-border;
            .cart-info {
                a {
                    &:hover {
                        background-image: linear-gradient(to right, $gradient1, $gradient2);
                    }
                }
                button {
                    &:hover {
                        background-image: linear-gradient(to right, $gradient1, $gradient2);
                    }
                }
            }
            .quick-view-part {
                background-image: linear-gradient(to right, $gradient1, $gradient2);
            }
        }
    }
}
.detail-cannabis {
    .detail_section {
        text-align: center;
        > div {
            box-shadow: -1px 2px 5px 1px #ececec;
            padding: 45px 25px;
            background-color: white;
        }
        p {
            margin-bottom: 0;
            line-height: 24px;
            letter-spacing: 0.05em;
        }
        h4 {
            font-weight: 700;
        }
        svg {
            width: 50px;
            margin-bottom: 15px;
            fill: $theme-deafult;
        }
    }
}
footer {
    &.footer-5 {
        background-image: linear-gradient(to right, $gradient1, $gradient2);
        .dark-layout {
            background-color: transparent;
        }
        .sub-footer {
            background-color: transparent;
            > .container {
                border-top: 1px solid $white;
            }
            p {
                color: $white;
            }
        }
        .footer-theme2 {
            p {
                color: $white;
            }
            .contact-details {
                li {
                    color: $white;
                    a {
                        color: $white;
                    }
                }
            }
            .subscribe-block {
                border-left: 1px solid $white;
                border-right: 1px solid $white;
            }
        }
    }
}
.tap-top {
    &.green-top {
        background-image: linear-gradient(to right, $gradient1, $gradient2);
        border: 1px solid rgba($white, 0.5);
    }
}
.quickviewm {
    .modal-dialog {
        .modal-content {
            .modal-body {
                background-image: none;
            }
        }
    }
}
#toast-container > div {
    opacity: 1;
}
.toast {
    font-size: initial !important;
    border: initial !important;
    backdrop-filter: blur(0) !important;
}
.toast-success {
    background-color: #51a351 !important;
}
.toast-error {
    background-color: #bd362f !important;
}
.toast-info {
    background-color: #2f96b4 !important;
}
.toast-warning {
    background-color: #f89406 !important;
}
/* =====================
    18.Flying Cart CSS start
========================== */
.addcart_btm_popup {
    position: fixed;
    right: 0;
    width: 42px;
    height: 41px;
    color: #000000;
    cursor: pointer;
    transition: all ease-in 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    text-decoration: none;
    background: #ffffff;
    top: 33%;
    border: 1px solid white;
    -webkit-box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
    box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
    .fixed_cart {
        position: relative;
        z-index: 99;
        display: block;
        float: right;
        font-size: 18px;
        text-decoration: none;
        padding-left: 8px;
        white-space: nowrap;
        .cart_qty_cls {
            font-weight: 400;
        }
    }
}
/* =====================
    19.footer CSS start
========================== */
footer {
    position: relative;
    .footer-lable {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: $theme-deafult;
        top: -10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-bottom: 10px solid darken($theme-deafult, 30%);
            border-right: 10px solid transparent;
            top: 0;
            right: -10px;
        }
        i {
            color: white;
            font-size: 25px;
        }
    }
    .footer-theme {
        .footer-mobile-title {
            display: none;
        }
    }
    .darken-layout {
        p {
            color: $grey-font;
        }
        .sub-title {
            .footer-title {
                &.active {
                    .according-menu {
                        font: normal normal normal 14px/1 $font-awesome;
                        &.before {
                            content: '\f068';
                        }
                    }
                }
            }
            h4 {
                color: $white;
            }
            li {
                a {
                    color: $grey-font;
                }
            }
            .contact-list {
                i {
                    color: $grey-font;
                }
                li {
                    color: $grey-font;
                }
            }
        }
        .footer-social {
            i {
                color: $grey-font;
            }
        }
    }
    p {
        line-height: 32px;
        letter-spacing: 0.03em;
        margin-bottom: 0;
    }
}
.footer-white {
    .light-layout {
        background-color: white;
    }
    .sub-footer {
        .row {
            border-top: 1px solid $round-border;
        }
    }
}
.footer-light {
    .subscribe {
        display: flex;
        height: 100%;
        align-items: center;
        border-right: 1px solid $round-border;
        text-align: left;
        p {
            line-height: 1;
        }
        h4 {
            margin-bottom: 10px;
        }
    }
    .subscribe-form {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: flex-end;
    }
}
.subscribe {
    text-align: center;
    h4 {
        color: $dark-font;
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        margin-bottom: 15px;
        i {
            color: $theme-deafult;
            padding-right: 5px;
            font-size: 23px;
        }
    }
}
.subscribe-form {
    display: inline-flex;
    .form-group {
        margin-bottom: 0;
    }
    .form-control {
        padding: 13px;
        width: 345px;
        border-radius: 0;
        border: 1px solid $white-dark;
        &.name-cls {
            width: 265px;
        }
    }
    &.classic-form {
        .form-control {
            border-radius: 0;
            border: none;
            background-color: $form-bg;
            margin-left: 15px;
        }
        .btn-solid {
            margin-bottom: 0 !important;
        }
    }
}
.footer-social,
.social-white {
    margin-top: 40px;
    display: flex;
    align-items: center;
    li {
        display: inline-block;
        padding-right: 45px;
    }
    i {
        font-size: 20px;
        color: $black;
        transition: 0.5s ease;
        &:hover {
            color: $theme-deafult !important;
            transition: 0.5s ease;
        }
    }
}
.social-white {
    li {
        padding-left: 22px;
        padding-right: 22px;
    }
}
.footer-theme {
    .footer-logo {
        margin-bottom: 18px;
    }
    .sub-title {
        h4 {
            color: $dark-font;
            margin-bottom: 25px;
            text-transform: uppercase;
            font-weight: 700;
        }
        li {
            position: relative;
            display: inherit;
            padding-top: 13px;
            text-transform: capitalize;
            font-size: 14px;
            color: $grey;
            a {
                position: relative;
                color: $grey;
                &:before {
                    position: absolute;
                    top: 20px;
                    content: '';
                    height: 2px;
                    width: 0;
                    background-color: $theme-deafult;
                    transition: 0.5s ease;
                }
            }
            &:hover {
                a {
                    &:before {
                        width: 100%;
                        height: 2px;
                        transition: 0.5s ease;
                    }
                }
            }
        }
        .contact-list {
            i {
                position: absolute;
                left: 0;
                top: 21px;
            }
            li {
                position: relative;
                padding-left: 25px;
                line-height: 28px;
                a {
                    &:hover {
                        color: $theme-deafult;
                        transition: all 0.5s ease;
                    }
                }
                &:hover {
                    color: lighten($black, 60%);
                    transition: all 0.3s ease;
                    a {
                        &:before {
                            display: none;
                        }
                    }
                }
                &:last-child {
                    margin-bottom: -5px;
                }
            }
        }
    }
}
.sub-footer {
    background-color: $white;
    &.dark-subfooter {
        background-color: $dark-footer;
        p {
            color: $grey-font;
        }
    }
    &.darker-subfooter {
        background-color: $darker-footer;
        p {
            color: $grey-shade;
        }
    }
    &.black-subfooter {
        background-color: $dark-font;
        p {
            color: $grey;
        }
    }
    p {
        color: $grey;
        margin-bottom: 0;
        margin-bottom: 30px;
    }
    .payment-card-bottom {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
            padding-left: 7px;
            padding-right: 7px;
            display: inline-block;
        }
    }
}
.footer-theme2 {
    text-align: center;
    .footer-mobile-title {
        display: none !important;
    }
    .contact-details {
        li {
            a {
                color: $grey-shade;
                &:hover {
                    color: $theme-deafult;
                    transition: all 0.5s ease;
                }
            }
        }
    }
    &.footer-border {
        border: 20px solid $border-grey;
    }
    .footer-logo {
        margin-bottom: 18px;
    }
    p {
        line-height: 32px;
        letter-spacing: 0.03em;
        margin-bottom: 0;
        color: $grey-shade;
    }
    h4 {
        color: $white;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 0;
        padding-bottom: 25px;
    }
    .contact-details {
        li {
            display: block;
            color: $grey-shade;
            padding: 0 50px;
            line-height: 2.2;
            &:last-child {
                margin-bottom: -8px;
            }
        }
    }
    .subscribe-block {
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;
        padding: 10px 110px;
        h2 {
            color: $white;
            margin-bottom: 30px;
        }
        .btn-solid {
            margin-top: 10px;
        }
        .form-group {
            margin-bottom: 0;
        }
        .form-control {
            text-align: center;
            letter-spacing: 5px;
            font-size: 12px;
            padding: 15px 0;
            border-radius: 0;
        }
    }
    .footer-link,
    .footer-link-b {
        &.link-white {
            h4 {
                color: $dark-font;
            }
            a {
                color: $grey;
                transition: 0.5s ease;
                &:hover {
                    color: $grey-dark;
                    transition: 0.5s ease;
                }
            }
        }
        h4 {
            padding-bottom: 20px;
        }
        li {
            padding-right: 15px;
            padding-left: 15px;
            text-transform: capitalize;
        }
        a {
            color: $grey-shade;
            transition: 0.5s ease;
            &:hover {
                color: $form-bg;
                transition: 0.5s ease;
            }
        }
    }
    .footer-link-b {
        h4 {
            margin-top: 50px;
        }
    }
    &.section-light {
        h2,
        h4 {
            color: $dark-font;
        }
        .footer-block {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 100%;
            h4 {
                padding-bottom: 25px;
            }
            .contact-details {
                li {
                    a {
                        color: $grey-shade;
                        &:hover {
                            color: $theme-deafult;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
            .subscribe-white {
                background-color: lighten($theme-deafult, 10%);
                width: 100%;
                padding: 85px 65px;
                border-left: 20px solid $border-grey;
                border-right: 20px solid $border-grey;
                .form-group {
                    margin-bottom: 0;
                }
                .form-control {
                    border: none;
                    border-radius: 0;
                }
                h2 {
                    margin-bottom: 30px;
                    margin-top: -5px;
                }
                .btn-solid {
                    margin-top: 10px;
                }
                .form-control {
                    text-align: center;
                    letter-spacing: 5px;
                    font-size: 12px;
                    padding: 15px 0;
                }
            }
        }
    }
}
.cart-qty-cls {
    position: absolute;
    background: $theme-deafult;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 20px;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    top: 20%;
    right: -8px;
    padding: 3px;
}
.loader-img {
    position: absolute;
    width: 170px;
    left: -65px;
    top: -24px;
}
/* =====================
    20.Loader CSS start
========================== */
@media (min-width: 600px) {
    .fluio-wrapper {
        height: 100vh;
        width: 100vw;
        z-index: 100;
        position: fixed;
        background-color: #fff;
        top: 0;
        .loader {
            width: 40%;
            height: auto;
            position: absolute;
            top: 44%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block !important;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
@media (max-width: 600px) {
    .fluio-wrapper {
        height: 100vh;
        width: 100vw;
        z-index: 100;
        position: fixed;
        background-color: #fff;
        .loader {
            width: 75%;
            height: auto;
            position: absolute;
            top: 44%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block !important;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.loader-wrapper {
    height: 100vh;
    width: 100vw;
    background-color: #fff;
    display: block;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        &:after {
            content: '';
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            border: 0 solid white;
            transform: translate(-50%, -50%);
            animation: loading 1000ms ease-out forwards infinite;
            transition: all 0.3s ease;
        }
    }
}
@keyframes loading {
    0% {
        border: 0 solid white;
        transition: all 0.3s ease;
    }
    20% {
        border: 8px solid white;
        width: 0;
        height: 0;
        transition: all 0.3s ease;
    }
    100% {
        border: 8px solid white;
        width: 100%;
        height: 100%;
        transition: all 0.3s ease;
    }
}
/* =====================
    21.Tab to top CSS start
========================== */
.tap-top {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 8;
    display: none;
    color: #fff;
    text-align: center;
    background: $theme-deafult;
    border: none;
    font-size: 22px;
    padding: 7px;
    cursor: pointer;
    &.top-cls {
        bottom: 130px;
    }
}
/* =====================
    22.theme setting box CSS start
========================== */
.color1 {
    background: #ff4c3b;
}
.color2 {
    background: #3fdda7;
}
.color3 {
    background: #f0b54d;
}
.color4 {
    background: #e4604a;
}
.color5 {
    background: #d4b196;
}
.color6 {
    background: #866e6c;
}
.color7 {
    background: #cc2121;
}
.color8 {
    background: #dc457e;
}
.color9 {
    background: #6d7e87;
}
.color10 {
    background: #fa869b;
}
.color11 {
    background: #81ba00;
}
.color12 {
    background: #fe816d;
}
.color13 {
    background: #01effc;
}
.color14 {
    background: #5d7227;
}
.color15 {
    background: #ff9944;
}
.color16 {
    background: #5fcbc4;
}
.color17 {
    background: #e38888;
}
.color18 {
    background: #000000;
}
.color-picker {
    position: fixed;
    right: -190px;
    top: calc(38vh + 220px);
    width: 190px;
    z-index: 1;
    transition: all 0.3s ease;
    .settings-header {
        background: #f3f3f3;
        padding: 5px 15px;
        box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
        h3 {
            color: black;
            margin: 0;
            font-size: 20px;
            padding: 4px 9px;
        }
    }
    .section {
        background: white;
        padding: 10px;
        border: 1px solid #f3f3f3;
        overflow: auto;
        i {
            font-size: 16px;
            margin-right: 10px;
        }
    }
    a {
        &.handle {
            position: absolute;
            right: 190px;
            width: 45px;
            height: 41px;
            transition: all ease-in 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            z-index: -1;
            text-decoration: none;
            background-color: rgb(249, 249, 249);
            box-shadow: -5.985px -0.419px 30px 0 rgba(0, 0, 0, 0.08), inset -2px 0 10px 0 rgba(0, 0, 0, 0.04);
            top: -80px;
            color: $black;
            i {
                margin-left: 5px;
            }
        }
    }
    .colors {
        a {
            width: 32px;
            height: 32px;
            margin: 5px;
            float: left;
            transition: all ease-in 0.3s;
        }
    }
    .skin {
        a {
            display: block;
            width: 70px;
            height: 30px;
            margin-left: 0;
            margin-top: 0;
            margin-right: 5px;
            float: left;
            text-align: center;
            line-height: 30px;
            border: 2px transparent solid;
            &.actt {
                border: 2px white solid;
            }
        }
    }
}
.dark-light {
    position: fixed;
    right: 0;
    width: 40px;
    height: 38px;
    display: flex;
    z-index: 1;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-decoration: none;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f7f7f7;
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    top: calc(50% + 34px);
    cursor: pointer;
}
.setting-sidebar {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    background-color: #f7f7f7;
    width: 40px;
    height: 40px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 0px 5px 0px rgba(154, 154, 154, 0.54);
    transition: all 0.5s ease;
    z-index: 1;
    i {
        animation: ring-rotate 3s infinite linear;
        font-size: 20px;
        color: #3c3c3c;
        margin-left: 10px;
    }
    &.open-icon {
        right: 320px;
        transition: all 0.5s ease;
    }
}
.setting_box_body {
    position: relative;
    background-color: white;
    z-index: 9;
    height: 100vh;
}
.setting-box {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 320px;
    right: -360px;
    background-color: white;
    top: 0;
    z-index: 999;
    box-shadow: 0 15px 5px 0 rgba(77, 77, 77, 0.28);
    transition: all 0.5s ease;
    ::-webkit-scrollbar {
        width: 3px;
        height: 7px;
    }
    ::-webkit-scrollbar-track {
        background: $round-border;
    }
    ::-webkit-scrollbar-thumb {
        background: $grey5;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: $grey2;
    }
    &.open-setting {
        right: 0;
        transition: all 0.5s ease;
        .overlay {
            visibility: visible;
            transition: all 0.5s ease;
        }
    }
    .sidebar-back {
        padding: 20px;
        font-size: 18px;
        color: #222222;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        box-shadow: inset 0px 0px 6px rgba(115, 115, 115, 0.09);
        background-color: #f3f3f3;
    }
    .setting-title {
        padding: 20px;
        border-bottom: 1px solid #efefef;
        margin-bottom: 0;
        h4 {
            position: relative;
            margin-bottom: 0;
            cursor: pointer;
        }
        &.active {
            .according-menu {
                font: normal normal normal 14px/1 FontAwesome;
                &:before {
                    content: '\f068';
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }
        .according-menu {
            font: normal normal normal 14px/1 FontAwesome;
            &:before {
                content: '\f067';
                position: absolute;
                right: 2px;
                top: 2px;
            }
        }
    }
    .setting-contant {
        padding: 20px;
        border-bottom: 1px solid #efefef;
        .setting_buttons {
            .setting_btn {
                background-color: $theme-deafult;
                color: white;
                width: 100%;
            }
            li {
                &:first-child {
                    width: 49%;
                }
                &.active {
                    box-shadow: 0px 0px 5px 0px rgb(146, 146, 146);
                }
                &:last-child {
                    margin-left: 10px;
                    width: 46%;
                }
            }
        }
        .color-box {
            li {
                margin: 3px;
                width: 32px;
                height: 32px;
                display: inline-grid;
            }
            &.active {
                position: relative;
                li {
                    box-shadow: 0px 0px 5px 0px rgb(146, 146, 146);
                }
                &:after {
                    content: '';
                    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                    top: 12px;
                    height: 15px;
                    width: 15px;
                    background-size: 80%;
                    background-repeat: no-repeat;
                    position: absolute;
                    left: 13px;
                    filter: invert(100);
                }
            }
        }
    }
    .buy_btn {
        padding: 20px;
        a {
            background-color: #f3f3f3;
            color: #3c3c3c;
            padding: 10px;
            transition: all 0.5s ease;
            i {
                margin-right: 5px;
                font-size: 18px;
            }
            img {
                filter: grayscale(100);
            }
        }
        .btn_multi {
        }
    }
    .overlay {
        visibility: hidden;
        opacity: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease;
    }
}
.demo-section {
    .title-text {
        h3 {
            color: $theme-deafult;
            margin-bottom: 24px;
            display: inline-block;
            padding-bottom: 5px;
            font-size: 40px;
            position: relative;
            i {
                font-size: 22px;
                position: absolute;
                top: 0;
                right: -20px;
            }
        }
    }
    .demo-effects {
        margin-bottom: 30px;
        h4 {
            text-transform: capitalize;
        }
        .set-position {
            display: block;
        }
        > div {
            text-decoration: none;
            color: black;
            .layout-container {
                height: 110px;
                width: 100%;
                display: inline-flex;
                background-size: cover;
                transition: all 2s ease-out 0s;
                box-shadow: -1px 2px 5px 1px #ececec;
                @for $i from 1 through 70 {
                    &.demo#{$i} {
                        background-image: url('#{$assetspath}/landing-page/demo/#{$i}.jpg');
                    }
                }
            }
            .demo-text {
                h4 {
                    font-size: 16px;
                    margin-top: 10px;
                    margin-bottom: 0;
                    color: #000000;
                    span {
                        font-weight: 500;
                    }
                }
                .demo-btn {
                    margin-top: 10px;
                    .btn {
                        border: 1px solid #ddd;
                        background-color: white;
                        width: 90px;
                        color: $theme-deafult;
                        padding: 5px 10px;
                        border-radius: 30px;
                        &:hover {
                            background-color: $theme-deafult;
                            color: white;
                        }
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &.effect-2 {
            .layout-container {
                background-size: cover;
                background-repeat: no-repeat;
            }
            &:hover {
                .layout-container {
                    background-position: top !important;
                }
            }
        }
        &.effect-3 {
            .layout-container {
                background-size: cover;
                background-repeat: no-repeat;
            }
            &:hover {
                .layout-container {
                    background-position: top !important;
                }
            }
        }
        &:hover {
            .layout-container {
                background-position: center 100% !important;
            }
        }
    }
}
@keyframes ring-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.sec-position {
    position: relative;
    top: -80px;
}
.color-picker {
    position: fixed;
    right: -190px;
    top: 35%;
    width: 190px;
    z-index: 1;
    transition: all 0.3s ease;
    .settings-header {
        background: #f3f3f3;
        padding: 5px 15px;
        box-shadow: 0px -10px 20px -5px rgba(220, 220, 220, 0.62);
        h3 {
            color: black;
            margin: 0;
            font-size: 20px;
            padding: 4px 9px;
        }
    }
    .section {
        background: white;
        padding: 10px;
        border: 1px solid #f3f3f3;
        overflow: auto;
        i {
            font-size: 16px;
            margin-right: 10px;
        }
    }
    a {
        &.handle {
            position: absolute;
            right: 190px;
            width: 42px;
            height: 41px;
            color: $black;
            transition: all ease-in 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            z-index: -1;
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;
            text-decoration: none;
            background: #ffffff;
            top: -80px;
            border: 1px solid white;
            box-shadow: 0 0 5px rgba(4, 4, 4, 0.28);
            i {
                margin-left: 5px;
            }
            &:hover {
                color: $theme-deafult;
            }
        }
    }
    .colors {
        a {
            width: 32px;
            height: 32px;
            margin: 5px;
            float: left;
            transition: all ease-in 0.3s;
        }
    }
    .skin {
        a {
            display: block;
            width: 70px;
            height: 30px;
            margin-left: 0;
            margin-top: 0;
            margin-right: 5px;
            float: left;
            text-align: center;
            line-height: 30px;
            border: 2px transparent solid;
            &.actt {
                border: 2px white solid;
            }
        }
    }
}
.handle {
    i {
        animation: ring-rotate 3s infinite linear;
    }
    &:hover {
        background-color: white;
    }
}
/* =====================
  23.Inner pages CSS start
========================== */
// error section //
.error-section {
    padding: 130px 0 140px;
    text-align: center;
    h1 {
        font-size: 200px;
        font-weight: 900;
        margin: 0;
        line-height: 0.8;
        color: $font-grey;
        margin-top: -11px;
    }
    h2 {
        color: $font-grey;
        font-weight: 500;
        letter-spacing: 3px;
        margin: 40px 0;
    }
}
// breadcrumb section //
.breadcrumb-section {
    padding: 30px 0;
    .page-title {
        display: flex;
        height: 100%;
        align-items: center;
        h2 {
            font-size: 16px;
            margin-bottom: 0;
        }
    }
    .breadcrumb {
        background-color: transparent;
        justify-content: flex-end;
        text-transform: uppercase;
        font-size: 14px;
        color: $grey2;
        font-weight: 600;
        margin: 0;
        a {
            color: $grey2;
            font-weight: 600;
        }
    }
}
// about page section //
.about-head {
    margin-bottom: 20px;
    align-items: center;
    display: flex;
    height: 100%;
    h2 {
        color: $theme-deafult;
        margin-bottom: 0;
    }
}
.about-page {
    p {
        margin-bottom: -7px;
    }
    .banner-section {
        border-radius: 5px;
        overflow: hidden;
    }
    h4 {
        margin-bottom: 10px;
        margin-top: 30px;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 1.3;
    }
    p {
        line-height: 2;
        letter-spacing: 0.03em;
    }
}
.testimonial {
    background-color: $grey-about;
    .testimonial-slider {
        .media {
            img {
                border-radius: 100%;
                border: 1px solid $round-border;
                padding: 5px;
                height: 130px;
                width: 130px;
            }
            h5 {
                font-weight: 700;
                color: $theme-deafult;
                margin-bottom: 0;
                margin-top: 7px;
            }
            h6 {
                margin-bottom: -4px;
            }
            .media-body {
                padding: 30px 75px 30px 30px;
                p {
                    line-height: 2;
                    letter-spacing: 0.03em;
                }
            }
        }
        .slick-track {
            .slick-slide {
                &:nth-child(even) {
                    .media {
                        border-left: 1px solid #dddddd;
                        padding-left: 50px;
                    }
                }
            }
        }
    }
}
.team {
    position: relative;
    img {
        margin: 0 auto;
    }
    .slick-prev,
    .slick-next {
        position: absolute;
        top: -45px;
        &:before {
            font: normal normal normal 30px/1 $font-awesome;
            color: $dark-font;
            opacity: 0.6;
        }
    }
    .slick-prev {
        left: unset;
        right: 20px;
        &:before {
            content: '\f104';
        }
    }
    .slick-next {
        right: 0;
        &:before {
            content: '\f105';
        }
    }
    h2 {
        color: $dark-font;
        padding-bottom: 15px;
        border-bottom: 1px solid $border;
        margin-bottom: 10px;
    }
    h4,
    h6 {
        text-align: center;
    }
    h4 {
        color: $theme-deafult;
        font-weight: 700;
        margin-top: 15px;
        margin-bottom: 5px;
    }
    h6 {
        margin-bottom: 0;
    }
}
// category page //
.infinite-scroll-component {
    overflow: hidden !important;
}
.collection-filter-block {
    margin-bottom: 30px;
    padding: 0 30px;
    border: 1px solid lighten($black, 86.5%);
    background-color: $white;
    .product-service {
        padding: 30px 0;
        .media {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid $round-border;
            svg {
                height: 40px;
                width: 40px;
                path {
                    fill: $theme-deafult;
                }
            }
            .media-body {
                padding-left: 10px;
                h4 {
                    margin-bottom: 5px;
                }
                p {
                    margin-bottom: 0;
                    text-transform: capitalize;
                }
            }
            &:last-child {
                padding-bottom: 0;
            }
        }
    }
}
.collection-collapse-block {
    &.open {
        margin-bottom: 12px;
    }
    &:last-child {
        padding-bottom: 24px;
    }
    .collapse-block-title {
        position: relative;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 16px;
        color: lighten($black, 26.5%);
        font-weight: 600;
        margin: 30px 0 0 0;
        &:after {
            position: absolute;
            display: block;
            top: 0;
            right: -3px;
            content: '\f0d7';
            font-family: $font-awesome;
            color: lighten($black, 26.5%);
        }
    }
    .collection-collapse-block-content {
        overflow: hidden;
        .color-selector {
            margin-top: 30px;
        }
        .collection-brand-filter {
            .category-list {
                li {
                    display: flex;
                    font-size: 16px;
                    text-transform: uppercase;
                    line-height: 18px;
                    a {
                        color: $grey;
                        padding-bottom: 10px;
                    }
                    &:last-child {
                        a {
                            padding-bottom: 0;
                        }
                    }
                    &:first-child {
                        margin-top: 30px;
                    }
                }
            }
            .collection-filter-checkbox {
                margin-bottom: 12px;
                &:first-child {
                    margin-top: 30px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                label {
                    text-transform: uppercase;
                    cursor: pointer;
                    font-size: 12px;
                    color: lighten($black, 46.5%);
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    &:before {
                        top: 1px;
                        height: 15px;
                        width: 15px;
                        background: $white;
                        border: 1px solid lighten($black, 46.5%);
                    }
                    &:after {
                        top: 1px;
                        height: 15px;
                        width: 15px;
                        background-size: 65%;
                    }
                }
                .custom-control-input {
                    &:checked {
                        ~ .custom-control-label {
                            &:after {
                                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                            }
                        }
                    }
                }
            }
        }
    }
}
.color-selector {
    ul {
        li {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            margin-right: 5px;
            margin-bottom: 10px;
            cursor: pointer;
            border: 1px solid $grey4;
        }
        .color-1 {
            background-color: white;
        }
        .color-2 {
            background-color: grey;
        }
        .color-3 {
            background-color: red;
        }
        .color-4 {
            background-color: purple;
        }
        .color-5 {
            background-color: blue;
        }
        .color-6 {
            background-color: yellow;
        }
        .color-7 {
            background-color: green;
        }
        li.active {
            border: 1px solid black;
            position: relative;
            &:after {
                content: '';
                background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>");
                top: 10px;
                right: 4px;
                height: 15px;
                width: 15px;
                background-size: 70%;
                background-repeat: no-repeat;
                position: absolute;
            }
        }
    }
}
.col-lg-2 .product-box .img-wrapper .product-thumb-list li img {
    height: 35px;
}
.collection-sidebar-banner {
    margin-top: 30px;
    img {
        width: 100%;
    }
}
.top-banner-wrapper {
    .top-banner-content {
        h4 {
            font-weight: 600;
            color: lighten($black, 26.5%);
            margin-bottom: 15px;
        }
        h5 {
            font-size: 16px;
            font-weight: 600;
            color: lighten($black, 26.5%);
            letter-spacing: 1px;
            line-height: 1.2;
            margin-bottom: 10px;
        }
        p {
            line-height: 1.5;
            color: lighten($black, 46.5%);
            margin-bottom: 0;
        }
    }
}
.product-wrapper-grid.list-view {
    .product-wrap {
        .product-info {
            text-align: left;
            align-self: center;
            padding-left: 15px;
        }
    }
    .product-box {
        display: flex;
        padding-bottom: 0;
        .img-wrapper,
        .img-block {
            width: 25%;
            min-width: 150px;
        }
        .product-detail {
            padding-left: 15px;
            align-self: center;
            text-align: left !important;
            .rating {
                margin-top: 0;
            }
            p {
                display: block !important;
                margin-bottom: 5px;
                line-height: 18px;
            }
            .color-variant {
                padding-top: 10px;
            }
            h6 {
                font-weight: 700;
            }
        }
    }
}
.collection-product-wrapper {
    .product-top-filter {
        border-bottom: 1px solid lighten($black, 86.5%);
        border-top: 1px solid lighten($black, 86.5%);
        .product-filter-content,
        .popup-filter {
            display: flex;
            flex-wrap: wrap;
            .search-count,
            .sidebar-popup {
                border-right: 1px solid lighten($black, 86.5%);
                padding: 20px;
                text-transform: capitalize;
                width: 35%;
                h5 {
                    margin-bottom: 0;
                }
            }
            .collection-view {
                padding: 20px;
                width: 15%;
                ul {
                    li {
                        height: 100%;
                        vertical-align: middle;
                        cursor: pointer;
                        &:first-child {
                            margin-right: 14px;
                        }
                    }
                }
            }
            .collection-grid-view {
                padding: 20px;
                width: 25%;
                border-right: 1px solid lighten($black, 86.5%);
                ul {
                    display: flex;
                    justify-content: flex-end;
                    li {
                        margin-left: 10px;
                        img {
                            height: 18px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .product-page-per-view,
            .product-page-filter {
                position: relative;
                width: 25%;
                select {
                    border: 0;
                    padding: 20px;
                    border-right: 1px solid lighten($black, 86.5%);
                    width: 100%;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    cursor: pointer;
                    background: url('#{$assetspath}/dropdown.png') no-repeat 95%;
                    &:focus {
                        outline: unset;
                    }
                    option {
                        padding: 20px 0;
                    }
                }
            }
            .product-page-filter {
                width: 25%;
                &:before {
                    right: 10px;
                }
                select {
                    border-right: none;
                }
            }
        }
        .popup-filter {
            justify-content: unset;
            ::-webkit-scrollbar {
                width: 3px;
            }
            ::-webkit-scrollbar-track {
                background: $round-border;
            }
            ::-webkit-scrollbar-thumb {
                background: $grey5;
            }
            ::-webkit-scrollbar-thumb:hover {
                background: $grey2;
            }
            .sidebar-popup {
                position: relative;
                cursor: pointer;
                width: 20%;
                background: url('#{$assetspath}/dropdown.png') no-repeat 95%;
                a {
                    font-size: 16px;
                    color: $dark-font;
                }
                .collection-filter {
                    background-color: #f8f8f8;
                    width: 270px;
                    height: 600px;
                    overflow-y: scroll;
                    .collection-filter-block {
                        border: none;
                    }
                    .theme-card {
                        padding: 0 30px;
                    }
                    .collection-sidebar-banner {
                        padding: 0 30px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            .open-popup {
                display: none;
                position: absolute;
                z-index: 9;
                top: 75px;
                left: 0;
                border: 1px solid $border-grey;
                box-shadow: 0 0 5px #ddd;
                min-width: 330px;
                &.open {
                    display: block;
                }
                .collection-filter-block {
                    margin-bottom: 0;
                }
            }
            .product-page-per-view,
            .product-page-filter {
                width: 20%;
                select {
                    padding-right: 45px;
                    font-size: 16px;
                }
                &:before {
                    right: 35px;
                }
            }
            .collection-grid-view {
                padding: 20px;
            }
            .collection-view {
                width: 20%;
            }
            .product-page-filter {
                &:before {
                    right: 25px !important;
                }
            }
        }
    }
    .product-wrapper-grid {
        .product-box {
            margin-top: 50px;
            .product-detail {
                p {
                    display: none;
                }
            }
        }
        .product-five {
            flex: 0 0 20%;
            max-width: 20%;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-right: 15px;
            padding-left: 15px;
        }
        .col-lg-2 {
            .product-box {
                .product-detail {
                    h6 {
                        font-size: 13px;
                    }
                    h4 {
                        font-size: 18px;
                    }
                    .rating {
                        i {
                            padding-right: 0;
                        }
                    }
                }
                .img-wrapper {
                    .lable-block {
                        .lable3 {
                            padding: 8px 4px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
.filter-main-btn {
    display: none;
    margin-bottom: 20px;
    cursor: pointer;
    span {
        width: 100%;
        border-radius: 0;
        font-size: 20px;
    }
}
.collection-mobile-back {
    padding: 15px 0;
    border-bottom: 1px solid lighten($black, 86.5%);
    display: none;
    span {
        text-transform: uppercase;
        font-size: 14px;
        color: #000;
        cursor: pointer;
        i {
            font-size: 20px;
            font-weight: bold;
            margin-right: 5px;
        }
    }
}
.product-pagination {
    border-bottom: 1px solid lighten($black, 86.5%);
    border-top: 1px solid lighten($black, 86.5%);
    margin: 50px 20px 0 20px;
    .pagination {
        border-radius: 0;
        .page-item {
            border-radius: 0;
            a {
                padding: 18px;
                border-bottom: 0;
                border-top: 0;
                color: lighten($black, 46.5%);
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
            &:last-child {
                .page-link {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
        .page-item.active {
            a {
                color: $theme-deafult;
                background-color: $white;
                border-color: lighten($black, 86.5%);
            }
        }
    }
    .product-search-count-bottom {
        justify-content: flex-end;
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid lighten($black, 86.5%);
        border-right: 1px solid lighten($black, 86.5%);
        padding-right: 15px;
        h5 {
            color: lighten($black, 46.5%);
            font-size: 14px;
            margin-bottom: 0;
            padding: 5px 0;
        }
    }
}
// collection page //
.collection {
    .collection-block {
        text-align: center;
        img {
            margin-bottom: 20px;
        }
        .collection-content {
            text-align: center;
            h3 {
                margin-bottom: 10px;
                color: $dark-font;
                text-transform: uppercase;
                font-weight: 900;
                letter-spacing: 0.05em;
            }
            h4 {
                color: $dark-font;
                margin-bottom: 10px;
                font-size: 16px;
            }
            p {
                line-height: 1.5;
            }
            .btn-solid,
            .btn-outline {
                padding: 10px 25px;
            }
        }
    }
}
// faq //
.faq-section {
    .accordion.theme-accordion {
        .card {
            margin-top: 10px;
            border-radius: 0;
            overflow: hidden;
            border-bottom: 1px solid $round-border;
            :not(:first-of-type):not(:last-of-type),
            .theme-accordion .card:first-of-type {
                border-bottom: 1px solid $round-border;
            }
            &:first-child {
                margin-top: 0;
            }
            .card-header {
                padding: 0;
                border-bottom: 0;
                transition: all 0.3s ease;
                background-color: $grey-lighter;
                button {
                    color: $font-color;
                    text-align: left;
                    font-size: 17px;
                    padding: 18px;
                    font-weight: 600;
                    &:before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        border-top: 6px solid #000000;
                        right: 20px;
                        top: 25px;
                        transition: all 0.3s ease;
                    }
                    &[aria-expanded='true'] {
                        &:before {
                            border-top: 0;
                            border-bottom: 6px solid $black;
                            transition: all 0.3s ease;
                        }
                    }
                    &:hover {
                        text-decoration: none;
                        color: $theme-deafult;
                        transition: all 0.3s ease;
                    }
                }
            }
            .card-body {
                padding: 15px 18px;
                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    color: $grey2;
                    line-height: 1.8;
                    letter-spacing: 0.06em;
                }
            }
        }
    }
}
// forget_pwd//
.pwd-page {
    text-align: center;
    h2 {
        margin-bottom: 25px;
        color: $dark-font;
        margin-top: -5px;
    }
    .theme-form {
        input {
            border-color: $round-border;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: center;
        }
        .btn-solid {
            margin: 0 auto;
        }
    }
}
// login//
.login-page {
    h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $dark-font;
        font-weight: 700;
        margin-top: -5px;
    }
    .theme-card {
        padding: 30px;
        border: 1px solid $round-border;
        line-height: 1;
        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 14px;
                font-weight: 600;
            }
            input {
                border-color: $round-border;
                font-size: 12px;
                padding: 17px 25px;
                margin-bottom: 30px;
                height: inherit;
            }
        }
    }
    .authentication-right {
        height: calc(100% - 48px);
        h6 {
            text-transform: uppercase;
            color: $font-color;
            font-weight: 600;
            margin-bottom: 20px;
        }
        p {
            font-size: 14px;
            color: $grey2;
            line-height: 2;
            margin-bottom: 1rem;
        }
    }
}
// register page //
.register-page {
    h3 {
        margin-bottom: 20px;
        text-transform: uppercase;
        color: $dark-font;
        font-weight: 700;
        margin-top: -5px;
    }
    .theme-card {
        padding: 30px;
        border: 1px solid $round-border;
        line-height: 1;
        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 14px;
                font-weight: 600;
            }
            input {
                border-color: $round-border;
                font-size: 12px;
                padding: 17px 25px;
                margin-bottom: 30px;
                height: inherit;
            }
        }
    }
}
// search //
.search-block {
    padding: 0;
    .form-header {
        .input-group {
            input {
                border-radius: 0 5px 5px 0;
                padding: 15px 20px;
            }
            i {
                padding-right: 10px;
            }
            .btn {
                z-index: 0;
            }
        }
    }
}
// contacts //
.contact-page {
    .map {
        iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
    .contact-right {
        padding-top: 30px;
        padding-bottom: 30px;
        ul {
            li {
                position: relative;
                padding-left: 150px;
                margin-top: 45px;
                display: block;
                &:first-child {
                    margin-top: 0;
                }
                .contact-icon {
                    position: absolute;
                    left: 0;
                    text-align: center;
                    width: 130px;
                    border-right: 1px solid #dddddd;
                    i {
                        font-size: 20px;
                        color: #ff4c3b;
                    }
                    h6 {
                        text-transform: uppercase;
                        padding-top: 5px;
                        font-weight: 600;
                        color: #ff4c3b;
                    }
                }
                p {
                    font-weight: 600;
                    margin-bottom: 0;
                    line-height: 30px;
                }
            }
        }
    }
    .theme-form {
        margin-top: -6px;
        label {
            text-transform: capitalize;
            color: $font-color;
            font-size: 14px;
            font-weight: 600;
        }
        input {
            border-color: $round-border;
            font-size: 12px;
            padding: 17px 25px;
            margin-bottom: 30px;
            height: inherit;
        }
        textarea {
            border-color: $round-border;
            font-size: 12px;
            padding: 17px 25px;
            margin-bottom: 30px;
            height: inherit;
        }
    }
    .form-control {
        border-radius: 0;
    }
}
// shopping products //
.qty-box {
    .input-group {
        justify-content: center;
        span {
            button {
                background: $white !important;
                border: 1px solid #ced4da;
            }
        }
        .form-control {
            text-align: center;
            width: 80px;
            flex: unset;
        }
        button {
            background-color: transparent;
            border: 0;
            color: $grey;
            cursor: pointer;
            padding-left: 12px;
            font-size: 12px;
            font-weight: 900;
            line-height: 1;
            i {
                font-weight: 900;
                color: $dark-font;
            }
        }
        .icon {
            padding-right: 0;
        }
    }
}
.cart-section,
.wishlist-section {
    .table {
        tbody {
            & + tbody {
                border-top: none;
            }
        }
    }
    .cart-buttons {
        padding-top: 35px;
        > div {
            &:last-child {
                text-align: right;
                padding-right: 59px;
            }
        }
    }
    .wishlist-buttons {
        padding-top: 35px;
        text-align: right;
        &:last-child {
            a {
                margin-left: 15px;
            }
        }
    }
    .cart-table {
        margin-bottom: 0;
        thead {
            th {
                border-bottom-width: 1px;
                font-weight: 900;
                color: $dark-font;
                text-transform: uppercase;
                font-size: 14px;
                border-top: 0;
                text-align: center;
                padding: 0 0.75rem 0.75rem 0.75rem;
            }
        }
    }
    tbody {
        h2 {
            margin-bottom: 0;
        }
        tr {
            td {
                min-width: 210px;
                vertical-align: middle;
                color: $grey;
                border-top: 0;
                border-bottom: 1px solid #eeeeee;
                text-align: center;
                a,
                p {
                    color: $grey;
                    font-weight: 400;
                    font-size: 14px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    img {
                        height: 90px;
                    }
                }
                .td-color {
                    color: $theme-deafult;
                }
                h2 {
                    font-size: 24px;
                    color: $dark-font;
                    font-weight: 400;
                }
                .mobile-cart-content {
                    display: none;
                    justify-content: center;
                    margin-top: 10px;
                    .qty-box {
                        border-right: 1px solid $round-border;
                        border-radius: 5px;
                        .input-group {
                            .form-control {
                                width: 48px;
                                padding: 6px;
                            }
                        }
                    }
                    .col-xs-3 {
                        align-self: center;
                        margin-left: 10px;
                        margin-right: 10px;
                    }
                    h2 {
                        font-size: 20px;
                    }
                }
            }
        }
    }
    tfoot {
        tr {
            th {
                padding-top: 35px;
                text-align: right;
            }
            td {
                padding-top: 35px;
                padding-bottom: 0;
                text-align: right;
                border: none;
                padding-right: 63px;
                h2 {
                    font-size: 24px;
                    margin-bottom: 0;
                }
                &:first-child {
                    width: 85%;
                    font-size: 15px;
                    padding-right: 10px;
                    text-transform: capitalize;
                    font-weight: 700;
                }
            }
        }
    }
}
// blog-details //
.blog-detail-page {
    .blog-detail {
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
        img {
            margin-bottom: 40px;
        }
        h3 {
            color: $dark-font;
            font-weight: 700;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        p {
            color: $grey;
            line-height: 1.8;
        }
        .post-social {
            color: $grey;
            text-align: left;
            padding-bottom: 15px;
            border-bottom: 1px solid $round-border;
            margin-bottom: 15px;
            li {
                & + li {
                    padding-left: 15px;
                    margin-left: 15px;
                    border-left: 1px solid $round-border;
                }
            }
        }
    }
    .blog-advance {
        > div {
            &:last-child {
                p {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        img {
            margin-bottom: 25px;
            margin-top: 25px;
        }
        ul {
            list-style-type: decimal;
            list-style-position: inside;
            line-height: 2.5;
            font-size: 15px;
            li {
                display: list-item;
                font-weight: 700;
            }
        }
        p {
            line-height: 1.8;
        }
    }
    .comment-section {
        border-bottom: 1px solid $round-border;
        li {
            padding-top: 60px;
            padding-bottom: 60px;
            border-top: 1px solid $round-border;
            img {
                height: 65px;
                width: 65px;
                margin-right: 20px;
                border-radius: 100%;
            }
            h6 {
                color: $grey4;
                text-transform: uppercase;
                font-weight: 700;
                margin-bottom: 5px;
                span {
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: capitalize;
                    color: $grey;
                    margin-left: 20px;
                }
            }
            p {
                line-height: 1.8;
                margin-bottom: -4px;
            }
        }
    }
    .blog-contact {
        h2 {
            margin-bottom: 30px;
            margin-top: -5px;
        }
        .theme-form {
            label {
                text-transform: capitalize;
                color: $font-color;
                font-size: 16px;
                font-weight: 700;
            }
            input,
            textarea {
                border-color: $round-border;
                font-size: 14px;
                padding: 17px 25px;
                margin-bottom: 30px;
            }
        }
    }
}
// blog-page //
.blog-page {
    .blog-sidebar {
        h6 {
            margin-bottom: 0;
            line-height: 20px;
            color: $font-color;
            font-weight: 700;
        }
        p {
            line-height: 20px;
            margin-bottom: 0;
            color: $grey2;
        }
        h4 {
            color: $font-color;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 20px;
            line-height: 20px;
            letter-spacing: 1px;
        }
        .theme-card {
            border: 1px solid $round-border;
            padding: 30px;
            background-color: $white;
            .recent-blog {
                li {
                    .media {
                        img {
                            width: 100px;
                            margin-right: 10px;
                        }
                    }
                    & + li {
                        margin-top: 30px;
                    }
                }
            }
            .popular-blog {
                li {
                    .blog-date {
                        background-color: $theme-deafult;
                        height: 70px;
                        width: 70px;
                        margin: 0 15px 0 0;
                        padding: 15px;
                        span {
                            display: block;
                            text-align: center;
                            color: #ffffff;
                            text-transform: uppercase;
                        }
                    }
                    & + li {
                        margin-top: 30px;
                    }
                    p {
                        margin-top: 10px;
                    }
                }
            }
            & + .theme-card {
                margin-top: 30px;
            }
        }
    }
    .blog-media {
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        .blog-right {
            height: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            h6 {
                color: $theme-deafult;
                text-transform: uppercase;
                margin-bottom: 10px;
                margin-top: -3px;
            }
            h4 {
                color: $font-color;
                font-weight: 700;
                line-height: 1.5;
                margin-bottom: 15px;
            }
            p {
                line-height: 1.8;
                margin-bottom: 0;
                letter-spacing: 0.04em;
            }
            ul {
                color: $grey;
                margin-bottom: 15px;
                li {
                    font-size: 14px;
                    line-height: 1.5;
                    i {
                        padding-right: 3px;
                    }
                    & + li {
                        padding-left: 15px;
                        margin-left: 15px;
                        border-left: 1px solid #dddddd;
                    }
                }
            }
        }
    }
}
// product page //
.loading-cls {
    width: 70px;
    height: 60px;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-image: url('#{$assetspath}/loader.gif');
}
.seen-cls {
    text-align: center;
    margin-top: 15px;
}
.seen-it-cls {
    margin-top: 50px;
    color: red;
    font-size: 16px;
    b {
        border: 1px solid #ddd;
        padding: 5px;
    }
}
.instock-cls {
    color: $theme-deafult;
    font-weight: 700;
}
.product-slick,
.rtl-product-slick,
.product-right-slick,
.rtl-product-right-slick {
    z-index: 0;
    .slick-prev,
    .slick-next {
        opacity: 0;
        transform: scale(1);
        transition: all 0.5s ease;
        margin-top: -10px;
        &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: white;
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .slick-next {
        right: 1px;
        &:before {
            content: '\f105';
        }
    }
    .slick-prev {
        left: 1px;
        z-index: 1;
        &:before {
            content: '\f104';
        }
    }
    .slick-list {
        overflow: hidden;
    }
    .slick-slide {
        > div {
            display: flex;
            margin-bottom: 5px;
        }
    }
    &:hover {
        .slick-prev,
        .slick-next {
            opacity: 1;
            transform: scale(1.05);
            transition: all 0.5s ease;
            &:before {
                opacity: 1;
            }
        }
        .slick-next {
            right: 20px;
        }
        .slick-prev {
            left: 20px;
        }
    }
}
.slider-nav {
    .slick-track {
        height: auto !important;
    }
}
.slider-nav,
.rtl-slider-nav,
.slider-right-nav,
.rtl-slider-right-nav {
    .slick-slide {
        padding: 0 15px 15px;
        padding-bottom: 0;
        > div {
            display: flex;
            margin: 15px 15px 0 15px;
            border: 1px solid $round-border;
            transition: all 0.5s ease;
        }
        &.slick-current {
            > div {
                border-color: $font-grey;
                transition: all 0.5s ease;
            }
            img {
                border: 1px solid $round-border;
            }
        }
        &.slick-active {
            transition: all 0.5s ease;
        }
    }
}
.slider-right-nav,
.rtl-slider-right-nav {
    .slick-slide {
        &:first-child {
            > div {
                margin-top: 0;
            }
        }
    }
}
.product-thumbnail .slider-nav .slick-list .slick-track {
    margin-top: 30px;
}
.pro_sticky_info {
    border: 1px solid $round-border;
    padding: 20px;
}
.is_stuck {
    margin-top: 80px;
}
.image-scroll {
    div {
        > div {
            padding-bottom: 20px;
            &:last-child {
                padding: 0;
            }
        }
    }
}
.product-right {
    p {
        margin-bottom: 0;
        line-height: 1.5em;
    }
    .product-title {
        color: $dark-font;
        text-transform: capitalize;
        font-weight: 700;
        margin-bottom: 0;
    }
    .border-product {
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px dashed $round-border;
        &:last-child {
            padding-bottom: 0;
        }
    }
    h2 {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-size: 25px;
        line-height: 1.2em;
    }
    h3 {
        font-size: 26px;
        color: $dark-font;
    }
    h4 {
        font-size: 16px;
        margin-bottom: 7px;
        del {
            color: $grey;
        }
        span {
            padding-left: 5px;
            color: $theme-deafult;
        }
    }
    .color-variant {
        margin-bottom: 10px;
        li {
            height: 30px;
            width: 30px !important;
            cursor: pointer;
            float: none;
            display: inline-block;
        }
    }
    .product-buttons {
        margin-bottom: 20px;
        .btn-solid,
        .btn-outline {
            padding: 7px 25px;
            border-radius: 5px;
        }
        a {
            &:last-child {
                margin-left: 10px;
            }
        }
    }
    .product-description {
        h6 {
            span {
                float: right;
            }
        }
        .qty-box {
            display: flex;
            align-items: center;
            margin-top: 10px;
            .input-group {
                justify-content: unset;
                width: unset;
                .form-control {
                    border-right: none;
                }
            }
        }
    }
    .size-box {
        margin-top: 10px;
        margin-bottom: 10px;
        ul {
            li {
                height: 35px;
                width: 35px;
                border-radius: 50%;
                margin-right: 10px;
                cursor: pointer;
                border: 1px solid $grey-about;
                text-align: center;
                a {
                    color: $dark-font;
                    font-size: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }
                &.active {
                    background-color: $grey-about;
                }
            }
        }
    }
    .product-icon {
        display: flex;
        .product-social {
            margin-top: 5px;
            li {
                padding-right: 30px;
                a {
                    color: $font-color;
                    transition: all 0.3s ease;
                    i {
                        font-size: 18px;
                    }
                    &:hover {
                        color: $theme-deafult;
                    }
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        .wishlist-btn {
            background-color: transparent;
            border: none;
            i {
                border-left: 1px solid $round-border;
                font-size: 18px;
                padding-left: 10px;
                margin-left: 5px;
                transition: all 0.5s ease;
            }
            span {
                padding-left: 10px;
                font-size: 18px;
            }
            &:hover {
                i {
                    color: $theme-deafult;
                    transition: all 0.5s ease;
                }
            }
        }
    }
    .payment-card-bottom {
        margin-top: 10px;
        ul {
            li {
                padding-right: 10px;
            }
        }
    }
    .timer {
        p {
            color: $dark-font;
        }
        margin-top: 10px;
        background-color: $grey-about;
    }
    &.product-form-box {
        text-align: center;
        border: 1px solid $round-border;
        padding: 20px;
        .size-text {
            text-align: left;
        }
        .product-description {
            .qty-box {
                margin-bottom: 5px;
                .input-group {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
        .product-buttons {
            margin-bottom: 0;
        }
        .timer {
            margin-bottom: 10px;
            text-align: left;
        }
    }
}
.single-product-tables {
    display: flex;
    margin-top: 15px;
    table {
        width: 20%;
        tr {
            height: 35px;
            td:first-child {
                font-weight: 600;
            }
        }
    }
    &.detail-section {
        margin-top: 0;
        table {
            width: 55%;
        }
    }
}
.product-accordion {
    .btn-link {
        color: $dark-font;
        &:hover {
            text-decoration: none;
        }
    }
    .card {
        border: none;
    }
    .card-body {
        width: 100%;
        height: 300px;
    }
    .card-header {
        padding: 5px 8px;
        border-bottom: 1px dashed rgba(0, 0, 0, 0.125);
        background-color: rgba(127, 127, 127, 0.03);
    }
}
.product-related {
    h2 {
        color: $dark-font;
        padding-bottom: 20px;
        border-bottom: 1px solid #dddada;
        margin-bottom: 20px;
        margin-top: -5px;
    }
}
.rating {
    margin-top: 0;
    i {
        padding-right: 5px;
        &:nth-child(-n + 4) {
            color: $star-yellow;
        }
        &:last-child {
            color: $round-border;
        }
    }
    .three-star {
        padding-bottom: 5px;
        i {
            color: #acacac;
            &:nth-child(-n + 3) {
                color: #ffd200;
            }
        }
    }
}
.tab-border {
    border: 1px solid $round-border;
    margin: 0;
    padding: 8px 0;
    .nav-border {
        border-bottom: 0;
        border-right: 1px solid $round-border;
    }
}
.vertical-tab {
    &.tab-product,
    .product-full-tab {
        .nav-material {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 15px 20px;
                        cursor: pointer;
                    }
                    &:focus {
                        border: none;
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }
    }
}
.tab-product,
.product-full-tab {
    padding-top: 30px;
    .nav-material {
        &.nav-tabs {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            .nav-item {
                .nav-link {
                    color: #212121;
                    text-align: center;
                    padding: 0 15px 20px 15px;
                    text-transform: uppercase;
                    border: 0;
                    cursor: pointer;
                }
                .material-border {
                    border-bottom: 2px solid $theme-deafult;
                    opacity: 0;
                }
            }
        }
    }
    .theme-form {
        input {
            border-color: $round-border;
            font-size: 15px;
            padding: 15px 25px;
            margin-bottom: 15px;
            height: inherit;
            text-align: left;
        }
        .btn-solid {
            margin: 0 auto;
        }
        textarea {
            border-color: $round-border;
            font-size: 15px;
            padding: 17px 25px;
            margin-bottom: 15px;
            height: inherit;
        }
    }
    .tab-content.nav-material {
        p {
            margin-top: -8px;
            margin-bottom: -8px;
            line-height: 2;
            letter-spacing: 0.05em;
        }
        .media {
            margin-top: 20px;
        }
    }
    .title {
        padding-right: 45px;
        color: $theme-deafult;
        padding-bottom: 20px;
    }
    .theme-slider {
        .slick-arrow {
            top: -45px;
            height: auto;
            :before {
                color: $black;
                font-size: 18px;
            }
        }
    }
    .product-box {
        position: relative;
        margin: 5px;
        &:hover {
            box-shadow: 0 0 12px 0 #dddddd;
            .lbl-1 {
                opacity: 1;
                transition: all 0.3s ease;
            }
            .lbl-2 {
                opacity: 1;
                animation: flipInY 1000ms ease-in-out;
            }
            .color-variant li {
                opacity: 1 !important;
                animation: fadeInUp 500ms ease-in-out;
            }
        }
        .img-block {
            min-height: unset;
        }
        .cart-info {
            position: absolute;
            padding: 10px 0;
            top: 25%;
            right: 15px;
            width: 40px;
            margin-right: 0;
            i {
                padding-right: 0;
            }
            a,
            button {
                color: $font-color;
                transition: all 0.3s ease;
                background-color: $white;
                height: 35px;
                width: 35px;
                margin: 7px 0;
                border-radius: 100%;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 0;
                box-shadow: 0 0 12px 0 $round-border;
                :hover {
                    transition: all 0.3s ease;
                    color: $theme-deafult;
                }
            }
        }
        .lbl-1 {
            background-color: $theme-deafult;
            padding: 2px 20px 2px 10px;
            display: inline-block;
            text-align: center;
            color: #ffffff;
            position: absolute;
            left: 0;
            top: 15px;
            font-size: 14px;
            line-height: 1.5;
            opacity: 0;
            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 0;
                height: 0;
                border-top: 12px solid $theme-deafult;
                border-bottom: 13px solid $theme-deafult;
                border-right: 7px solid $white;
            }
        }
        .lbl-2 {
            font-size: 14px;
            top: 15px;
            position: absolute;
            right: 10px;
            color: #333333;
            font-weight: 600;
            text-transform: capitalize;
            opacity: 0;
        }
        a {
            color: #0072bb;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 1px;
        }
        .color-variant {
            position: absolute;
            top: -35px;
            width: 100%;
        }
        .slick-slide img {
            display: block;
        }
        .product-details {
            position: relative;
            .color-variant {
                position: absolute;
                top: -35px;
                width: 100%;
                li {
                    opacity: 0;
                    display: inline-block;
                    height: 15px;
                    width: 15px;
                    border-radius: 100%;
                    margin: 0 3px;
                    transition: all 0.3s ease;
                    cursor: pointer;
                }
            }
            h6 {
                color: #333333;
                font-weight: 600;
                letter-spacing: 1px;
                text-transform: capitalize;
            }
            .price {
                padding-bottom: 10px;
                font-size: 16px;
                color: $theme-deafult;
            }
        }
    }
}
.product-full-tab {
    padding-top: 70px;
}
.product-load-more {
    .col-grid-box {
        display: none;
    }
}
.load-more-sec {
    margin-top: 40px;
    text-align: center;
    a {
        font-size: 18px;
        text-transform: uppercase;
        display: block;
        padding: 10px 0;
        border-top: 1px solid $round-border;
        border-bottom: 1px solid $round-border;
        width: 100%;
    }
}
// Quick-view //
.quick-view {
    width: 100%;
    max-width: 1080px;
    max-height: 600px;
    position: relative;
}
// Check-out page //
.checkout_class {
    text-transform: capitalize;
}
.checkout-page {
    .checkout-title {
        margin-bottom: 25px;
        h3 {
            color: $grey4;
            font-weight: 700;
        }
    }
    .checkout-form {
        .checkout-details {
            position: relative;
            background-color: $grey-lighter;
            border: 1px solid $round-border;
            padding: 40px;
        }
        .check-out {
            .form-group {
                &:last-child {
                    margin-bottom: -5px;
                    label {
                        margin-bottom: -5px;
                    }
                }
            }
        }
        .form-group {
            position: relative;
            margin-bottom: 25px;
            h3 {
                color: $grey4;
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 30px;
            }
            .field-label {
                line-height: 24px;
                text-transform: capitalize;
                color: #333333;
                margin-bottom: 10px;
                font-weight: 700;
                span {
                    font-size: 16px;
                    color: $dark-font;
                    font-weight: 600;
                }
            }
            label {
                color: $grey;
            }
        }
        select {
            cursor: pointer;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            -moz-appearance: none;
            background: url('#{$assetspath}/dropdown.png') no-repeat 95%;
        }
        input {
            &[type='text'],
            &[type='email'],
            &[type='password'],
            &[type='tel'],
            &[type='number'],
            &[type='url'] {
                width: 100%;
                padding: 0 22px;
                height: 45px;
                border: 1px solid #dddddd;
            }
        }
        select,
        textarea {
            width: 100%;
            padding: 0 22px;
            height: 45px;
            border: 1px solid #dddddd;
        }
    }
    .check-box {
        line-height: 24px;
        font-size: 14px;
        font-weight: normal;
        padding-top: 5px;
        label {
            position: relative;
            top: -1px;
            font-weight: normal;
            padding: 0;
            font-size: 16px;
            cursor: pointer;
            color: $font-color;
        }
    }
    .lower-content {
        margin-top: 30px;
        .order-column {
            margin-bottom: 40px;
        }
    }
}
.order-box {
    position: relative;
    margin-bottom: 50px;
    .title-box {
        position: relative;
        padding-bottom: 25px;
        color: $grey4;
        font-weight: 600;
        font-size: 22px;
        border-bottom: 1px solid #ededed;
        margin-bottom: 20px;
        span {
            position: relative;
            width: 35%;
            float: right;
            line-height: 1.2em;
        }
    }
    .qty {
        position: relative;
        border-bottom: 1px solid #ededed;
        margin-bottom: 30px;
        li {
            position: relative;
            display: block;
            font-size: 15px;
            color: $grey4;
            line-height: 20px;
            margin-bottom: 20px;
            span {
                float: right;
                font-size: 18px;
                line-height: 20px;
                color: #232323;
                font-weight: 400;
                width: 35%;
            }
        }
    }
    .sub-total {
        position: relative;
        border-bottom: 1px solid $round-border;
        margin-bottom: 30px;
        li {
            position: relative;
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            color: $font-color;
            line-height: 20px;
            margin-bottom: 20px;
            width: 100%;
            .count {
                position: relative;
                font-size: 18px;
                line-height: 20px;
                color: $theme-deafult;
                font-weight: 400;
                width: 35%;
                float: right;
            }
        }
        .shopping-option {
            label {
                position: relative;
                font-size: 16px;
                line-height: 32px;
                padding-left: 10px;
                color: $grey6;
            }
        }
        .shipping {
            width: 35%;
            float: right;
        }
    }
    .total {
        position: relative;
        margin-bottom: 40px;
        li {
            position: relative;
            display: block;
            font-weight: 400;
            color: $font-color;
            line-height: 20px;
            margin-bottom: 10px;
            font-size: 18px;
            .count {
                position: relative;
                font-size: 18px;
                line-height: 20px;
                color: $theme-deafult;
                font-weight: 400;
            }
            span {
                float: right;
                font-size: 15px;
                line-height: 20px;
                color: $grey4;
                font-weight: 400;
                width: 35%;
                display: block;
            }
        }
    }
}
.payment-box {
    position: relative;
    .upper-box {
        position: relative;
    }
    .payment-options {
        position: relative;
        margin-top: 20px;
        margin-bottom: 30px;
        li {
            display: flex;
            margin-bottom: 15px;
            .radio-option {
                position: relative;
                label {
                    position: relative;
                    padding-left: 30px;
                    text-transform: capitalize;
                    color: $grey6;
                    cursor: pointer;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 0;
                }
                input[type='radio'] {
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
                label {
                    .small-text {
                        position: relative;
                        display: none;
                        font-size: 15px;
                        line-height: 25px;
                        font-weight: 300;
                        color: #666666;
                        margin-top: 10px;
                    }
                    img {
                        position: relative;
                        display: block;
                        max-width: 100%;
                        margin-left: -30px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
.stripe-section {
    text-transform: uppercase;
    background-color: #f9f9f9;
    padding: 15px;
    border: 1px solid #dddddd;
    table {
        tbody {
            tr {
                td {
                    &:first-child {
                        padding-right: 15px;
                    }
                }
            }
        }
    }
    h5 {
        font-weight: 700;
    }
    .content {
        h5 {
            color: #4f4f4f;
            font-weight: 400;
        }
        table {
            width: 100%;
            tr {
                border-bottom: 1px solid #f1f1f1;
                td {
                    padding: 8px 0;
                    color: #9a9a9a;
                    &:last-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
.stripe-section {
    .content {
        table {
            tr {
                td {
                    &:first-child {
                        text-align: right;
                    }
                }
            }
        }
    }
}
// Dashboard page
.dashboard-left {
    .block-title h2 {
        font-size: 24px;
        padding-bottom: 20px;
    }
    .block-content {
        border: 1px solid #ddd;
        padding: 15px;
        ul li {
            display: flex;
            transition: all 0.5s ease;
            &.active {
                color: $theme-deafult;
                a {
                    color: $theme-deafult;
                }
            }
            &:before {
                content: '\f105';
                display: inline-block;
                font-family: FontAwesome;
                font-style: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                width: 30px;
                height: 30px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                margin-top: 3px;
            }
            &:hover {
                a {
                    color: #ff0000;
                    transition: all 0.5s ease;
                }
                padding-left: 10px;
                color: #ff0000;
                transition: all 0.5s ease;
            }
        }
    }
}
.padding-cls {
    padding-left: 50px;
    padding-right: 50px;
}
.dashboard-right {
    p {
        color: #5f5f5f;
        line-height: 20px;
    }
    .dashboard {
        border: 1px solid #ddd;
        padding: 30px;
        .page-title h2 {
            font-size: 22px;
            margin-bottom: 15px;
        }
        .welcome-msg {
            p {
                margin-bottom: 0;
            }
        }
    }
}
.dashboard {
    .box-head {
        h2 {
            font-size: 22px;
            margin: 20px 0 0;
            text-transform: capitalize;
            color: #333;
        }
    }
    .box {
        a {
            color: $theme-deafult;
        }
        h6 {
            margin-bottom: 0;
        }
        .box-title {
            border-bottom: 1px solid #ddd;
            display: inline-block;
            margin-bottom: 5px;
            padding: 12px 0;
            position: relative;
            width: 100%;
            h3 {
                font-size: 16px;
                margin: 0;
                text-transform: capitalize;
                color: #333;
            }
            > a {
                position: absolute;
                right: 0;
                top: 12px;
                color: $theme-deafult;
            }
        }
        address {
            margin-bottom: 0;
        }
    }
}
.box-content {
    h6 {
        font-size: 14px;
        line-height: 25px !important;
        margin-bottom: 0;
    }
    a {
        color: $theme-deafult;
    }
}
.account-sidebar {
    display: none;
    cursor: pointer;
    background-color: $theme-deafult;
    color: $white;
    width: 30%;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    margin-bottom: 20px;
    padding: 5px;
}
// Order-success //
.success-text {
    text-align: center;
    i {
        font-size: 50px;
        color: #4ead4e;
    }
    h2 {
        margin-top: 10px;
        margin-bottom: 15px;
    }
    p {
        font-size: 18px;
        text-transform: capitalize;
    }
}
.order-success-sec {
    h4 {
        font-weight: 700;
        text-transform: capitalize;
    }
    .order-detail {
        li {
            display: flex;
            text-transform: capitalize;
            line-height: 24px;
            font-size: 16px;
        }
    }
    .payment-mode {
        p {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 0;
        }
    }
}
.delivery-sec {
    padding: 30px;
    text-align: center;
    margin-top: 20px;
    background-color: #f9f9f9;
    h2,
    h3 {
        color: $black;
        text-transform: capitalize;
    }
}
.product-order {
    h3 {
        font-weight: 700;
        text-transform: uppercase;
        color: $black;
    }
    .product-order-detail {
        margin-top: 15px;
        img {
            height: 130px;
        }
        .order_detail {
            display: flex;
            align-items: center;
            h4 {
                margin-bottom: 5px;
                text-transform: capitalize;
                font-weight: 700;
            }
            h5 {
                text-transform: capitalize;
                margin-bottom: 0;
            }
        }
    }
    .total-sec {
        border-top: 1px solid $round-border;
        border-bottom: 1px solid $round-border;
        margin: 15px 0;
        padding: 15px 0;
        ul {
            li {
                font-size: 18px;
                display: block;
                text-transform: capitalize;
                span {
                    float: right;
                }
            }
        }
    }
    .final-total {
        h3 {
            display: block;
            margin-bottom: 0;
            span {
                float: right;
            }
        }
    }
}
// compare //
.compare-padding {
    padding-bottom: 50px;
    h2 {
        margin-bottom: 20px;
    }
}
.compare-lable {
    position: absolute;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
    top: 20px;
    left: 16px;
    letter-spacing: 0.1em;
    z-index: 1;
}
.compare-page {
    .table-wrapper {
        padding-bottom: 0;
        .table {
            border: 1px solid $white-dark;
            text-transform: capitalize;
            color: $dark-font;
            .product-name {
                width: 15%;
            }
            .featured-image {
                width: 185px;
            }
            td {
                border-top: none;
            }
            thead {
                .th-compare {
                    td {
                        font-size: 14px;
                        font-weight: 700;
                        background: $top-header;
                        border-right: 1px solid #eee;
                    }
                    th {
                        text-align: left;
                        border-bottom: 1px solid $white-dark;
                        border-right: 1px solid $white-dark;
                        .remove-compare {
                            float: left;
                            border: none;
                            background: transparent;
                            padding: 0;
                            font-size: 14px;
                            font-weight: 500;
                        }
                    }
                }
            }
            tbody {
                tr {
                    th {
                        background: $top-header;
                    }
                    td {
                        border-right: 1px solid #eee;
                        position: relative;
                    }
                    th,
                    td {
                        padding: 15px;
                        vertical-align: top;
                        border-bottom: 1px solid #eee;
                        border-top: 1px solid #eee;
                        border-right: 1px solid #eee;
                    }
                    .grid-link__title {
                        font-size: 15px;
                    }
                    .product_price {
                        margin: 15px 0;
                        span.money {
                            padding-left: 5px;
                        }
                    }
                    p {
                        color: $dark-font;
                        font-size: 13px;
                        line-height: 20px;
                        margin: 0;
                    }
                    .add-to-cart,
                    .select-option-cls {
                        padding: 6px 10px;
                    }
                }
            }
        }
    }
}
.compare-section {
    .compare-part {
        position: relative;
        .close-btn {
            background-color: transparent;
            border: none;
            font-size: 25px;
            position: absolute;
            top: 0;
            right: 0;
        }
        .img-secton {
            padding: 0 15px 15px;
            img {
                width: 50%;
                margin: 0 auto;
                padding-top: 25px;
            }
            a {
                h5 {
                    margin-bottom: 0;
                    text-transform: capitalize;
                    margin-top: 10px;
                }
            }
            h5 {
                margin-bottom: 0;
                line-height: 1.2;
            }
        }
        .detail-part {
            .title-detail {
                background-color: #f7f8fa;
                text-align: center;
                padding: 8px 0;
                border-top: 1px solid #dbdbdb;
                border-bottom: 1px solid #dbdbdb;
                h5 {
                    margin-bottom: 0;
                    text-transform: uppercase;
                }
            }
            .inner-detail {
                padding: 15px;
                p {
                    margin-bottom: 0;
                    line-height: 1.2;
                    letter-spacing: 0.05em;
                }
            }
        }
        .btn-part {
            text-align: center;
            padding: 15px;
            border-top: 1px solid #ddd;
            .btn-solid {
                padding: 5px 10px;
            }
        }
    }
    .slick-slide {
        > div {
            border: 1px solid #ddd;
            border-left: none;
        }
        &:first-child {
            border-left: 1px solid #ddd;
        }
    }
}
/* =====================
    24.RTL CSS start
========================== */
body.rtl {
    direction: rtl;
}
.rtl {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        text-align: right;
    }
    .slick-slider {
        direction: ltr;
    }
    .slick-slide {
        float: left;
    }
    ul {
        -webkit-padding-start: 0;
    }
    .mr-3 {
        margin-right: 0 !important;
        margin-left: 5px;
    }
    .offset-md-3,
    .offset-lg-2,
    .offset-xl-3,
    .offset-lg-3,
    .offset-md-2 {
        margin: 0 auto;
    }
    .title1,
    .title2,
    .title3,
    .title4 {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            text-align: center;
        }
    }
    .setting-box {
        direction: ltr;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
            text-align: left;
        }
    }
    .brand-logo {
        img {
            transform: scaleX(-1);
        }
    }
    [dir='rtl'] .slick-slide {
        float: left;
    }
    .effect-cls {
        &:before,
        &:after {
            right: 0;
        }
    }
    .game-product {
        .product-box {
            &:hover {
                .cart-info {
                    a {
                        &:nth-child(1) {
                            i {
                                animation: fadeInLeft 300ms ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
    .breadcrumb-section {
        .breadcrumb {
            padding-left: 0;
        }
    }
    .cart-section,
    .wishlist-section {
        .wishlist-buttons {
            text-align: left;
        }
    }
    .category-block {
        .category-details {
            h6 {
                text-align: center;
            }
        }
    }
    .offer-slider {
        .slick-slide {
            > div {
                direction: rtl;
            }
        }
    }
    .tools-parallax-product {
        .tools-description {
            .tools-form {
                .search-button {
                    text-align: right;
                }
            }
        }
    }
    .typo-content {
        &.typo-buttons {
            text-align: right;
        }
    }
    .typography_section {
        .typography-box {
            .headings {
                text-align: right;
            }
            ul,
            ol,
            dl {
                text-align: right;
            }
            ol {
                padding-inline-start: 0px;
            }
            .typo-content {
                &.input_button {
                    text-align: right;
                }
            }
        }
    }
    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                h3,
                h4 {
                    text-align: center;
                }
            }
        }
    }
    .absolute-product {
        .product-box {
            .product-detail {
                .rating,
                h4,
                h6,
                .color-variant {
                    text-align: center;
                }
            }
        }
    }
    .product-box,
    .product-wrap {
        .img-wrapper {
            .front {
                text-align: right;
                left: unset;
                right: 0;
            }
            .back {
                transform: translateX(100px);
                text-align: right;
                left: unset;
                right: 0;
            }
        }
        &:hover {
            .img-wrapper {
                .back {
                    transform: translateX(0);
                }
            }
        }
    }
    .box-product {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            padding-right: 0;
                            padding-left: 15px;
                            img {
                                padding: 0 0 0 15px;
                            }
                        }
                        .cart-bottom {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
    header {
        &.header-tools {
            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 25px 0 25px 45px;
                    }
                }
            }
        }
    }
    .form_search {
        button {
            left: 5px;
            right: unset;
        }
    }
    .j-box {
        .product-box {
            .product-detail {
                padding-left: 0;
                padding-right: 15px;
            }
        }
    }
    .gym-product {
        .product-box {
            .product-detail {
                h6,
                h4 {
                    text-align: center;
                }
            }
        }
    }
    .search-overlay {
        > div {
            .closebtn {
                right: unset;
                left: 25px;
            }
        }
    }
    .home-slider,
    .center-slider {
        .text-center {
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: center !important;
            }
        }
        .text-left {
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: left;
            }
        }
        .text-right {
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: right;
            }
        }
        .p-left {
            .slider-contain {
                justify-content: flex-start;
            }
        }
        .p-right {
            .slider-contain {
                justify-content: flex-end;
            }
        }
        .slider-contain {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: left;
            }
            .btn-solid,
            .btn-outline {
                float: inherit;
            }
        }
        .slick-next {
            right: unset;
            left: 1px;
            &:before {
                transform: rotate(180deg);
            }
        }
        .slick-prev {
            left: unset;
            right: 1px;
            &:before {
                transform: rotate(180deg);
            }
        }
        &:hover {
            .slick-next {
                right: unset;
                left: 100px;
            }
            .slick-prev {
                left: unset;
                right: 100px;
            }
        }
    }
    .pwd-page {
        h2 {
            text-align: center;
        }
    }
    .product-box,
    .product-wrap {
        .color-variant {
            -webkit-padding-start: 0;
        }
        .product-info {
            h4,
            h6 {
                text-align: center;
            }
        }
        .product-detail {
            .rating {
                text-align: right;
            }
            .color-variant {
                text-align: right;
                padding: 0;
                padding-top: 15px;
                li {
                    &:first-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .img-block {
            .lable-wrapper {
                .lable1 {
                    border-bottom-right-radius: 25px;
                    border-top-right-radius: 25px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
                .lable2 {
                    border-bottom-left-radius: 25px;
                    border-top-left-radius: 25px;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
        .img-wrapper {
            .lable-block {
                .lable3 {
                    left: unset;
                    right: 7px;
                }
                .lable4 {
                    left: 7px;
                    right: unset;
                }
            }
        }
        .cart-detail {
            left: 20px;
            right: unset;
        }
        .cart-info {
            &.cart-wrap {
                left: 10px;
                right: unset;
            }
        }
        &:hover {
            .cart-wrap {
                button {
                    animation: fadeInLeft 300ms ease-in-out;
                }
                a {
                    &:nth-child(2) {
                        i {
                            animation: fadeInLeft 500ms ease-in-out;
                        }
                    }
                    &:nth-child(3) {
                        i {
                            animation: fadeInLeft 700ms ease-in-out;
                        }
                    }
                    &:nth-child(4) {
                        i {
                            animation: fadeInLeft 1000ms ease-in-out;
                        }
                    }
                }
            }
            .cart-detail {
                button {
                    animation: fadeInLeft 300ms ease-in-out;
                }
                a {
                    &:nth-child(2) {
                        i {
                            animation: fadeInLeft 500ms ease-in-out;
                        }
                    }
                    &:nth-child(3) {
                        i {
                            animation: fadeInLeft 700ms ease-in-out;
                        }
                    }
                    &:nth-child(4) {
                        i {
                            animation: fadeInLeft 1000ms ease-in-out;
                        }
                    }
                }
            }
        }
    }
    .footer-light {
        .subscribe {
            border-right: none;
            border-left: 1px solid $round-border;
            h4 {
                text-align: right;
            }
        }
    }
    .subscribe {
        h4 {
            text-align: center;
            i {
                padding-left: 5px;
                padding-right: 0;
            }
        }
    }
    .service-block {
        svg {
            margin-left: 12px;
            margin-right: 0;
        }
        + .service-block {
            border-right: 1px solid lighten($black, 86.5%);
            border-left: none;
        }
    }
    .service-block1 {
        h4,
        p {
            text-align: center;
        }
    }
    .blog-details {
        h4,
        h6 {
            text-align: center;
        }
        a {
            p {
                text-align: center;
            }
        }
    }
    .instagram {
        h2 {
            text-align: center;
        }
    }
    .category-block {
        .category-details {
            h5 {
                text-align: center;
            }
        }
    }
    .category-bg {
        .contain-block {
            h6 {
                text-align: center;
            }
        }
    }
    .about-text {
        p {
            text-align: center;
        }
    }
    .beauty-about {
        .text-center {
            text-align: left !important;
        }
    }
    .collection-banner {
        &.p-left {
            .contain-banner {
                justify-content: flex-end;
            }
        }
        &.p-right {
            .contain-banner {
                justify-content: flex-start;
            }
        }
        &.p-center {
            .contain-banner {
                justify-content: center;
            }
        }
        &.text-center {
            .contain-banner {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    text-align: center;
                }
            }
        }
        &.text-left {
            .contain-banner {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    text-align: left;
                }
            }
        }
        &.text-right {
            .contain-banner {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    text-align: right;
                }
            }
        }
        .contain-banner {
            justify-content: flex-end;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: left;
            }
        }
    }
    .full-banner {
        .banner-contain {
            .btn-solid,
            .btn-outline {
                // float: right;
            }
        }
        &.p-left {
            .banner-contain {
                float: left;
            }
        }
        &.p-right {
            .banner-contain {
                float: right;
            }
        }
        &.p-center {
            .banner-contain {
                left: 0;
                right: 0;
                margin: 0 auto;
                h3,
                h4 {
                    text-align: center;
                }
                .btn-solid,
                .btn-outline {
                    float: unset;
                }
            }
        }
        &.text-center {
            .banner-contain {
                h2,
                h3,
                h4 {
                    text-align: center;
                }
            }
        }
        &.text-left {
            .banner-contain {
                h2,
                h3,
                h4 {
                    text-align: left;
                }
            }
        }
        &.text-right {
            .banner-contain {
                h2,
                h3,
                h4 {
                    text-align: right;
                }
            }
        }
    }
    .theme-card {
        .slick-prev {
            left: 0;
            right: unset;
        }
        .slick-next {
            right: unset;
            left: 25px;
        }
        .offer-slider {
            img {
                padding: 15px 0 15px 15px;
            }
            .media {
                .media-body {
                    a {
                        h6 {
                            margin-left: 61px;
                            margin-right: 0;
                        }
                    }
                    .rating {
                        text-align: right;
                        i {
                            padding-left: 5px;
                        }
                    }
                }
            }
        }
        &.card-border {
            h5 {
                padding-left: 0;
                padding-right: 50px;
            }
            .slick-prev {
                left: 30px;
            }
            .slick-next {
                left: 50px;
            }
            .offer-slider {
                img {
                    padding: 15px 30px 15px 15px;
                }
                .slick-slide {
                    > div {
                        direction: rtl;
                    }
                }
            }
        }
    }
    .background {
        .contain-bg {
            h4 {
                text-align: center;
            }
        }
    }
    .center-slider {
        &:hover {
            .slick-next {
                left: 20px;
                right: unset;
            }
            .slick-prev {
                left: unset;
                right: 20px;
            }
        }
    }
    .theme-tab {
        .tab-title {
            -webkit-padding-start: 0;
        }
        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        img {
                            padding: 15px;
                        }
                        .media {
                            .media-body {
                                .rating {
                                    text-align: right;
                                }
                                a {
                                    h6 {
                                        margin-left: 35px;
                                        margin-right: 0;
                                    }
                                }
                                .color-variant {
                                    text-align: right;
                                    -webkit-padding-start: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .beauty-about {
        .about-text {
            p {
                text-align: right;
            }
        }
        .service-block1 {
            h5 {
                text-align: center;
            }
        }
    }
    .timer {
        padding-left: 0;
        padding-right: 40px;
        span {
            .padding-l {
                padding-left: 0;
                padding-right: 22px;
            }
        }
    }
    .category-m {
        .category-wrapper {
            h4 {
                text-align: center;
            }
            .category-link {
                -webkit-padding-start: 0;
            }
        }
    }
    .full-box {
        .theme-card {
            .offer-slider {
                .product-box2 {
                    .media {
                        .media-body {
                            a {
                                h6 {
                                    margin-right: 0;
                                    margin-left: 20px;
                                }
                            }
                            .color-variant {
                                text-align: right;
                                -webkit-padding-start: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .compare-page {
        .table-wrapper {
            .table {
                tbody {
                    tr {
                        th,
                        td {
                            text-align: right;
                        }
                    }
                }
                thead {
                    .th-compare {
                        td {
                            text-align: right;
                        }
                        th {
                            .remove-compare {
                                float: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .product-wrap {
        .product-detail,
        .product-info {
            .rating,
            h4,
            h6,
            .color-variant {
                text-align: center;
            }
            .color-variant {
                li {
                    margin: 0 2px;
                }
            }
        }
    }
    .breadcrumb-item {
        + .breadcrumb-item {
            padding-right: 0.5rem;
            padding-left: 0;
            &:before {
                padding-right: 0;
                padding-left: 0.5rem;
            }
        }
    }
    .top-header {
        .header-dropdown {
            li {
                &:first-child {
                    padding-right: 25px;
                    padding-left: 0;
                }
            }
        }
    }
    .main-menu {
        .menu-right {
            .icon-nav {
                .onhover-div {
                    .show-div {
                        &.shopping-cart {
                            li {
                                .media {
                                    img {
                                        &.mr-3 {
                                            margin-right: unset !important;
                                            margin-left: 1rem !important;
                                        }
                                    }
                                }
                                .close-circle {
                                    right: unset;
                                    left: 0;
                                }
                            }
                        }
                    }
                }
            }
            .main-navbar {
                .nav-menu {
                    li {
                        .mega-menu-container {
                            a {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
        .menu-left {
            .navbar {
                i {
                    float: unset;
                }
                .sidenav {
                    .sidebar-menu {
                        li {
                            a {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }
    .main-nav-center {
        .toggle-nav {
            left: 175px;
            right: 0;
        }
    }
    .testimonial {
        .testimonial-slider {
            .slick-track {
                .slick-slide {
                    .media {
                        h5,
                        h6 {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .sitemap_page {
        ul {
            text-align: right;
        }
    }
    .team {
        .slick-next {
            right: unset;
            left: 20px;
        }
        .slick-prev {
            right: unset;
            left: 0;
        }
        h4,
        h6 {
            text-align: center;
        }
    }
    .blog-page {
        .blog-sidebar {
            .theme-card {
                .recent-blog {
                    text-align: right;
                    li {
                        .media {
                            img {
                                margin-right: 0;
                                margin-left: 10px;
                            }
                        }
                    }
                }
                .popular-blog {
                    li {
                        .blog-date {
                            margin: 0 0 0 15px;
                        }
                    }
                }
            }
        }
        .blog-media {
            .blog-right {
                ul {
                    text-align: right;
                    li {
                        + li {
                            padding-right: 15px;
                            margin-right: 15px;
                            border-right: 1px solid $round-border;
                            padding-left: 0;
                            margin-left: 0;
                            border-left: none;
                        }
                    }
                }
            }
        }
    }
    .blog-detail-page {
        .blog-detail {
            .post-social {
                text-align: right;
                li {
                    + li {
                        padding-right: 15px;
                        margin-right: 15px;
                        border-right: 1px solid $round-border;
                        padding-left: 0;
                        margin-left: 0;
                        border-left: none;
                    }
                }
            }
        }
        .blog-advance {
            ul {
                text-align: right;
            }
        }
        .comment-section {
            li {
                img {
                    margin-right: 0;
                    margin-left: 20px;
                }
            }
        }
        .blog-contact {
            .theme-form {
                label,
                .btn-solid,
                .btn-outline {
                    float: right;
                }
            }
        }
    }
    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {
                    h2,
                    p {
                        text-align: center;
                    }
                }
            }
        }
        tfoot {
            tr {
                td {
                    &:first-child {
                        padding-right: 0;
                        padding-left: 10px;
                        text-align: left;
                    }
                }
            }
        }
        .cart-buttons {
            > div {
                &:last-child {
                    text-align: left;
                    padding-left: 59px;
                    padding-right: 0;
                }
                &:first-child {
                    .btn-solid,
                    .btn-outline {
                        float: right;
                    }
                }
            }
        }
    }
    .collection-product-wrapper {
        .product-top-filter {
            .product-filter-content {
                .collection-view {
                    ul {
                        li {
                            &:first-child {
                                margin-right: 0;
                                margin-left: 14px;
                            }
                        }
                    }
                }
                .search-count {
                    border-right: none;
                    border-left: 1px solid $round-border;
                    padding-right: 0;
                    padding-left: 20px;
                }
                .product-page-per-view {
                    select {
                        border-right: none;
                        border-left: 1px solid $round-border;
                        padding: 20px;
                        background: url('#{$assetspath}/dropdown.png') no-repeat 5%;
                    }
                }
                .collection-grid-view {
                    border-right: none;
                    border-left: 1px solid $round-border;
                }
                .product-page-filter {
                    select {
                        padding: 20px;
                        background: url('#{$assetspath}/dropdown.png') no-repeat 5%;
                    }
                }
            }
            .popup-filter {
                .open-popup {
                    left: unset;
                    right: 0;
                }
                .collection-grid-view,
                .product-page-per-view,
                .sidebar-popup,
                .search-count {
                    border-right: none;
                    border-left: 1px solid $round-border;
                    select {
                        border-right: none;
                    }
                }
                .collection-view {
                    ul {
                        float: right;
                        li {
                            &:first-child {
                                margin-right: 0;
                                margin-left: 14px;
                            }
                        }
                    }
                }
                .product-page-per-view {
                    select {
                        background: url('#{$assetspath}/dropdown.png') no-repeat 5%;
                    }
                }
                .product-page-filter {
                    select {
                        background: url('#{$assetspath}/dropdown.png') no-repeat 5%;
                    }
                }
                .sidebar-popup {
                    text-align: right;
                    background: url('#{$assetspath}/dropdown.png') no-repeat 5%;
                }
            }
            .product-filter-content,
            .popup-filter {
                .collection-view {
                    text-align: right;
                }
            }
        }
    }
    .qty-box {
        .input-group {
            span {
                button {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .product-pagination {
        .product-search-count-bottom {
            padding-right: 0;
            padding-left: 15px;
        }
        .pagination {
            .page-item {
                a {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .custom-control-label {
        &:after {
            right: -1.5rem;
            left: unset;
        }
    }
    .contact-page {
        .theme-form {
            label {
                float: right;
            }
            .btn-solid,
            .btn-outline {
                float: right;
            }
        }
        .contact-right {
            ul {
                li {
                    padding-right: 150px;
                    padding-left: 0;
                    .contact-icon {
                        left: unset;
                        border-left: 1px solid $round-border;
                        border-right: 0;
                        right: 0;
                        h6 {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
    .faq-section {
        .accordion {
            &.theme-accordion {
                .card {
                    .card-header {
                        button {
                            float: right;
                            text-align: right;
                            &:before {
                                right: unset;
                                left: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .login-page {
        .theme-card {
            text-align: right;
            .theme-form {
                label {
                    float: right;
                }
            }
        }
    }
    .register-page {
        .theme-card {
            .theme-form {
                label {
                    float: right;
                }
            }
        }
    }
    .image-swatch {
        text-align: right;
    }
    .product-right {
        .color-variant {
            text-align: center;
        }
        .rating {
            text-align: right;
        }
        &.product-form-box {
            h4,
            h3,
            h6 {
                text-align: center;
            }
        }
        .product-icon {
            .product-social {
                li {
                    padding-right: 0;
                    padding-left: 30px;
                }
            }
            .wishlist-btn {
                i {
                    border-left: none;
                    border-right: 1px solid $round-border;
                    padding-left: 0;
                    margin-left: 0;
                    padding-right: 10px;
                    margin-right: 5px;
                }
                span {
                    padding-left: 0;
                    padding-right: 10px;
                }
            }
        }
        .payment-card-bottom {
            text-align: right;
            ul {
                li {
                    padding-right: 0;
                    padding-left: 10px;
                }
            }
        }
    }
    .single-product-tables {
        text-align: right;
        &.detail-section {
            text-align: right;
        }
    }
    .tab-product,
    .product-full-tab {
        .theme-form {
            input {
                text-align: right;
            }
        }
        .tab-content {
            .theme-form {
                .media {
                    .ml-3 {
                        margin-left: 0 !important;
                        margin-right: 1rem !important;
                    }
                }
            }
        }
    }
    .theme-form {
        text-align: right;
    }
    .collection {
        .collection-block {
            .collection-content {
                h3,
                h4,
                p {
                    text-align: center;
                }
            }
        }
    }
    .product-right-slick,
    .product-slick {
        .slick-next {
            right: unset;
            left: 1px;
            transform: rotate(180deg);
        }
        .slick-prev {
            left: unset;
            right: 1px;
            transform: rotate(180deg);
        }
        &:hover {
            .slick-next {
                right: unset;
                left: 20px;
            }
            .slick-prev {
                left: unset;
                right: 20px;
            }
        }
    }
    .collection-wrapper {
        .product-right {
            .product-buttons {
                text-align: center;
            }
            .size-box {
                text-align: center;
                ul {
                    li {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }
            .product-description {
                h6 {
                    span {
                        float: left;
                    }
                }
            }
        }
    }
    .collection-mobile-back {
        span {
            i {
                float: left;
            }
        }
    }
    .modal-header {
        .close {
            padding: unset;
            margin: unset;
        }
    }
    .collection-collapse-block {
        .collapse-block-title {
            &:after {
                left: -3px;
                right: unset;
            }
        }
    }
    .collection-filter-block {
        .product-service {
            .media {
                .media-body {
                    padding-right: 10px;
                    padding-left: 0;
                }
            }
        }
    }
    .collection-sidebar-banner {
        text-align: right;
    }
    .tab-border {
        .nav-border {
            border-right: 0;
            border-left: 1px solid $round-border;
        }
    }
    .product-pagination {
        .pagination {
            .page-item {
                &.active {
                    a {
                        border-right: none;
                    }
                }
            }
        }
    }
    .custom-control {
        padding-left: 0;
        padding-right: 1.5rem;
    }
    .collection-collapse-block {
        .collection-collapse-block-content {
            .color-selector {
                ul {
                    text-align: right;
                }
            }
            .collection-brand-filter {
                .collection-filter-checkbox {
                    text-align: right;
                }
            }
        }
    }
    .theme-modal {
        .modal-dialog {
            .quick-view-modal {
                button {
                    &.close {
                        left: 10px;
                        right: 0;
                    }
                }
            }
            .modal-content {
                .modal-body {
                    .modal-bg {
                        .close {
                            left: 17px;
                            right: unset;
                        }
                    }
                }
            }
        }
    }
    .custom-control-label {
        &:before {
            right: -1.5rem;
            left: 0;
        }
    }
    .product-wrapper-grid {
        &.list-view {
            .product-box {
                .product-detail {
                    padding-right: 15px;
                    padding-left: 0;
                }
            }
        }
    }
    .top-header {
        .header-contact {
            text-align: right;
            li {
                padding-right: 0;
                padding-left: 25px;
                i {
                    padding-right: 0;
                    padding-left: 10px;
                }
            }
        }
        .header-dropdown {
            text-align: left;
            .onhover-show-div {
                li {
                    padding-right: 0;
                }
            }
            li {
                i {
                    padding-right: 0;
                    padding-left: 5px;
                }
            }
        }
    }
    .main-menu {
        .menu-right {
            float: left;
        }
    }
    .search-overlay {
        > div {
            .overlay-content {
                button {
                    right: unset;
                    left: 10px;
                }
            }
        }
    }
    .footer-theme {
        .sub-title {
            text-align: right;
            li {
                a {
                    &:before {
                        right: 0;
                    }
                }
            }
            .contact-list {
                li {
                    padding-left: 0;
                    padding-right: 25px;
                }
                i {
                    left: unset;
                    right: 0;
                }
            }
        }
    }
    .footer-logo {
        text-align: right;
    }
    .footer-social,
    .social-white {
        li {
            padding-right: 0;
            padding-left: 45px;
        }
    }
    .sub-footer {
        .payment-card-bottom {
            li {
                padding-left: 0;
                padding-right: 15px;
            }
        }
    }
    .footer-theme2 {
        .footer-block,
        .footer-title {
            h4,
            h2 {
                text-align: center;
            }
        }
        .footer-logo {
            text-align: center;
        }
        .social-white {
            li {
                padding-left: 22px;
                padding-right: 22px;
            }
        }
    }
    .dark-layout {
        h2 {
            text-align: center;
        }
        .footer-contant {
            p {
                text-align: center;
            }
        }
    }
    footer {
        &.footer-black {
            &.footer-light {
                .light-layout {
                    &.upside {
                        .subscribe {
                            border-left: 1px solid rgba(0, 0, 0, 0.8);
                            border-right: none;
                        }
                    }
                }
            }
        }
    }
    .subscribe-form {
        &.classic-form {
            .form-control {
                margin-left: 0;
                margin-right: 15px;
            }
        }
    }
    .rtl-text {
        .product-right {
            .color-variant,
            .size-box,
            .product-buttons,
            .border-product {
                text-align: right;
            }
        }
    }
    .search-block {
        .form-header {
            .input-group {
                i {
                    padding-right: 0;
                    padding-left: 10px;
                }
            }
        }
    }
    .payment-box {
        .payment-options {
            li {
                .radio-option {
                    text-align: right;
                    label {
                        padding-right: 30px;
                        padding-left: 0;
                    }
                    input[type='radio'] {
                        right: 0;
                        left: unset;
                    }
                }
            }
        }
    }
    .order-box {
        .total {
            li {
                .count {
                    text-align: right;
                }
            }
        }
        .sub-total {
            .shipping {
                text-align: right;
            }
            .shopping-option {
                label {
                    padding-left: 0;
                    padding-right: 10px;
                }
            }
            li {
                .count {
                    text-align: right;
                }
            }
        }
        .qty {
            li {
                span {
                    text-align: right;
                }
            }
        }
        .title-box {
            span {
                text-align: right;
            }
        }
    }
    .error-section {
        h1,
        h2 {
            text-align: center;
        }
    }
    .dashboard {
        .box {
            .box-title {
                > a {
                    right: unset;
                    left: 0;
                }
            }
            address {
                text-align: right;
            }
        }
    }
    .success-text {
        h2,
        p {
            text-align: center;
        }
    }
    .product-order {
        .product-order-detail {
            text-align: right;
        }
        .final-total {
            h3 {
                span {
                    float: left;
                }
            }
        }
    }
    .delivery-sec {
        h2,
        h3 {
            text-align: center;
        }
    }
    .theme-modal {
        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            &.addtocart {
                                .media {
                                    .media-body {
                                        a {
                                            h6 {
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                                #upsell_product {
                                    .product-box {
                                        .product-detail {
                                            h6,
                                            h4 {
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .product-upsell {
                            h4 {
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .add_to_cart {
        .cart-inner {
            .cart_top {
                .close-cart {
                    margin-right: auto;
                    margin-left: unset;
                }
            }
            .cart_media {
                .close-circle {
                    left: 0;
                    right: unset;
                }
            }
        }
    }
    .payment-box {
        .payment-options {
            li {
                .radio-option {
                    label {
                        img {
                            margin-right: -30px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    }
    .checkout-page {
        .checkout-form {
            select {
                background: url('#{$assetspath}/dropdown.png') no-repeat 3%;
            }
        }
    }
    .compare-section {
        .compare-part {
            .detail-part {
                .title-detail {
                    h5 {
                        text-align: center;
                    }
                }
            }
        }
    }
    .dashboard-left {
        .block-content {
            ul {
                li {
                    &:hover {
                        padding-right: 10px;
                        padding-left: 0;
                    }
                }
            }
        }
    }
    .layout7-product {
        .product-box {
            .details-product {
                right: 15px;
                left: unset;
            }
        }
    }
    .game-product {
        .product-box,
        .product-wrap {
            .product-detail {
                .rating {
                    text-align: center;
                }
                h4,
                h6 {
                    text-align: center;
                }
            }
        }
    }
    .j-box {
        .product-box,
        .product-wrap {
            .cart-info {
                &.cart-wrap {
                    left: 0;
                    right: 0;
                }
            }
        }
        .product-box {
            .cart-info {
                a {
                    border-right: 1px solid $round-border;
                    border-left: none;
                }
            }
        }
    }
    .product-m {
        .slick-slide {
            > div {
                direction: rtl;
            }
        }
    }
    header {
        &.left-header {
            .sidenav {
                right: 0;
                .left-sidebar_center {
                    padding: 40px 30px 40px 0;
                }
                .pixelstrap {
                    li {
                        a {
                            .sub-arrow {
                                left: 7px;
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
            .top-header {
                padding-left: 0;
                padding-right: 300px;
            }
            .search-overlay {
                padding-right: 300px;
                padding-left: 0;
            }
        }
    }
    // .left-sidebar_space {
    // padding-right: 300px;
    // padding-left: 0;
    // }
    .detail-cannabis {
        .detail_section {
            h4,
            p {
                text-align: center;
            }
        }
    }
    .tools-grey {
        .product-box {
            &.product-wrap {
                .cart-info {
                    &.cart-wrap {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
    .tab-left {
        .theme-tab {
            .left-side {
                .tab-title {
                    margin-left: 100px;
                    text-align: right;
                    margin-right: 0;
                }
            }
        }
    }
    .review-page {
        .comnt-sec {
            text-align: right;
            li {
                margin-right: 0;
                margin-left: 15px;
                a {
                    i {
                        margin-right: 0;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .template-password {
        #container {
            #login {
                h2 {
                    text-align: center;
                }
                .theme-form {
                    text-align: center;
                    h3 {
                        text-align: center;
                    }
                }
            }
            #powered {
                p {
                    text-align: center;
                }
            }
        }
    }
    .ribbon-1 {
        right: 14px;
        left: unset;
    }
    .theme-modal {
        &.demo-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .demo-section {
                            .demo-effects {
                                > div {
                                    .demo-text {
                                        h4 {
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
/* =====================
    25.Dark CSS start
========================== */
body {
    &.dark {
        background-color: $dark-body;
        transition: all 0.3s ease;
        color: $white-5;
    }
}
.dark {
    h1 {
        color: $white;
    }
    h2 {
        color: $white-1;
    }
    h3 {
        color: $white-2;
    }
    h4 {
        color: $white-3;
    }
    h5 {
        color: $white-4;
    }
    h6 {
        color: $white-5;
    }
    p {
        color: $white-5;
    }
    li {
        color: $white-5;
    }
    a {
        color: $dark-link;
    }
    .page-link {
        background-color: $dark-body;
        transition: all 0.3s ease;
        border-color: $dark-border;
    }
    .breadcrumb-section {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .light-layout {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .form-control {
        color: $white-5;
        background-color: $dark-body;
        transition: all 0.3s ease;
        border: 1px solid $dark-border;
    }
    .btn-solid {
        color: $white !important;
        &:hover {
            color: $dark-font !important;
        }
    }
    select {
        color: $white-5;
    }
    option {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    header {
        background-color: $dark-body;
        transition: all 0.3s ease;
        &.sticky {
            &.fixed {
                background-color: $dark-body;
            }
        }
        &.header-5 {
            .pixelstrap {
                a,
                &:hover,
                &:active {
                    color: $dark-font;
                }
            }
        }
        &.header-metro {
            .metro {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
            .top-header {
                .header-contact {
                    li {
                        color: $header-font;
                    }
                }
                .header-dropdown {
                    li {
                        color: $header-font;
                        a {
                            i {
                                color: $header-font;
                            }
                        }
                    }
                }
            }
        }
        &.header-tools {
            background-color: transparent;
            transition: all 0.3s ease;
            .logo-menu-part {
                > .container {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }
        }
        &.left-header {
            .top-header {
                background-color: $dark-body;
                transition: all 0.3s ease;
            }
            .main-menu {
                .menu-right {
                    .header-dropdown {
                        > li {
                            img {
                                filter: invert(100);
                            }
                        }
                    }
                }
            }
            .onhover-div {
                > div {
                    img {
                        filter: invert(100);
                    }
                }
            }
            .sidenav {
                nav {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }
                .left-sidebar_center {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }
                .pixelstrap {
                    > li {
                        > a {
                            background-color: $dark-top;
                            transition: all 0.3s ease;
                        }
                    }
                }
            }
        }
        &.green-gradient {
            background-color: $dark-body;
            transition: all 0.3s ease;
            .top-header {
                background-color: $dark-top;
                transition: all 0.3s ease;
                background: $dark-top;
                background-image: none;
            }
        }
    }
    .top-header {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .sub-footer {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .border-section {
        border-color: $dark-border;
    }
    .main-navbar {
        .nav-menu {
            // background-color: $dark-body;
            .back-btn {
                .mobile-back {
                    color: $white-1;
                }
            }
            > li {
                > a {
                    color: $white-1;
                }
                .nav-submenu {
                    background-color: $dark-body;
                    box-shadow: none;
                    li {
                        a {
                            color: $white-1;
                        }
                        .nav-sub-childmenu {
                            background-color: $dark-body;
                            box-shadow: none;
                            li {
                                a {
                                    color: $white-1;
                                }
                            }
                        }
                    }
                }
                .mega-menu-container {
                    background-color: $dark-body;
                    box-shadow: none;
                    .mega-box {
                        .link-section {
                            .menu-content {
                                ul {
                                    li {
                                        a {
                                            color: $white-1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .sm-vertical {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .sidenav {
        nav {
            background-color: $dark-top;
            transition: all 0.3s ease;
            .sidebar-menu {
                background-color: $dark-top;
                li {
                    a {
                        color: $white-1;
                    }
                    .mega-menu {
                        background-color: $dark-top;
                        box-shadow: none;
                        .link-section {
                            ul {
                                background-color: $dark-top;
                            }
                        }
                    }
                    ul {
                        background-color: $dark-top;
                    }
                }
            }
        }
        .sidebar-back {
            color: $white-1;
            border-color: $dark-border;
        }
    }
    .pixelstrap {
        ul {
            background: $dark-top;
            box-shadow: none;
            a {
                color: $white-3;
                &:hover,
                &:focus,
                &:active,
                &.highlighted {
                    color: $white-3;
                }
            }
        }
    }
    .pixelstrap {
        .home-menu,
        .feature-menu,
        .category-menu,
        .full-mega-menu,
        .clothing-menu {
            box-shadow: 0 0 1px 0 $dark-body;
        }
    }
    .onhover-div {
        > div {
            img {
                filter: brightness(100);
            }
        }
        .show-div {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }
    .error-section {
        h1 {
            color: #e6e6e6;
        }
    }
    .breadcrumb-item {
        &.active {
            color: $white-3;
        }
    }
    .breadcrumb-section {
        .breadcrumb {
            a {
                color: $grey-about;
            }
        }
    }
    .main-menu {
        .menu-left {
            .navbar {
                i {
                    color: $grey-about;
                }
            }
        }
        .menu-right {
            .icon-nav {
                .onhover-div {
                    .show-div {
                        &.setting {
                            h6 {
                                color: $white-5;
                            }
                            ul {
                                li {
                                    a {
                                        color: $white-5;
                                    }
                                }
                            }
                        }
                        &.shopping-cart {
                            li {
                                .media {
                                    .media-body {
                                        h4 {
                                            color: $white-3;
                                            span {
                                                color: $white-4;
                                            }
                                        }
                                    }
                                }
                                .close-circle {
                                    i {
                                        &:hover {
                                            color: $white;
                                        }
                                    }
                                }
                                .total {
                                    border-color: $dark-border;
                                    h5 {
                                        color: $white-4;
                                    }
                                }
                                .buttons {
                                    a {
                                        color: $dark-link;
                                        &:hover {
                                            color: $theme-deafult;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer-theme {
        .sub-title {
            li {
                color: $white-5;
                a {
                    color: $white-5;
                }
            }
            h4 {
                color: $white-3;
            }
        }
    }
    .footer-social,
    .social-white {
        i {
            color: $white-5;
        }
    }
    .footer-light {
        .subscribe {
            border-color: $dark-border;
        }
    }
    .footer-theme2 {
        .contact-details {
            li {
                color: $white-5;
                a {
                    color: $white-3;
                }
            }
        }
        .footer-link,
        .footer-link-b {
            a {
                color: $dark-link;
                &:hover {
                    color: $white;
                }
            }
        }
    }
    .top-header {
        .header-contact {
            li {
                color: $white-5;
            }
        }
        .header-dropdown {
            li {
                color: $white-5;
                a {
                    i {
                        color: $white-5;
                    }
                }
            }
            .onhover-dropdown {
                .onhover-show-div {
                    li {
                        a {
                            color: $dark-link;
                        }
                    }
                }
            }
        }
        &.top-header-dark {
            .header-dropdown {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
            .header-contact {
                li {
                    i {
                        color: $dark-link;
                    }
                }
            }
        }
        &.top-header-dark2 {
            .header-dropdown {
                li {
                    a {
                        color: $white-5;
                    }
                }
            }
            .header-contact {
                li {
                    i {
                        color: $white-5;
                    }
                }
            }
        }
        &.top-header-dark3 {
            .header-dropdown {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
            .header-contact {
                li {
                    i {
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .onhover-dropdown {
        .onhover-show-div {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }
    .testimonial {
        background-color: $dark-top;
        transition: all 0.3s ease;
        .testimonial-slider {
            .slick-track {
                .slick-slide {
                    &:nth-child(even) {
                        .media {
                            border-color: $dark-border;
                        }
                    }
                }
            }
            .media {
                img {
                    border-color: $dark-border;
                }
            }
        }
    }
    .team {
        h2 {
            border-color: $dark-border;
        }
    }
    .service-block {
        + .service-block {
            border-color: $dark-border;
        }
    }
    .pixelstrap {
        &.light-font-menu {
            li {
                > a {
                    color: $white-1;
                }
            }
        }
    }
    .theme-tab {
        .tab-title,
        .tab-title2 {
            a {
                color: $dark-link;
            }
            .current {
                a {
                    color: $theme-deafult;
                }
            }
        }
        .tab-title2 {
            &:after {
                border-color: $dark-border;
            }
            .current {
                background-color: $dark-top;
                transition: all 0.3s ease;
                a {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    .product-box,
    .product-wrap {
        .product-detail,
        .product-info {
            h4 {
                color: $white-3;
            }
        }
    }
    .theme-card {
        .offer-slider {
            .media {
                .media-body {
                    h4 {
                        color: $white-3;
                    }
                }
            }
        }
        .slick-prev,
        .slick-next {
            &:before {
                color: $white;
            }
        }
    }
    .theme-card {
        h5 {
            &.title-border {
                border-color: $dark-border;
            }
        }
        &.card-border {
            border-color: $dark-border;
        }
    }
    .dark-layout {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .sub-footer {
        &.darker-subfooter {
            background-color: $dark-top;
            transition: all 0.3s ease;
            p {
                color: $white-5;
            }
        }
    }
    .blog-page {
        .blog-media {
            .blog-right {
                h4 {
                    color: $white-3;
                }
                ul {
                    color: $white-5;
                }
            }
        }
        .blog-sidebar {
            .theme-card {
                background-color: $dark-body;
                transition: all 0.3s ease;
                border-color: $dark-border;
            }
            h4 {
                color: $white-3;
            }
            h6 {
                color: $white-5;
            }
            p {
                color: $white-5;
            }
        }
    }
    .blog-detail-page {
        .blog-detail {
            h3 {
                color: $white-2;
            }
            p {
                color: $white-5;
            }
        }
        .post-social {
            color: $white-5;
            border-color: $dark-border;
            li {
                + li {
                    border-color: $dark-border;
                }
            }
        }
        .comment-section {
            border-color: $dark-border;
            li {
                border-color: $dark-border;
                h6 {
                    color: $white-5;
                    span {
                        color: $dark-span;
                    }
                }
            }
        }
        .blog-contact {
            .theme-form {
                label {
                    color: $white-5;
                }
                input,
                textarea {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }
        }
    }
    .cart-section,
    .wishlist-section {
        .cart-table {
            thead {
                th {
                    color: $white-1;
                }
            }
        }
        tbody {
            tr {
                td {
                    border-color: $dark-border;
                    a {
                        color: $dark-link;
                    }
                    h2 {
                        color: $white-1;
                    }
                }
            }
        }
    }
    .table {
        tbody {
            + tbody {
                border-color: $dark-border;
            }
        }
        thead {
            th {
                border-color: $dark-border;
            }
        }
    }
    .top-banner-wrapper {
        .top-banner-content {
            h4 {
                color: $white-3;
            }
            h5 {
                color: $white-4;
            }
            p {
                color: $white-5;
            }
        }
    }
    .collection-product-wrapper {
        .product-top-filter {
            border-color: $dark-border;
            .product-filter-content,
            .popup-filter {
                .search-count,
                .sidebar-popup {
                    border-color: $dark-border;
                }
                .collection-grid-view {
                    border-color: $dark-border;
                }
                .product-page-per-view {
                    select {
                        border-color: $dark-border;
                    }
                }
            }
            .popup-filter {
                .sidebar-popup {
                    a {
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .product-pagination {
        border-color: $dark-border;
        .pagination {
            .page-item {
                &.active {
                    a {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                        border-color: $dark-border;
                    }
                }
            }
        }
        .product-search-count-bottom {
            border-color: $dark-border;
            h5 {
                color: $white-4;
            }
        }
    }
    .portfolio-section {
        &.metro-section {
            .product-box {
                .product-detail {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    h4 {
                        color: $white-3;
                    }
                    h6 {
                        color: $white-5;
                    }
                }
                .cart-wrap {
                    button {
                        border-color: $dark-border;
                        background-color: rgba($dark-body, 0.8);
                        transition: all 0.3s ease;
                        i {
                            color: $dark-link;
                        }
                    }
                    a {
                        i {
                            border-color: $dark-border;
                            background-color: rgba($dark-body, 0.8);
                            transition: all 0.3s ease;
                            color: $dark-link;
                        }
                    }
                }
            }
        }
    }
    .collection-filter-block {
        border-color: $dark-border;
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .collection-collapse-block {
        .collapse-block-title {
            color: $white-2;
            &:after {
                color: $white-2;
            }
        }
        .collection-collapse-block-content {
            .collection-brand-filter {
                .collection-filter-checkbox {
                    label {
                        color: $dark-span;
                    }
                }
            }
        }
    }
    .load-more-sec {
        a {
            border-color: $dark-border;
        }
    }
    .checkout-page {
        .checkout-title {
            h3 {
                color: $white-2;
            }
        }
        .checkout-form {
            .form-group {
                .field-label {
                    color: $white-5;
                }
            }
            input {
                &[type='text'],
                &[type='email'],
                &[type='password'],
                &[type='tel'],
                &[type='number'],
                &[type='url'] {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }
            select,
            textarea {
                border-color: $dark-border;
            }
            .checkout-details {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border: 1px solid $dark-border;
            }
        }
    }
    .order-box {
        .title-box {
            color: $white-2;
            border-color: $dark-border;
        }
        .qty {
            border-color: $dark-border;
            li {
                color: $white-5;
                span {
                    color: $white-4;
                }
            }
        }
        .sub-total {
            border-color: $dark-border;
            li {
                color: $white-5;
            }
            .shopping-option {
                label {
                    color: $dark-span;
                }
            }
        }
        .total {
            li {
                color: $white-5;
            }
        }
    }
    .payment-box {
        .payment-options {
            li {
                .radio-option {
                    label {
                        color: $dark-span;
                    }
                }
            }
        }
    }
    .collection {
        .collection-block {
            .collection-content {
                h4 {
                    color: $white-3;
                }
                h3 {
                    color: $white-2;
                }
            }
        }
    }
    .table {
        th,
        td {
            border-color: $dark-border;
        }
    }
    .compare-page {
        .table-wrapper {
            .table {
                color: $white-1;
                border-color: $dark-border;
                thead {
                    .th-compare {
                        td {
                            background: $dark-top;
                            border-color: $dark-border;
                        }
                        th {
                            border-color: $dark-border;
                            .remove-compare {
                                color: $white-5;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        th {
                            border-color: $dark-border;
                            background: $dark-top;
                        }
                        td {
                            border-color: $dark-border;
                        }
                        p {
                            color: $white-5;
                        }
                    }
                }
            }
        }
    }
    .compare-section {
        .compare-part {
            .detail-part {
                .title-detail {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                }
            }
            .btn-part {
                border-color: $dark-border;
            }
            .close-btn {
                color: $white-3;
            }
        }
        .slick-slide {
            > div {
                border-color: $dark-border;
            }
            &:first-child {
                border-color: $dark-border;
            }
        }
    }
    .contact-page {
        .theme-form {
            label {
                color: $white-5;
            }
            input {
                border-color: $dark-border;
            }
            textarea {
                border-color: $dark-border;
            }
        }
        .contact-right {
            ul {
                li {
                    .contact-icon {
                        border-color: $dark-border;
                    }
                }
            }
        }
    }
    .dashboard {
        .box-head {
            h2 {
                color: $white-1;
            }
        }
        .box {
            .box-title {
                border-color: $dark-border;
                h3 {
                    color: $white-2;
                }
            }
        }
    }
    .dashboard-left {
        .block-content {
            border-color: $dark-border;
            ul {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .dashboard-right {
        .dashboard {
            border-color: $dark-border;
        }
    }
    .white-bg {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .border-bottom-grey {
        border-color: $dark-border;
    }
    .layout-8 {
        .layout-8-bg {
            background-blend-mode: overlay;
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }
    .card {
        background-color: $dark-body;
        transition: all 0.3s ease;
        .card-header {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }
    .faq-section {
        .accordion {
            &.theme-accordion {
                .card {
                    border-color: $dark-border;
                    .card-header {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                        button {
                            color: $white-4;
                            &[aria-expanded='true'] {
                                &:before {
                                    border-bottom-color: $dark-border;
                                }
                            }
                            &:before {
                                border-top-color: $dark-border;
                            }
                        }
                    }
                    .card-body {
                        p {
                            color: $white-5;
                        }
                    }
                }
            }
        }
    }
    &.box-layout-body {
        .box-layout-header {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }
        .box-layout {
            &.bg-image {
                background-color: $dark-top;
                transition: all 0.3s ease;
                background-image: none;
                box-shadow: none;
            }
        }
        .light-layout {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }
        .sub-footer {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }
    .full-box {
        .theme-card {
            .offer-slider {
                .product-box2 {
                    + .product-box2 {
                        border-top: none;
                    }
                }
            }
        }
    }
    .center-slider {
        border-color: $dark-border;
    }
    .bg-block {
        background-color: $dark-top;
        transition: all 0.3s ease;
        background-image: none;
    }
    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    .product-box2 {
                        .media {
                            .media-body {
                                h4 {
                                    color: $white-3;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .flower-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;
        background-image: none;
    }
    .pwd-page {
        .theme-form {
            input {
                border-color: $dark-border;
            }
        }
    }
    .full-scroll-menu {
        background-color: transparent;
        transition: all 0.3s ease;
    }
    .full-scroll-footer {
        .sub-footer {
            background-color: transparent;
            transition: all 0.3s ease;
        }
    }
    .portfolio-section {
        .filter-button {
            background-color: $dark-body;
            transition: all 0.3s ease;
            color: $white-5;
            &.active {
                background-color: $theme-deafult;
                transition: all 0.3s ease;
                color: $white;
            }
        }
        .isotopeSelector {
            &:hover {
                .overlay-background {
                    border-color: $round-border;
                    i {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                    }
                }
            }
            .overlay {
                border-color: $round-border;
            }
        }
    }
    .bg-img-gym {
        .dark-layout {
            background-color: transparent;
            transition: all 0.3s ease;
        }
        .sub-footer {
            &.darker-subfooter {
                background-color: $black;
                transition: all 0.3s ease;
            }
        }
    }
    .category-block {
        .category-image {
            border-color: $dark-border;
            &.svg-image {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }
    }
    .j-box {
        .product-box {
            border-color: $dark-border;
            .product-detail {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
            .cart-info {
                background-color: rgba($dark-top, 0.84);
                transition: all 0.3s ease;
                border-color: $dark-border;
                a {
                    border-color: $black;
                }
            }
        }
    }
    .white-layout {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .footer-theme2 {
        &.footer-border {
            border-color: $dark-border;
        }
        &.section-light {
            .footer-block {
                .subscribe-white {
                    border-color: $dark-border;
                }
                h4 {
                    color: $white-3;
                }
                .contact-details {
                    li {
                        a {
                            color: $white-3;
                        }
                    }
                }
            }
        }
        .footer-link {
            &.link-white {
                h4 {
                    color: $white-3;
                }
            }
        }
    }
    .jewel-footer {
        .sub-footer {
            &.black-subfooter {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
            p {
                color: $white-5;
            }
        }
    }
    .absolute-banner {
        .absolute-bg {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }
    .login-page {
        .theme-card {
            border-color: $dark-border;
            .theme-form {
                input {
                    border-color: $dark-border;
                }
                label {
                    color: $dark-span;
                }
            }
        }
        .authentication-right {
            h6,
            p {
                color: $white-5;
            }
        }
    }
    .lookbook {
        .lookbook-block {
            .lookbook-dot {
                &:before {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                }
                .dot-showbox {
                    .dot-info {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    .blog-left {
        .blog-details {
            h4 {
                color: $white-3;
            }
            h6 {
                color: $white-5;
            }
        }
    }
    footer {
        &.footer-black {
            .below-section {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
            .sub-footer {
                background-color: $dark-body;
                transition: all 0.3s ease;
            }
            .upside {
                .small-section {
                    background-color: $dark-footer-bg;
                    transition: all 0.3s ease;
                }
            }
            .subscribe {
                h4 {
                    color: $white-3;
                }
                p {
                    color: $white-5;
                }
            }
            &.footer-light {
                .subscribe {
                    border-color: $dark-span;
                }
            }
        }
        &.pet-layout-footer {
            .white-layout {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
            .sub-footer {
                &.black-subfooter {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
        &.footer-classic {
            .sub-footer {
                background-color: $dark-body;
                transition: all 0.3s ease;
            }
        }
        &.footer-5 {
            background-image: unset;
            .footer-theme2 {
                .subscribe-block {
                    border-color: $dark-border;
                }
            }
            .sub-footer {
                > .container {
                    border-color: $dark-border;
                }
            }
        }
    }
    .logo-block {
        img {
            filter: invert(100);
            &:hover {
                filter: invert(100);
            }
        }
    }
    .bg-white {
        background-color: $dark-body !important;
        transition: all 0.3s ease;
    }
    .product-box,
    .product-wrap {
        .img-wrapper {
            .cart-box {
                background-color: $dark-body;
                transition: all 0.3s ease;
                box-shadow: none;
            }
        }
    }
    .tab-bg {
        &.tab-grey-bg {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }
    .delivery-sec {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .product-order {
        .total-sec {
            border-color: $dark-border;
        }
    }
    .blog.blog_box {
        .blog-details {
            p {
                color: $white-5;
            }
            a {
                p {
                    color: $white-5;
                }
            }
            .read-cls {
                color: $dark-link;
            }
        }
    }
    .product-right {
        .product-title {
            color: $white-5;
        }
        .border-product {
            border-color: $dark-border;
        }
        .product-icon {
            .product-social {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
            .wishlist-btn {
                color: $dark-link;
                i {
                    border-color: $dark-border;
                }
            }
        }
        &.product-form-box {
            border-color: $dark-border;
            .timer {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }
        .timer {
            background-color: $dark-top;
            transition: all 0.3s ease;
            p {
                color: $white-5;
            }
        }
        .size-box {
            ul {
                li {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                    border-color: $dark-border;
                    &.active {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                    }
                    a {
                        color: $dark-link;
                    }
                }
            }
        }
        h4 {
            del {
                color: $dark-span;
            }
        }
    }
    .timer {
        span {
            .timer-cal {
                color: $dark-span;
            }
        }
    }
    .qty-box {
        .input-group {
            span {
                button {
                    background: $dark-body !important;
                    border-color: $dark-border;
                }
            }
            button {
                i {
                    color: $dark-span;
                }
            }
        }
    }
    .nav-tabs {
        border-color: $dark-border;
        .nav-link {
            &.active {
                background-color: $dark-body;
                transition: all 0.3s ease;
            }
        }
    }
    .tab-product {
        .nav-material {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        color: $dark-link;
                    }
                }
                .nav-link {
                    &.active {
                        color: $theme-deafult;
                    }
                }
            }
        }
    }
    .product-full-tab {
        .nav-material {
            &.nav-tabs {
                .nav-link {
                    &.active {
                        color: $theme-deafult;
                    }
                }
            }
        }
    }
    .tab-product,
    .product-full-tab {
        .theme-form {
            input,
            textarea {
                border-color: $dark-border;
            }
        }
    }
    .product-related {
        h2 {
            border-color: $dark-border;
        }
    }
    .product-accordion {
        .btn-link {
            color: $white-3;
        }
        .card-header {
            border-color: rgba($dark-border, 0.125);
        }
    }
    .theme_checkbox {
        label {
            color: $dark-span;
            .checkmark {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border-color: $dark-border;
                &:after {
                    border-color: $white;
                }
            }
        }
    }
    .bundle {
        .bundle_detail {
            .price_product {
                color: $white;
            }
        }
    }
    .modal-content {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    button {
        &.close {
            color: $white;
        }
    }
    .modal-header {
        border-color: $dark-border;
    }
    .collection-filter-block {
        .product-service {
            .media {
                border-color: $dark-border;
            }
        }
    }
    .pro_sticky_info {
        border-color: $dark-border;
    }
    .tab-border {
        border-color: $dark-border;
    }
    .register-page {
        .theme-card {
            border-color: $dark-border;
            .theme-form {
                input {
                    border-color: $dark-border;
                }
                label {
                    color: $dark-span;
                }
            }
        }
    }
    .category-border {
        background-color: $dark-border;
        transition: all 0.3s ease;
        div {
            .category-banner {
                .category-box {
                    h2 {
                        background-color: $dark-top;
                        transition: all 0.3s ease;
                    }
                }
            }
        }
    }
    .background {
        background-color: $dark-border;
        transition: all 0.3s ease;
        .contain-bg {
            background-color: $dark-body;
            transition: all 0.3s ease;
            h4 {
                color: $white-3;
            }
            &:hover {
                h4 {
                    color: $theme-deafult;
                }
            }
        }
    }
    .blog-bg {
        background-color: $dark-border;
        transition: all 0.3s ease;
    }
    .sub-footer {
        &.black-subfooter {
            background-color: $dark-top;
            transition: all 0.3s ease;
        }
    }
    &.tools-bg {
        background-color: $dark-body;
        transition: all 0.3s ease;
        section {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }
    }
    .category-tools {
        .category-m {
            .category-wrapper {
                background-color: $dark-top;
                transition: all 0.3s ease;
            }
        }
    }
    .category-m {
        .category-wrapper {
            border-color: $dark-border;
            h4 {
                color: $white-3;
            }
            .category-link {
                li {
                    a {
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .tools-grey {
        .product-box {
            .product-info {
                background-color: $dark-top;
                transition: all 0.3s ease;
                border-color: rgba($dark-border, 0.2);
            }
            .cart-info {
                button {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                    color: $dark-link;
                }
                a {
                    background-color: $dark-top;
                    transition: all 0.3s ease;
                    i {
                        color: $dark-link;
                    }
                }
            }
            .img-wrapper {
                .front {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    .tools-brand {
        .row {
            background-color: $dark-top;
            transition: all 0.3s ease;
            box-shadow: none;
        }
    }
    .typography_section {
        .typography-box {
            .headings {
                background-color: rgba($dark-top, 0.5);
                transition: all 0.3s ease;
                border-color: $dark-border;
                h3 {
                    color: $white-2;
                }
                span {
                    color: $dark-span;
                    code {
                        color: $dark-span;
                    }
                }
            }
            .typo-content {
                .sub-title {
                    color: $white-1;
                    border-color: rgba($dark-border, 0.7);
                }
            }
        }
        .typo-content {
            &.heading_content {
                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    color: white;
                }
            }
            &.product-pagination {
                .pagination {
                    .page-item {
                        border-color: $dark-border;
                    }
                }
            }
        }
        code {
            background-color: $dark-body;
            transition: all 0.3s ease;
        }
    }
    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                background-color: $dark-top;
                transition: all 0.3s ease;
                box-shadow: none;
                h4 {
                    color: $white-3;
                }
            }
        }
    }
    .absolute-product {
        .product-box {
            background-color: $dark-top;
            transition: all 0.3s ease;
            .product-detail {
                .cart-bottom {
                    border-color: $dark-border;
                    i {
                        color: $dark-link;
                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
    .box-product {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        box-shadow: none;
                        .media {
                            background-color: $dark-top;
                            transition: all 0.3s ease;
                        }
                        .cart-bottom {
                            border-color: $dark-border;
                        }
                    }
                }
            }
        }
    }
    .insta-title {
        background-color: $dark-body;
        transition: all 0.3s ease;
    }
    .tab-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .darken-layout {
        background-color: $dark-top;
        transition: all 0.3s ease;
        p {
            color: $white-5;
        }
        .sub-title {
            .contact-list {
                li {
                    color: $white-5;
                }
                i {
                    color: $white-5;
                }
            }
        }
        .footer-social {
            i {
                color: $white-5;
            }
        }
    }
    .sub-footer {
        &.dark-subfooter {
            p {
                color: $white-5;
            }
        }
    }
    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {
                    a,
                    p {
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .footer-title {
        border-color: $dark-border;
    }
    .category-bg {
        background-color: $dark-top;
        transition: all 0.3s ease;
        .contain-block {
            background-color: $dark-body;
            transition: all 0.3s ease;
            h2 {
                color: $white-1;
            }
            h6 {
                span {
                    color: $dark-span;
                }
            }
        }
    }
    .service_slide {
        .service-home {
            .service-block1 {
                background-color: rgba($dark-top, 0.9);
                transition: all 0.3s ease;
                &:nth-child(even) {
                    background-color: rgba($dark-top, 0.7);
                    transition: all 0.3s ease;
                }
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }
    }
    .bg-grey {
        background-color: $dark-top;
        transition: all 0.3s ease;
    }
    .detail-cannabis {
        .detail_section {
            > div {
                background-color: $dark-body;
                transition: all 0.3s ease;
                box-shadow: none;
            }
            svg {
                fill: $white;
            }
        }
    }
    .border-box {
        &.tools-grey {
            .product-box {
                border-color: $dark-border;
            }
        }
    }
    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-image: linear-gradient(
                        135deg,
                        $theme-deafult 5.77%,
                        $dark-top 5.77%,
                        $dark-top 25%,
                        $white-1 25%,
                        $white-1 30.77%,
                        $dark-top 30.77%,
                        $dark-top 50%,
                        $theme-deafult 50%,
                        $theme-deafult 55.77%,
                        $dark-top 55.77%,
                        $dark-top 75%,
                        $white-1 75%,
                        $white-1 80.77%,
                        $dark-top 80.77%,
                        $dark-top 100%
                    );
                    .modal-bg {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                        .age-content {
                            h4 {
                                color: $white-3;
                            }
                        }
                        .close {
                            span {
                                color: $white;
                            }
                        }
                        .offer-content {
                            h2 {
                                color: $white-1;
                            }
                        }
                    }
                }
            }
        }
        &.demo-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                        .demo-section {
                            .demo-effects {
                                > div {
                                    .layout-container {
                                        box-shadow: none;
                                    }
                                    .demo-text {
                                        h4 {
                                            color: $white-3;
                                        }
                                        .demo-btn {
                                            .btn {
                                                border-color: $dark-border;
                                                background-color: $dark-body;
                                                transition: all 0.3s ease;
                                                color: $dark-link;
                                            }
                                        }
                                    }
                                }
                            }
                            .title-text {
                                h3 {
                                    color: $white-2;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            &.addtocart {
                                #upsell_product {
                                    .product-box {
                                        .product-detail {
                                            h6 {
                                                a {
                                                    color: $dark-link;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.exit-modal {
            .media {
                .media-body {
                    h5 {
                        color: $white-4;
                    }
                }
            }
            .stop {
                filter: invert(100);
            }
        }
    }
    #quick-view {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    background-color: $dark-body;
                    transition: all 0.3s ease;
                }
            }
        }
    }
    .addcart_btm_popup {
        background-color: $dark-top;
        transition: all 0.3s ease;
        border-color: $dark-border;
        .fixed_cart {
            i {
                color: $dark-link;
            }
        }
    }
    .review-page {
        .comnt-sec {
            li {
                a {
                    color: $dark-link;
                }
            }
        }
    }
    .sitemap_page {
        ul {
            li {
                a {
                    color: $dark-link;
                    &:hover {
                        color: $theme-deafult;
                    }
                }
            }
            ul {
                li {
                    a {
                        color: $white-4;
                    }
                }
            }
        }
    }
    .effect-cls {
        &:before,
        &:after {
            filter: invert(100);
        }
    }
    .game-product {
        .product-box {
            .cart-info {
                a {
                    i {
                        background-color: $dark-body;
                        transition: all 0.3s ease;
                        color: $dark-link;
                    }
                }
            }
        }
    }
    .demo-right {
        a {
            background-color: $black;
            transition: all 0.3s ease;
        }
    }
    .rtl-btn,
    .dark-light {
        background-color: $black;
        box-shadow: none;
    }
    .color-picker {
        a {
            &.handle {
                background-color: $black;
                transition: all 0.3s ease;
                color: $dark-link;
            }
        }
    }
    .setting-box {
        .setting-title {
            h4 {
                color: $font-color;
            }
        }
    }
}
/* =====================
    18.Portfoilio CSS start
========================== */
.portfolio-padding {
    padding-bottom: 40px;
}
.portfolio-section {
    .article-title {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        color: white;
        transition: all 0.5s ease;
        text-align: center;
        float: left;
        font-size: 18px;
        line-height: 24px;
        a {
            color: white;
        }
    }
    .isotopeSelector {
        float: left;
        width: 100%;
        position: relative;
        .overlay {
            position: relative;
            border: 1px solid $round-border;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .isotopeSelector {
        margin-bottom: 30px;
        img {
            transition: all 0.4s ease;
        }
        .overlay-background {
            transform: scale(0);
            transition: all 0.4s ease;
            i {
                position: absolute;
                top: 50%;
                left: 50%;
                background-color: white;
                padding: 10px;
                border-radius: 100%;
                transform: translate(-50%, -50%);
                color: $theme-deafult;
                font-size: 22px;
                display: none;
                transition: all 0.4s ease;
                width: 40px;
                height: 40px;
                align-items: center;
                justify-content: center;
            }
        }
        &:hover {
            img {
                transform: scale(1.2) rotate(2deg);
                transition: all 0.4s ease;
            }
            .overlay-background {
                transform: scale(1);
                border: 3px solid white;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-color: rgba($theme-deafult, 0.2);
                transition: all 0.4s ease;
                i {
                    display: flex;
                    transition: all 0.4s ease;
                }
            }
        }
    }
    .border-portfolio {
        margin: 3px;
        overflow: hidden;
    }
    #form1 {
        padding-bottom: 20px;
    }
    .filter-button {
        padding: 0 24px;
        font-size: 20px;
        line-height: 35px;
        border: none;
        background-color: white;
        &.active {
            background-color: $theme-deafult;
            color: $white;
        }
    }
    &.fullwidth-portfolio {
        .isotopeSelector {
            padding-left: 7px;
            padding-right: 7px;
            margin-bottom: 14px;
        }
    }
    &.metro-section {
        .isotopeSelector {
            &:hover {
                img {
                    // transform: scale(1) rotate(0deg);
                    // transition: all 0.5s ease;
                }
            }
        }
        .product-box {
            .product-detail {
                opacity: 0;
                position: absolute;
                background-color: $white;
                padding: 10px;
                transition: all 0.5s ease;
                width: 65%;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -10px;
                text-align: center;
                h6 {
                    color: #525252;
                    padding-top: 0;
                    margin-top: -2px;
                }
                h4 {
                    font-weight: 400;
                    color: $black;
                }
            }
            .cart-wrap {
                top: 7px;
                right: 7px;
                opacity: 1;
                button {
                    border: 1px solid $white;
                    border-radius: 100%;
                    background-color: rgba($white, 0.8);
                    opacity: 1;
                    i {
                        padding: 7px;
                        color: $font-color;
                    }
                }
                a {
                    i {
                        border-radius: 100%;
                        border: 1px solid $white;
                        margin: 5px 0;
                        background-color: rgba($white, 0.8);
                        padding: 7px;
                        color: $font-color;
                        opacity: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
            &:hover {
                .product-detail {
                    opacity: 1;
                    transition: all 0.5s ease;
                    bottom: 15px;
                }
                .cart-wrap {
                    button {
                        animation: none;
                    }
                    a {
                        i {
                            opacity: 1;
                        }
                        &:nth-child(2) {
                            i {
                                animation: fadeInDown 500ms ease-in-out;
                            }
                        }
                        &:nth-child(3) {
                            i {
                                animation: fadeInDown 700ms ease-in-out;
                            }
                        }
                        &:nth-child(4) {
                            i {
                                animation: fadeInDown 1000ms ease-in-out;
                            }
                        }
                    }
                }
            }
        }
    }
}
.filter-section {
    .filter-container {
        text-align: center;
        padding: 0 0 20px;
        ul {
            &.filter {
                > li {
                    padding: 0 25px;
                    > a {
                        color: $theme-deafult;
                        font-size: 20px;
                        line-height: 40px;
                        &:hover,
                        &:focus {
                            text-decoration: none;
                            color: $theme-deafult;
                            outline: none;
                        }
                    }
                    &.active {
                        background-color: $theme-deafult;
                        a {
                            color: $white;
                        }
                    }
                    span {
                        color: #ddd;
                        font-size: 20px;
                        display: inline-block;
                        margin: 0 5px;
                    }
                }
            }
        }
        .filter {
            > li {
                padding: 0;
                margin: 0;
            }
        }
    }
    .title1 {
        .title-inner1 {
            margin-top: -5px;
        }
    }
    h1 {
        text-align: center;
    }
}
.demo-colored-wrap {
    background: #ecf0f1;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}
.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}
.mfp-title {
    display: none;
}
.offer-box {
    top: 50%;
    position: fixed;
    transform: translateY(-50%);
    display: flex;
    left: -415px;
    transition: all 0.5s ease;
    z-index: 9;
    img {
        margin-right: 18px;
    }
    &.toggle-cls {
        left: 0;
        transition: all 0.5s ease;
    }
    .heading-right {
        h3 {
            position: absolute;
            top: 50%;
            right: 0;
            width: 230px;
            height: 36px;
            line-height: 36px;
            letter-spacing: 5px;
            font-size: 18px;
            color: #ffffff;
            font-weight: 700;
            text-transform: uppercase;
            transform: translate(50%, -50%) rotate(-90deg);
            text-align: center;
            background-color: $theme-deafult;
            margin-bottom: 0;
            cursor: pointer;
        }
    }
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: 100%;
}
.my-masonry-grid_column {
    padding-left: 30px;
    /* gutter size */
    background-clip: padding-box;
}
/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 30px;
}
