@media screen and (min-width: 600px) {
    
    .nav-style{
        z-index: 7;
    }

}

@media screen and (max-width: 600px) {

    .nav-style{
        z-index: 7 !important;
    }

}