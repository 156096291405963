.shop-footer{
    background-color: #d6d6d6;;
    min-height: 200px;
}

.footer-container{
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 65px;
}

.footer-terms{
    color: black;
    &:hover{
        text-decoration: underline;
    }
}

.footer-s2{
    margin-top: 36px;
    margin-bottom: 70px;
}

.footer-s1{
    height: 50px;
}

.footer-ws{
    color: black;
    &:hover{
        text-decoration: underline;
    }
}

.footer-cate{
    height: 80px;
    overflow-x: auto;
}

.footer-cate::-webkit-scrollbar {
width: 5px;
}

/* Track */
.footer-cate::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
.footer-cate::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
.footer-cate::-webkit-scrollbar-thumb:hover {
background: #555; 
}

.black-link{
    color: black;
    &:hover{
        text-decoration: underline;
    }
}