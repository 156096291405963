@media screen and (max-width: 600px) {
  .mb-header {
    position: fixed;
    width: 100%;
    //padding-top: 0 !important;//android
    height: 115px;
    z-index: 4;
    top: 0px;
  }
}

@media screen and (min-width: 600px) {
  .drop-nav {
    max-height: 500px;
  }

  .over-navmenu {
    width: 475px !important;
  }
}

.fixed-logo {
  width: 90px;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 40px;
  z-index: 7;
}

.cart-link {
  position: fixed;
  left: 16px;
  top: 45px;
}

.home-link {
  position: fixed;
  left: 65px;
  top: 73px;
  i {
    font-size: 30px;
    color: gray;
  }
}

.cart-label {
  position: absolute;
  top: 30px;
  font-size: 19px;
  left: 10px;
  background: #149792;
  color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: bold;
}

.burger-div {
  position: absolute;
  left: -25px;
  width: 24px;
  top: 65px;
  div {
    border-style: solid;
    border-width: 1px;
    margin-bottom: 3px;
  }
}

.pendiente-badge {
  position: absolute;
  background: #169792;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  top: 80%;
  right: -8px;
  padding: 3px;
}

.pendiente-cuenta-badge {
  font-size: 14px;
  background: #169792;
  color: white;
  border-radius: 50%;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 1px;
  padding-bottom: 2px;
}

.pasillo-plus {
  width: 34px;
  height: 34px;
  font: bold 16px/34px monospace !important;
  position: absolute;
  top: -5px;
  right: -41px;
}

.menu-category-label {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

@media screen and (max-width: 1200px) {
  .nav-display {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .nav-display {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .nav-display2 {
    display: block;
  }
}

@media screen and (min-width: 600px) {
  .nav-display2 {
    display: none;
  }
}

.top-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.top-home-icon {
  padding: 4px;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  font-size: 25px;
  margin-right: 15px;
}

.top-drop {
  margin-right: 15px;
  background-color: white;
  color: black;
  font-size: 14px;
  border-radius: 10px !important;
  &:hover {
    background-color: white;
    color: black;
  }
}

.btn-top {
  margin-right: 15px;
  background-color: white;
  color: black;
  font-size: 20px !important;
  border-radius: 10px !important;
  &:hover {
    background-color: white;
    color: black;
  }
  img {
    width: 18px;
    position: relative;
    top: -3px;
  }
  span {
    padding-left: 20px;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: white;
  border-color: #4e555b;
}

.top-button-pos {
  position: absolute;
  right: 30%;
}

.center-nav-home {
  margin: auto;
}

.home-link-nav {
  font-size: 28px !important;
  display: block;
  padding-right: 45px;
  color: #222222;
  font-weight: 400;
  line-height: 23px;
  text-decoration: none;
  text-transform: uppercase;
  padding-top: 52px !important;
  padding-bottom: 52px !important;
  padding-left: 0;
  letter-spacing: 0.07em;
  position: relative;
}

.home-nav-size {
  height: 127px !important;
}

.center-home-s {
  display: block;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  margin-bottom: 150px;
  margin-top: 50px;
}

.nav-menu-home {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  li {
    position: relative;
    float: left;
    display: block;
    a {
      display: block;
      padding-right: 45px;
      color: #222222;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-decoration: none;
      text-transform: uppercase;
      padding-top: 42px;
      padding-bottom: 42px;
      padding-left: 0;
      letter-spacing: 0.07em;
      position: relative;
    }
  }
}

.top-location-pos {
  position: absolute;
  right: 35%;
  bottom: 75px;
}

.location-icon {
  padding-right: 10px;
  font-size: 16px;
}
