
.pse-title{
    text-align: end;
    font-weight: bold;
}

.pse-info{
    text-align: start;
}

.pse-button-order{
    width: 50px;
    margin-top: -7px;
}